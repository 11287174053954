@use '../abstracts' as *;
@use 'sass:math';

/* ####################################################################################

  Sass導入メモ
  font-size、txt-ctrは既存のまま
  テキストカラーはmain.cssの記述を移動してきた
  その他はtempから以下を変更して使用
  txt-lg,txt-sm,txtareaは使わない

#################################################################################### */


/* txt
**************************************** */

/* --- 既存のスタイル --- */
.txt-gry, .txt-gry * {
  color: #aaa;
}
/* 影響範囲が広すぎるのてwhのままにしてる */
.txt-wh, .txt-wh * {
  color: var(--clr-wht) !important;
}

//カラーを出力
@each $key, $color in $colors {
  .#{$key},
  .#{$key} * { color: var(--#{$key}); }
}
//ブロックエディタ用
@each $key, $color in $colors {
  .has-#{$key}-color,
  .has-#{$key}-color * { color: var(--#{$key}); }
}

/* --- font-size --- */
.fz-12 {
  font-size: 0.75rem; /* 12px */
}
.fz-13 {
  font-size: 0.813rem; /* 13px */
}
.fz-14 {
  font-size: 0.875rem; /* 14px */
}
.fz-15 {
  font-size: 0.938rem; /* 15px */
}
.fz-16 {
  font-size: 1rem; /* 16px */
}
.fz-17 {
  font-size: 1.063rem; /* 17px */
}
.fz-18 {
  font-size: 1.125rem; /* 18px */
}
.fz-20 {
  font-size: 1.25rem; /* 20px */
}
.fz-24 {
  font-size: 1.5rem; /* 24px */
}
.fz-26 {
  font-size: 1.625rem; /* 26px */
}
.fz-28 {
  font-size: 1.75rem; /* 28px */
}
.fz-30 {
  font-size: 1.875rem; /* 30px */
}
.fz-32 {
  font-size: 2rem; /* 32px */
}
.fz-36 {
  font-size: 2.25rem; /* 36px */
}
.fz-40 {
  font-size: 2.5rem; /* 40px */
}
.fz-44 {
  font-size: 2.75rem; /* 44px */
}
.fz-48 {
  font-size: 3rem; /* 48px */
}
.fz-56 {
  font-size: 3.5rem; /* 56px */
}
.fz-64 {
  font-size: 4rem; /* 64px */
}
@include mq(sp) {
  .fz-12 {
    font-size: 0.786rem; /* 11px */
  }
  .fz-13 {
    font-size: 0.857rem; /* 12px */
  }
  .fz-14 {
    font-size: 0.929rem; /* 13px */
  }
  .fz-15 {
    font-size: 1rem; /* 14px */
  }
  .fz-16 {
    font-size: 1.071rem; /* 15px */
  }
  .fz-17 {
    font-size: 1.143rem; /* 16px */
  }
  .fz-18 {
    font-size: 1.214rem; /* 17px */
  }
  .fz-20 {
    font-size: 1.286rem; /* 18px */
  }
  .fz-24 {
    font-size: 1.429rem; /* 20px */
  }
  .fz-26 {
    font-size: 1.429rem; /* 20px */
  }
  .fz-28 {
    font-size: 1.714rem; /* 24px */
  }
  .fz-30 {
    font-size: 1.714rem; /* 24px */
  }
  .fz-32 {
    font-size: 1.857rem; /* 26px */
  }
  .fz-36 {
    font-size: 1.857rem; /* 26px */
  }
  .fz-40 {
    font-size: 2rem; /* 28px */
  }
  .fz-44 {
    font-size: 2rem; /* 28px */
  }
  .fz-48 {
    font-size: 2.143rem; /* 30px */
  }
  .fz-56 {
    font-size: 2.286rem; /* 32px */
  }
  .fz-64 {
    font-size: 2.571rem; /* 36px */
  }
}

/* --- font-family --- */
.font-en {
  font-family: var(--font-en);
}
.font-jp {
  font-family: var(--font-jp);
}
.font-jp-b {
  font-family: var(--font-jp-b);
}

/* --- clip-txt --- */
// 省略行を1から4まで生成
@for $i from 1 through 4 {
  .clip-txt#{$i} {
    @include clip-txt($i);
  }
}

/* txt-ctr
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}
.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}
.txt-rgt {
  text-align: right;
}
.txt-ctr {
  text-align: center;
}

.i-block {
  display: inline-block;
}

@include mq(sp) {
  .txt-ctr-pc,
  .txt-rgt-pc {
    text-align: left;
  }
  .txt-ctr-sp {
    text-align: center;
  }
}