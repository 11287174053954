@use "../abstracts" as *;

/* ##############################################################################

    COMMON

############################################################################## */

html {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
body {
  height: 100%;
  transition: background 0.4s ease;
}
body.page-dx-contact,
body.page-recruiting-contact,
body.page-product-contact {
  padding-top: 60px;
}
body.post-type-blog,
body.post-type-pr,
body.post-type-note {
  padding-top: 80px;
}

@media screen and (max-width: 767px) {
  body.page-contact,
  body.page-recruiting-contact,
  body.page-product-contact,
  body.page-dx-contact,
  body.post-type-blog,
  body.post-type-pr,
  body.post-type-note {
    padding-top: 40px;
  }
}

.gradation_bg_cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}
.gradation_bg_cover .gradation_bg {
  position: absolute;
  width: 900px;
  height: 900px;
  background: rgb(112, 190, 194);
  background: radial-gradient(circle, rgba(112, 190, 194, 0.6) 0%, rgba(194, 229, 231, 0) 70%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#70bec2",endColorstr="#c2e5e7",GradientType=1);
}
/* 白 */
.gradation_bg_cover .gradation_bg0,
.gradation_bg_cover .gradation_bg4 {
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(194, 229, 231, 0) 60%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#c2e5e7",GradientType=1);
}
.gradation_bg_cover .gradation_bg0 {
  top: 0;
  left: 0;
  transform: translateY(-20%) translateX(30%);
  animation: gradation_bg0_movement infinite 15s;
}
.gradation_bg_cover .gradation_bg4 {
  right: 0;
  bottom: 0;
  transform: translateY(30%) translateX(30%);
}
.gradation_bg_cover .gradation_bg1 {
  top: 0;
  left: 0;
  transform: translateX(-40%);
  animation: gradation_bg1_movement infinite 10s;
}
.gradation_bg_cover .gradation_bg2 {
  top: 0;
  right: 0;
  transform: translateX(40%);
  animation: gradation_bg2_movement infinite 15s;
}
.gradation_bg_cover .gradation_bg3 {
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  animation: gradation_bg3_movement infinite 10s;
}

#cookie-notice {
  right: 2.34vw;
  left: auto;
  bottom: 88px;
  max-width: 720px;
  width: 100%;
  min-width: auto;
  font-size: 81.25%;
  border-radius: 16px;
}
.page-contact #cookie-notice {
  display: none;
}
#cookie-notice .cookie-notice-container {
  padding: 20px 36px;
  display: flex;
  text-align: left;
  align-items: center;
}
#cookie-notice .cookie-notice-container::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 33px;
  background-repeat: no-repeat;
  background-image: url(../images/common/icon-cookie.png);
  margin-right: 24px;
}
.cn-text-container {
  flex: 1;
  margin: 0;
}
#cookie-notice .cn-button:not(.cn-button-custom) {
  padding: 13px 32px;
  font-size: 85%;
  margin: 0 0 0 16px;
  background-color: var(--clr-bg-02) !important;
  color: var(--clr-blk);
}
#cn-notice-text {
  font-size: 0.7rem;
}
#cn-notice-text a {
  color: var(--clr-wht);
  text-decoration: underline;
}
.cn-close-icon {
  position: absolute;
  top: 18px;
  right: 8px;
}
@media screen and (max-width: 1440px) {
  #cookie-notice {
    bottom: 58px;
  }
}
@media screen and (max-width: 767px) {
  #cookie-notice {
    left: 0;
    right: 0;
    margin: auto;
    bottom: 18px;
    width: 97%;
    border-radius: 8px;
    font-size: 75%;
  }
  #cookie-notice .cookie-notice-container {
    padding: 16px;
  }
  #cookie-notice .cookie-notice-container::before {
    display: none;
  }
  #cookie-notice .cn-button:not(.cn-button-custom) {
    padding: 10px 16px;
  }
}

main {
  position: relative;
  z-index: 3;
}
.home main {
  overflow: hidden;
}

/* layout
**************************************** */

/* --- anchor --- */
.anchor {
  position: absolute;
  top: -80px; /* 固定ヘッダーの高さ */
  left: 0;
  pointer-events: none;
}
@media screen and (max-width: 1080px) {
  /* --- anchor --- */
  .anchor {
    top: -64px; /* 固定ヘッダーの高さ */
  }
}

.linebox {
  padding: 40px;
  border-radius: 20px;
  border: 1px solid #ddd;
}
.page-product-contact .lps_parts .inner .linebox .linebox--ttl {
  margin-top: 0;
}

@media screen and (max-width: 767px) {
  .linebox {
    padding: 24px;
    border-radius: 8px;
  }
  .page-product-contact .linebox {
    padding: 20px;
  }
  .page-product-contact .linebox:first-child {
    padding-bottom: 16px;
  }
  .linebox .linebox--ttl {
    margin-top: 0 !important;
  }
}

/* bg
********************************************** */
.lps_sec,
.anchor_toc {
  position: relative;
  z-index: 1;
}
.bg-blur::before,
.lps_sec:first-of-type::before,
.anchor_toc::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
.lps_sec > .inner {
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .form_detail .inner {
    width: 100%;
  }
  .form_detail--txtarea > .lps_sec:first-child {
    padding-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .anchor_toc {
    padding: 0;
  }
  .anchor_toc--scroll {
    overflow: scroll;
  }
  .anchor_toc--list {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    justify-content: flex-start;
  }
  .anchor_toc--list li {
    position: relative;
    padding: 32px 16px;
  }
  .anchor_toc--list li a {
    position: relative;
    z-index: 1;
    word-break: keep-all;
    white-space: nowrap;
  }
}


/* ttl
********************************************** */

/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 432px;
  padding-bottom: 50px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fbf9f7;
}
.page-contact:not(.page-thanks) .page_ttl,
.page-common_hide .page_ttl {
  padding-bottom: 100px;
}
.page_ttl--btm {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 40px;
}
.page_ttl--ttl {
  gap: 40px;
}
.page_ttl-jp {
  font-size: 1.375rem; /* 22px */
  font-family: var(--font-jp-b);
}
.page_ttl-en {
  display: block;
  line-height: 1;
  font-size: min(vw(120),rem(120));
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.04em;
}
.page_ttl .page_lead {
  flex-shrink: 0;
}
.page_ttl .page_lead--txt {
  margin-right: 24px;
}
.page_ttl .page_lead--btns .btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  border-radius: 6px;
  padding: 11px 16px 11px;
  font-size: 0.925rem;
}
.page_ttl .page_lead--btns .btn i {
  margin-right: 8px;
}
.page_ttl .page_lead--btns .btn .icon-svg {
  right: 22px;
}
.page_ttl .page_lead--btns .btn .icon-svg i {
  height: 13px;
}
.page_ttl .page_lead--btns .btn .icon-svg svg {
  width: 13px;
  height: 13px;
}
.page_ttl .page_lead--btns .btn.com_info {
  margin-top: 0;
  margin-left: 12px;
}
.page_ttl .page_lead--btns .btn.com_info .icon-svg {
  top: 16px;
  right: 22px;
  transform: rotate(90deg);
}

.page_ttl--deco {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.page_ttl--deco .deco {
  position: absolute;
}
.page_ttl--deco .triangle1 {
  top: 0;
  left: 52%;
  transform: rotate(12.96deg);
}
.page_ttl--deco .triangle2 {
  top: 118px;
  left: -296px;
  transform: rotate(-15deg);
}
.page_ttl--deco .triangle3 {
  left: -400px;
  bottom: 404px;
  transform: rotate(-107deg);
  transform-origin: center;
}
.page_ttl--deco .circle {
  display: inline-block;
  width: 123px;
  height: 123px;
  border-radius: 100%;
}
.page_ttl--deco .circle1 {
  top: 80px;
  left: 33%;
  background-color: var(--clr-sub-02);
}
.page_ttl--deco .circle2 {
  left: 13%;
  bottom: -60px;
  background-color: var(--clr-sub-01);
}

/* --- ttl --- */
:is(.ttl-01, .ttl-02, .ttl-03, .ttl-04, .ttl-05, .ttl-06) {
  letter-spacing: 0.1em;
}
:is(.ttl-01, .ttl-02, .ttl-03, .ttl-04, .ttl-05, .ttl-06) span{
  letter-spacing: inherit;
}
:is(.ttl-01, .ttl-02, .ttl-03, .ttl-04, .ttl-05, .ttl-06, .subtitle, .subtitle-sm):not(.font-en) {
  font-family: var(--font-jp-b);
}
.ttl-01 {
  position: relative;
  text-align: center;
}
.ttl-01 .en {
  display: block;
  margin-bottom: 24px;
  font-size: clamp(rem(80), vw(120), rem(120));
  letter-spacing: 0.02rem;
  color: #0d1e28;
  line-height: 1.15;
  font-weight: 400;
}
.ttl-01 .jp {
  font-family: var(--font-jp-b);
  font-size: 3rem; /*18px*/
  line-height: 1.5;
}
.ttl-01 .en + .jp {
  font-size: 1.125rem; /*18px*/
  line-height: 1.5;
}
.ttl-02 {
  font-size: min(vw(40), rem(40));
  line-height: 1.6;
  letter-spacing: 0.04em;
  font-family: var(--font-jp-b);
}
.ttl-03 {
  font-size: 1.5rem; /* 24px */
  font-family: var(--font-jp-b);
  letter-spacing: 0.1em;
  line-height: 1.5;
}
/* ▼▼▼ figmaのテキスタイル。ttl-03などはまだ定義してない。上手く既存を吸収していきたい ▼▼▼ */
.ttl-04 {
  font-size: 2rem;
  line-height: 1.6;
  letter-spacing: 0.05em;
}
.ttl-04.font-en {
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.032em;
}
.ttl-05 {
  font-size: 1.5rem;
  line-height: 1.7;
  letter-spacing: 0.05em;
}
.ttl-05.font-en {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.0533333333em;
}
.ttl-06 {
  font-size: 1.25rem;
  line-height: 1.7;
  letter-spacing: 0.05em;
}
.ttl-06.font-en {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.06415em;
}
.subtitle {
  font-size: 1.125rem;
  line-height: 1.8;
  letter-spacing: 0.05em;
}
.subtitle.font-en {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.08em;
}
.subtitle-sm {
  font-size: 0.875rem;
  line-height: 1.6;
  letter-spacing: 0.05em;
}
.subtitle-sm.font-en {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.0914285714em;
}
/* ▲▲▲ figmaのテキスタイル。上手く既存を吸収していきたい ▲▲▲ */
.lps_sec .ttl-01,
.lps_sec .ttl-02,
.lps_sec .ttl-03 {
  position: relative;
  z-index: 1;
}
.lps_sec .ttl-01 {
  margin-bottom: 4em;
}
.lps_sec .ttl-02 {
  margin-bottom: 1.46em;
}
.lps_sec .ttl-03:not(:last-child) {
  margin-bottom: 1em;
}
.lps_sec .column-3 .ttl-03,
.lps_sec .column-4 .ttl-03 {
  font-size: 1.5rem; /* 24px */
}
.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: 2rem;
}
.cta_field_ttl.ttl-01 .en,
.cta_field_ttl.ttl-01 .jp {
  clip-path: inset(0);
  transform: translateY(0);
}
.cta_works_ttl.cta_field_ttl.ttl-01 .en,
.cta_works_ttl.cta_field_ttl.ttl-01 .jp {
  color: #1695c8;
}
@media screen and (max-width: 767px) {
  /* --- page_ttl --- */
  .page_ttl {
    min-height: auto;
    padding-top: 162px;
    padding-bottom: 20px;
    text-align: center;
  }
  .page_ttl .inner {
    z-index: 2;
  }
  .page_ttl--btm {
    display: block;
  }
  .page_ttl-jp {
    font-size: 1rem; /* 15px */
    letter-spacing: 0.06em;
  }
  .page_ttl-en {
    margin-bottom: 8px;
    font-size: 11.59vw; /* 48px */
    font-weight: 400;
    letter-spacing: 0.04em;
  }
  .page_ttl--ttl {
    display: block;
  }
  .page_ttl .page_lead--txt {
    margin-right: 0;
    margin-bottom: 16px;
    font-size: 1rem;
  }
  .page_ttl .page_lead--btns {
    width: 100%;
    justify-content: center;
  }
  .page_ttl .page_lead--btns .btn {
    margin-top: 0 !important;
    margin-bottom: 8px;
  }
  .page_ttl .page_lead--btns .btn-cta {
    margin-right: 8px;
  }
  .page_ttl .page_lead--btns .btn a {
    width: 100%;
    padding: 8px 16px 8px 16px;
    font-size: 0.866666rem;
  }
  .page_ttl .page_lead--btns .btn.btn-tel a {
    background-color: var(--clr-sub-04);
  }
  .page_ttl .page_lead--btns .btn .icon-svg {
    right: 12px;
  }
  .page_ttl .page_lead--btns .btn.com_info .icon-svg {
    top: 12px;
    right: 22px;
  }
  .page_ttl--deco {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  .page_ttl--deco .triangle1 {
    top: 153px;
    right: -130px;
    width: 478px;
    height: 429px;
    z-index: 1;
  }
  .page_ttl--deco .triangle2,
  .page_ttl--deco .triangle3 {
    width: 484px;
    height: 484px;
  }
  .page_ttl--deco .triangle3 {
    top: -390px;
    left: -278px;
    bottom: auto;
  }
  .page_ttl--deco .circle {
    width: 58px;
    height: 58px;
  }
  .page_ttl--deco .circle1 {
    top: 59px;
    left: 56%;
  }
  .page_ttl--deco .circle2 {
    left: -3%;
    top: 327px;
  }

  /* --- ttl --- */
  .ttl-01 .en {
    margin-bottom: 8px;
    font-size: 9.66vw;
    letter-spacing: 0.02em;
  }
  .cta_field_ttl.ttl-01 .en {
    clip-path: inset(0);
    transform: translateY(0);
    font-size: 9.66vw;
  }
  .ttl-01 .jp {
    font-size: 2rem;
  }
  .ttl-01 .en + .jp {
    font-size: 1.0667rem;
  }
  .ttl-02 {
    font-size: 1.6rem;
    text-align: left !important;
  }

  /* ▼▼▼ figmaのテキスタイル。ttl-03などはまだ定義してない。上手く既存を吸収していきたい ▼▼▼ */
  .ttl-04 {
    font-size: 1.4285714286rem;
  }
  .ttl-04.font-en {
    font-size: 1.4285714286rem;
    font-weight: 500;
    line-height: 1.7;
    letter-spacing: 0;
  }
  .ttl-05 {
    font-size: 1.2857142857rem;
  }
  .ttl-05.font-en {
    font-size: 1.2857142857rem;
    line-height: 1.7;
    letter-spacing: 0.01em;
  }
  .ttl-06 {
    font-size: 1.1428571429rem;
  }
  .ttl-06.font-en {
    font-size: 1.1428571429rem;
    font-weight: 500;
    line-height: 1.7;
    letter-spacing: 0.0128571429em;
  }
  .subtitle {
    font-size: 1.1428571429rem;
    line-height: 1.6;
  }
  .subtitle.font-en {
    font-size: 1.1428571429rem;
    font-weight: 600;
    letter-spacing: 0.0625em;
  }
  .subtitle-sm {
    font-size: sprem(14);
  }
  .subtitle-sm.font-en {
    font-size: 0.8571428571rem;
    font-weight: 700;
    letter-spacing: 0.0625em;
  }
  /* ▲▲▲ figmaのテキスタイル。上手く既存を吸収していきたい ▲▲▲ */

  .lps_sec .ttl-01 {
    margin-bottom: 2.5em;
  }
  .ttl-03,
  .lps_sec .column-3 .ttl-03,
  .lps_sec .column-4 .ttl-03,
  .lps_parts--img_text .inner-lg .ttl-03 {
    font-size: 1.33333rem; /* 20px */
  }
  .lps_sec .ttl-02,
  .lps_sec .ttl-03:not(:last-child) {
    margin-bottom: 0.5em;
  }
}

/* breadcrumbs
********************************************** */
.breadcrumbs {
  margin-top: 14px;
  text-align: right;
  font-family: var(--font-en), sans-serif;
}
.breadcrumbs a:hover {
  text-decoration: none;
}
.breadcrumbs a:before,
.breadcrumbs span:not(:first-child)::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 1px;
  background-color: #29323d;
  vertical-align: middle;
  margin-inline: 8px;
}
.breadcrumbs > span > span > a:first-child::before {
  display: none;
}
.breadcrumbs .breadcrumb_last {
  display: table-cell;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  .breadcrumbs {
    margin-top: 32px;
    overflow-x: scroll;
  }
  .archive-blog .breadcrumbs,
  .single-blog .breadcrumbs,
  .single-pr .breadcrumbs,
  .single-note .breadcrumbs {
    margin-top: 8px;
  }
  .breadcrumbs > span {
    display: flex;
    width: max-content;
  }
  .breadcrumbs a:before,
  .breadcrumbs .breadcrumb_last::before {
    vertical-align: super;
  }
}

/* txt
********************************************** */
.txt-pickup {
  position: relative;
  display: inline-block;
  padding-inline: 3rem;
  margin: auto
}
.txt-pickup::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1.875rem;
  height: 1.875rem;
  background-color: #80d4d4;
  clip-path: polygon(50% 0%, 0 5%, 100% 100%);
}
.txt-pickup::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 1.875rem;
  height: 1.875rem;
  background-color: #80d4d4;
  clip-path: polygon(50% 0%, 100% 5%, 0 100%);
}

/* btn
********************************************** */
.free {
  display: inline-block;
  padding: 5px 8px 4px;
  margin-left: 6px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 0.9125rem;
  line-height: 1;
}

/* cta_bloc
********************************************** */
.cta_bloc--inner {
  padding: 72px;
  border-radius: 24px;
  background-color: var(--clr-bg-02);
}
.cta_bloc .ttl-01 {
  margin-bottom: 46px;
  text-align: left;
}
.cta_bloc .ttl-01 + p {
  margin-bottom: 54px;
}
.cta_bloc .btn a {
  width: 278px;
  background-color: var(--clr-secondary-default);
}
.cta_bloc .btn.btn-gray a {
  background-color: #737373;
}
.cta_bloc .btn-gray .icon-arrow {
  top: 23px;
  transform: rotate(90deg);
}
.cta_bloc .imgarea {
  width: 55%;
  margin-left: 40px;
}
.cta_bloc .imgarea li {
  position: relative;
  width: calc(100% / 3 - (4px * 2 / 3));
  height: 158px;
  margin-right: 4px;
  margin-bottom: 4px;
  border: 1px solid #dfdfdf;
  background-size: cover;
  background-position: center;
}
.cta_bloc .imgarea li:nth-child(3n) {
  margin-right: 0;
}

@media screen and (max-width: 1500px) {
  .cta_bloc--inner {
    padding: 40px;
    flex-direction: column;
  }
  .cta_bloc--inner .txtarea {
    text-align: center;
    margin-bottom: 40px;
  }
  .cta_bloc .ttl-01 {
    text-align: center;
  }
  .cta_bloc .imgarea {
    width: 88%;
    margin-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .cta_bloc--inner {
    padding: 32px;
    border-radius: 16px;
  }
  .cta_bloc .ttl-01 {
    margin-bottom: 16px;
  }
  .cta_bloc .ttl-01 + p {
    margin-bottom: 24px;
  }
  .cta_bloc .btn a {
    width: 100%;
  }
  .cta_bloc .imgarea {
    display: none;
  }
}

/* ##############################################################################

    INDEX

############################################################################## */

.body_deco {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  opacity: 15%;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  pointer-events: none;
}
.body_deco_img {
  width: min(41.875vw, 804px);
  height: min(37.65625vw ,723px);
  aspect-ratio: 804/723;
  transform: rotate(-20deg);
  animation: 30s linear infinite rotation10;
  transition-delay: 0.2s;
}

@media screen and (max-width: 767px) {
  .body_deco {
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    padding-top: 35px;
  }
  .body_deco_img {
    width: 400px;
    height: 360px;
  }
}

.ttl-01 .en,
.ttl-01 .jp {
  display: block;
  clip-path: inset(100% 0 0 0);
  transform: translateY(10px);
  transition: all 0.6s ease;
}
.ttl-01 .jp {
  transition-delay: 0.2s;
}
.ttl-01.active .en,
.ttl-01.active .jp {
  transform: translateY(0);
  clip-path: inset(0 0 0 0);
}

/* hero
********************************************** */
.hero {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding-top: 7vw;
}
.hero::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
}
.hero .inner {
  position: relative;
  z-index: 10;
  max-width: 1540px;
  padding-right: clamp(460px, 35.4166vw, 540px);
}
.hero--catch {
  margin-bottom: 34px;
  font-size: min(vw(56),rem(56));
  line-height: 1.64;
  letter-spacing: 0.2em;
  font-family: var(--font-jp-b);
  transition: all 0.8s ease;
  transform: translateX(40px);
  clip-path: inset(0 100% 0 0);
}
.active .hero--catch {
  letter-spacing: 0.06em;
  transform: translateX(0);
  clip-path: inset(0 0 0 0);
}
.hero--lead {
  line-height: 1.86;
  letter-spacing: 0.2em;
  margin-bottom: 74px;
  font-family: var(--font-jp-b);
  transform: translateX(40px);
  transition: all 0.8s ease;
  transition-delay: 0.2s;
  clip-path: inset(0 100% 0 0);
}
.active .hero--lead {
  letter-spacing: 0.08em;
  transform: translateX(0);
  clip-path: inset(0 0 0 0);
}
.hero--lead + .btn {
  width: 278px;
  transform: translateX(40px);
  transition: all 0.8s ease;
  transition-delay: 0.25s;
  clip-path: inset(0 100% 0 0);
}
.active .hero--lead + .btn {
  transform: translateX(0);
  clip-path: inset(0 0 0 0);
}
.hero--deco {
  position: absolute;
  opacity: 0;
  transition: all 1s ease;
}
.home_triangle {
  width: min(52.34375vw, 1005px);
  height: min(47.0833vw, 905px);
  aspect-ratio: 1005/904;
}
.hero--deco.triangle1 {
  right: -80px;
  right: max(-4.1666vw, -80px);
  transform: rotate(12.94deg);
  animation: 30s linear infinite rotation1;
  transition-delay: 0.5s;
}
.hero--deco.triangle2 {
  left: max(-26.3542vw, -506px);
  bottom: 0;
  transform: rotate(-15deg);
  animation: 20s linear infinite rotation2;
  transition-delay: 0.4s;
}
.hero--deco.triangle3 {
  top: 0;
  left: max(-17.70833vw, -340px);
  translate: 0 -85.62%;
  transform: rotate(-108deg);
  transform-origin: center;
  animation: 15s linear infinite rotation3;
  transition-delay: 0.3s;
}
.hero--deco.circle {
  width: min(6.45833vw,124px);
  height: min(6.45833vw,124px);
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: var(--clr-sub-02);
}
.hero--deco.circle1 {
  top: 6vw;
  left: 35%;
  animation: 18s fuwafuwa infinite;
}
.hero--deco.circle2 {
  right: 2.34vw;
  bottom: 10px;
  animation: 22s fuwafuwa infinite;
  animation-delay: 2s;
}
.active .hero--deco {
  opacity: 1;
}
.hero--btn_wrap {
  display: flex;
  gap: 16px;
  max-width: 640px;
}
.hero--btn_wrap .btn {
  width: 100%;
}
.hero--btn_wrap .btn + .btn {
  margin-top: 0;
}
@media screen and (min-width: 768px) and (max-width: 1300px) {
  .hero {
    height: 80vh;
  }
}
@media screen and (max-width: 1300px) {
  .hero--lead br {
    display: none;
  }
  .hero--btn_wrap {
    flex-direction: column;
    width: 312px;
  }
}
@media screen and (max-width: 767px) {
  .hero {
    display: block;
    padding-top: 364px;
    padding-bottom: 32px;
    margin-bottom: 0;
  }
  .hero .inner {
    padding-right: 0;
  }
  .hero--catch {
    margin-bottom: 16px;
    font-size: 5.8vw;
  }
  .hero--lead {
    margin-bottom: 24px;
    font-size: 87.5%;
  }
  .hero--lead + .btn {
    margin: 0 auto;
  }
  .hero--deco.triangle1,
  .hero--deco.triangle2,
  .hero--deco.triangle3 {
    width: 400px;
    height: 360px;
  }
  .hero--deco.triangle1 {
    top: -4%;
    left: 27%;
    right: auto;
    z-index: 2;
  }
  .hero--deco.triangle2 {
    top: 16%;
    left: -60%;
  }
  .hero--deco.triangle3 {
    top: -35%;
    left: -45%;
  }
  .hero--deco.circle {
    width: 56px;
    height: 56px;
  }
  .hero--deco.circle1 {
    top: 8%;
    left: 6%;
    animation: 18s fuwafuwa3 infinite;
  }
  .hero--deco.circle2 {
    top: 34%;
    left: 0;
    right: auto;
    bottom: auto;
    background-color: var(--clr-sub-01);
    animation: 30s fuwafuwa infinite;
    transition-delay: 1s;
  }
  .hero--btn_wrap {
    flex-flow: column;
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
  .hero {
    padding-top: 240px;
  }
}
@media screen and (max-width: 320px) {
  .hero {
    padding-top: 184px;
  }
  .hero .inner {
    z-index: 2;
  }
}

/* home_ttl-jp
**************************************** */
.home_ttl-jp {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.home_ttl-jp.ttl-ctr {
  align-items: center;
}
.home_ttl-jp .en {
  font-family: var(--font-en);
  font-weight: 500;
  font-size: min(vw(40),rem(40));
  letter-spacing: 0.032em;
  line-height: 1.2;
}
.home_ttl-jp .jp {
  font-family: var(--font-jp-b);
  font-size: min(vw(64),rem(64));
  line-height: 1.5;
  letter-spacing: 0.05em;
}
.home_ttl-jp.size-s {
  gap: 0;
}
.home_ttl-jp.size-s .en {
  font-size: min(vw(24),rem(24)); 
  letter-spacing: 0.0533em;
}

.home_ttl-jp.anime .en,
.home_ttl-jp.anime .jp {
  clip-path: inset(100% 0 0 0);
  transform: translateY(10px);
  transition: all 0.6s ease;
}
.home_ttl-jp.anime.active .en,
.home_ttl-jp.anime.active .jp {
  transform: translateY(0);
  clip-path: inset(0 0 0 0);
}

@media screen and (max-width: 767px) {
  .home_ttl-jp {
    gap: 8px;
    align-items: center;
  }
  .home_ttl-jp .en {
    font-size: 1.25rem;
  }
  .home_ttl-jp .jp {
    font-size: 2rem;
    letter-spacing: 0.05em;
  }
  .home_ttl-jp.size-s .en {
    font-size: sprem(18);
  }
}

/* home_content
**************************************** */
.home_event::before,
.home_interview::before,
.home_works::before,
.home_service::before,
.home_other_service::before,
.home_company::before,
.home_news::before,
.home_blog::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

/* hero_post
**************************************** */
.hero_post > * {
  box-shadow: 0 0 16px 0 rgba(69, 131, 175, 0.15);
}
@media screen and (min-width: 767px) {
  .hero_post {
    position: absolute;
    right: 2.34vw;
    bottom: 80px;
    width: clamp(460px, 35.4166vw, 540px); /* vw 640 */
    z-index: 20;
  }
}
@media screen and (max-width: 767px) {
  .hero_post {
    width: 90%;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }
  .hero_post > * {
    box-shadow: 0 0 12px 0 rgba(69, 131, 175, 0.15);
  }
}
/* --- cookie表示ある時の位置 --- */
@media screen and (min-width: 1441px) {
  .cookies-not-set .hero_post {
    bottom: 220px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1440px) {
  .cookies-not-set .hero_post {
    bottom: 180px;
  }
}

/* home_situation
********************************************** */
.home_situation {
  position: relative;
  flex-flow: column;
  padding: 0;
  background-color: var(--clr-wht);
  border-radius: 16px;
  transition: 0.4s;
}
.home_situation--info {
  display: flex;
  align-items: end;
  background-color: var(--clr-bg-02);
  padding: 10px;
  border-radius: 16px 16px 0 0;
  text-align: center;
  justify-content: center;
}
.home_situation--info h3 {
  margin-bottom: 4px;
  font-family: var(--font-jp-b);
  line-height: 1;
}
.home_situation--info p {
  font-size: 1.2rem;
  line-height: 1;
  font-family: var(--font-jp-b);
}
.home_situation--info .num {
  margin-right: 4px;
  font-size: 2.375em;
  font-size: clamp(30px, 2.395833vw, 38px); /* vw 46px */
  font-weight: 600;
}
.home_situation #situation_slide {
  width: 98%;
  height: 120px;
  padding: 0 10px 0 20px;
  margin-block: 16px;
  overflow-y: scroll;
  font-size: 0.9375rem;
}
.home_situation #situation_slide::-webkit-scrollbar {
  width: 4px;
}
.home_situation #situation_slide::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.05);
}
.home_situation #situation_slide::-webkit-scrollbar-thumb {
  background-color: rgba(13, 30, 40, 0.1);
}
.home_situation .posts-situation {
  padding-bottom: 48px;
}
.home_situation .posts-situation::before {
  content: "";
  position: absolute;
  width: calc(100% - 45px);
  height: 80px;
  bottom: 16px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
}
.home_situation .posts-situation .post:last-child::after {
  content: "etc.";
  position: absolute;
  inset-inline: 0;
  margin: auto;
  top: calc(100% + 18px);
  text-align: center;
  font-family: var(--font-en);
  z-index: 1;
}
.home_situation .posts-situation .post {
  margin-bottom: 4px;
}
.home_situation .posts-situation .post--date {
  margin-right: 8px;
  color: var(--clr-blk);
  opacity: 0.6;
}
.home_situation .posts-situation .post--ttl {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* 語尾を3点リーダーに */
  font-weight: normal;
}
@media screen and (max-width: 767px) {
  .home_situation {
    padding: 0;
    border-radius: 16px;
  }
  .home_situation--info {
    width: 100%;
    flex-direction: row;
    border-radius: 16px 16px 0 0;
  }
  .home_situation--info h3 {
    margin-bottom: 0;
    color: var(--clr-blk);
  }
  .home_situation--info p {
    color: var(--clr-blk);
    font-size: 100%;
  }
  .home_situation--info .num {
    margin-left: 8px;
    font-size: 136%;
    color: var(--clr-blk);
  }
  .home_situation #situation_slide {
    width: 100%;
    height: 128px;
    padding: 0 16px;
    margin: 16px 0;
  }
  .home_situation .posts-situation .post {
    flex-direction: column;
    margin-bottom: 8px;
  }
  .home_situation .posts-situation .post--ttl {
    flex: 1;
    font-size: 0.75rem; /* 12px */
  }
  .home_situation .posts-situation .post--date {
    font-size: 0.75rem; /* 12px */
  }
  .home_situation .posts-situation::before {
    height: 40px;
  }
}

/* home_pickupnews
**************************************** */
.home_pickupnews {
  position: relative;
  margin-bottom: 1rem;
  border-radius: 16px;
  background-color: var(--clr-wht);
  overflow: hidden;
  z-index: 10;
}
.home_pickupnews--ttl {
  width: 100%;
  padding: 10px;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
  background-color: var(--clr-bg-02);
}
.home_pickupnews--txt {
  padding: 1rem 1.5rem;
  font-size: 0.9375rem;
  line-height: 1.4;
  flex: 1;
}
.home_pickupnews--txt .date {
  display: block;
  margin-bottom: 0.25em;
  color: var(--clr-blk);
  font-size: 1rem;
  opacity: 0.6;
}
.home_pickupnews--txt a:hover {
  color: var(--clr-primary-default);
}
@media screen and (max-width: 767px) {
  .home_pickupnews--box {
    background-color: var(--clr-wht);
    border-radius: 15px;
  }
  .home_pickupnews--ttl {
    width: 100%;
    font-size: 1.357rem;
  }
  .home_pickupnews--txt {
    padding: 1rem;
    font-size: 1rem;
  }
  .home_pickupnews--txt .date {
    margin-bottom: 0.125em;
    font-size: 0.8571rem;
  }
}

/* home_event
**************************************** */
.home_event {
  padding-block: 120px;
  z-index: 5;
}
.home_event > .inner {
  position: relative;
  z-index: 1;
}
.home_event--txt {
  font-size: 1.25rem;
  padding-bottom: 12px;
}
.posts-advice--slide.slick-initialized .slick-list {
  overflow: visible;
}
.posts-advice--slide.posts-advice.slick-initialized .post {
  width: calc((100% - 40px * 3) / 4) ;
  margin-inline: 1.04166vw; /* vw 20px */
  margin-bottom: 0;
  opacity: 0.25;
  transition: opacity 0.3s ease-in;
  pointer-events: none;
}
.posts-advice--slide.slick-initialized .post.slick-active {
  opacity: 1;
  pointer-events: inherit;
}
.posts-advice--slide.slick-initialized .slick-arrow {
  position: absolute;
  top: 0;
  width: 80px;
  cursor: pointer;
  z-index: 10;
}
.posts-advice--slide.slick-initialized .slick-arrow::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: 40px;
  height: 40px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  rotate: 45deg;
}
.posts-advice--slide.slick-initialized .slick-arrow:hover {
  color: var(--clr-sub-02);
}
.posts-advice--slide.slick-initialized .slick-arrow.slick-disabled {
  opacity: 0;
  pointer-events: none;
}
.posts-advice--slide.slick-initialized .slick-prev {
  left: -80px;
}
.posts-advice--slide.slick-initialized .slick-next {
  right: -80px;
  rotate: 180deg;
}
.home_event--txt.anime {
  clip-path: inset(100% 0 0 0);
  transform: translateY(20px);
  transition: transform 0.4s ease, clip-path 0.4s ease;
}
.home_event--txt.anime.active {
  clip-path: inset(0 0 0 0);
  transform: translateY(0);
}

@media screen and (min-width: 768px) {
  .home_event > .inner {
    width: calc(86% + 2.08333vw); /* vw 40px */
    max-width: calc(1600px + 2.08333vw); /* vw 40px */  
  }
  .home_event--ttlarea {
    margin-inline: 1.04166vw; /* vw 20px */
  }
  .home_event--txt.anime,
  .home_event--list.anime .posts-advice .post {
    clip-path: inset(100% 0 0 0);
    transform: translateY(20px);
    transition: transform 0.4s ease, clip-path 0.4s ease;
  }
  .home_event--txt.anime.active,
  .home_event--list.anime.active .posts-advice .post {
    clip-path: inset(0 0 0 0);
    transform: translateY(0);
  }
}
@media screen and (max-width: 767px) {
  .home_event {
    padding-block: 40px;
  }
  .home_event--ttlarea {
    align-items: center;
  }
  .home_event--txt {
    font-size: 1rem;
  }
  .posts-advice--slide.posts-advice.slick-initialized .post {
    margin-inline: 12px;
  }
  .posts-advice--slide.slick-initialized .slick-arrow {
    position: absolute;
    top: 0;
    width: 24px;
    cursor: pointer;
    z-index: 10;
  }
  .posts-advice--slide.slick-initialized .slick-arrow::after {
    width: 16px;
    height: 16px;
  }
  .posts-advice--slide.slick-initialized .slick-prev {
    left: -16px;
  }
  .posts-advice--slide.slick-initialized .slick-next {
    right: -16px;
  }
}

/* home_interview
********************************************** */
.home_interview {
  z-index: 5;
  padding-block: min(vw(120),120px);
}
.home_interview .ttl-01 {
  z-index: 2;
}
.home_interview--read {
  font-size: 1.25rem;
}
.home_interview--scroll {
  position: relative;
  z-index: 1;
}

.home_interview .home_interview--scroll + .flex p:not(.btn),
.home_works .home_works--scroll + .flex p:not(.btn) {
  flex: 1;
  margin-right: 60px;
}
.home_interview .home_interview--scroll + .flex p.btn,
.home_works .home_works--scroll + .flex p.btn {
  width: 100%;
  max-width: 320px;
}
.home_interview--logo .pc {
  display: block;
}
.home_interview--logo .sp {
  display: none;
}
.home_interview_link {
  margin-bottom: 160px;
}
.relation_interview--txt,
.relation_interview .btn-en {
  position: relative;
}
.home_interview--read {
  clip-path: inset(100% 0 0 0);
  transform: translateY(20px);
  transition: all 0.6s ease;
}
.home_interview--read.active {
  clip-path: inset(0 0 0 0);
  transform: translateY(0);
}

@media screen and (min-width: 768px) {
  .home_interview .posts-interview .post {
    clip-path: inset(0 100% 0 0);
    transform: translateX(20px);
    transition: all 0.6s ease;
  }
  .home_interview .posts-interview.active .post {
    clip-path: inset(0 0 0 0);
    transform: translateX(0);
  }
}

@media screen and (max-width: 767px) {
  .home_interview {
    padding-block: 40px;
  }
  .home_interview--read {
    font-size: 1rem;
  }
  .home_interview--scroll,
  .relation_interview--scroll {
    overflow-x: scroll;
    margin-bottom: 16px;
  }
  .home_interview .posts-interview,
  .relation_interview .posts-interview {
    display: flex;
    width: 1240px;
    margin-bottom: 16px;
  }
  .home_interview .posts-interview .post,
  .relation_interview .posts-interview .post {
    width: calc(100% / 4);
  }
  .home_interview--logo img.pc {
    display: none;
  }
  .home_interview--logo img.sp {
    display: block;
  }

  .home_interview .home_interview--scroll + .flex p:not(.btn-en),
  .home_works .home_works--scroll + .flex p:not(.btn-en) {
    flex: none;
    width: 100%;
    margin-right: 0;
    margin-bottom: 32px;
  }
  .home_interview .home_interview--scroll + .flex .btn-en,
  .home_works .home_works--scroll + .flex .btn-en {
    text-align: center;
  }
  .home_interview .home_interview--scroll + .inner-sm,
  .home_works .home_works--scroll + .inner-sm {
    max-width: 100%;
    width: 100%;
  }
  .relation_interview .btn-en {
    margin-top: 16px;
    text-align: center;
  }
  .home_interview_link {
    margin-bottom: 40px;
  }
}

/* home_works
********************************************** */
.home_works {
  padding-block: min(vw(120),120px) min(vw(120),120px);
  @include mq(sp) {
    padding-block: sprem(40);
  }
}
.home_works--deco {
  position: absolute;
}
.home_works--deco.triangle1 {
  top: -14vw;
  left: max(-28.645833vw ,-550px);
  transform: rotate(-45deg);
  animation: 30s linear infinite rotation6;
}
.home_works--deco.circle {
  top: -6vw;
  left: 11.3vw;
  width: min(6.45833vw,124px);
  height: min(6.45833vw,124px);
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: var(--clr-sub-02);
  mix-blend-mode: multiply;
  animation: 20s fuwafuwa infinite;
}
.home_works .deco_txt,
.home_company .deco_txt {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: min(vw(183),rem(183));
  background-size: min(vw(-3400),rem(-3400)) auto;
  background-image: url(../images/home/deco_txt.png);
  background-repeat: repeat-x;
  animation: txtroop 10s linear infinite;
}
.home_company .deco_txt {
  top: 80px;
  bottom: auto;
}
.home_works--read {
  font-size: 1.25rem;
  font-weight: 600;

  clip-path: inset(100% 0 0 0);
  transition: all 0.6s ease;
  transform: translateY(20px);
}
.home_works--read.active {
  clip-path: inset(0 0 0 0);
  transform: translateY(0);
}
.home_works .posts-works {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 70px 38px;
}

.home_works .posts-works .post {
  clip-path: inset(100% -40px -40px -40px);
  transition: all 0.6s ease;
  transform: translateY(20px);
}
.home_works .posts-works.active .post {
  clip-path: inset(-40px -40px -40px -40px);
  transform: translateY(0);
}


@media screen and (max-width: 767px) {
  .home_works--deco.circle {
    width: 60px;
    height: 60px;
  }
  .home_works--deco.triangle1 {
    left: -200px;
    width: 300px;
    height: 270px;
  }
  .home_works .deco_txt,
  .home_company .deco_txt {
    height: 32px;
  }
  .home_works--read {
    font-size: 1rem;
  }
  .home_works .posts-works {
    display: flex;
    gap: 16px;
  }
  .home_works--scroll {
    overflow-x: scroll;
    width: calc(100% + 32px);
    padding-top: 16px;
    padding-inline: 16px;
    translate: -16px -16px;
  }
  .home_works .posts-works {
    display: flex;
    width: 1680px;
    margin-bottom: 16px;
  }
  .home_works .posts-works .post {
    width: calc(100% / 6);
  }
  .home_works .posts-works .post:nth-child(n + 7) {
    display: none;
  }
  .home_works .posts-works .post:last-child {
    margin-bottom: 0;
  }
}

/* home_service
********************************************** */
.home_service {
  z-index: 1;
  padding-top: min(vw(120),120px);
  overflow: hidden;
  @include mq(sp) {
    padding-top: sprem(40);
  }
}
.home_service--deco {
  position: absolute;
}
.home_service--deco.triangle1 {
  right: -5%;
  bottom: -5%;
  z-index: 2;
  transform: rotate(-105deg);
  animation: 20s linear infinite rotation3;
}
.home_service--deco.circle {
  width: min(8.75vw, 168px);
  height: min(8.75vw, 168px);
  aspect-ratio: 1;
  border-radius: 50%;
  animation: 20s fuwafuwa infinite;
}
.home_service--deco.circle1 {
  top: 5%;
  right: 0;
  background-color: var(--clr-sub-02);
}
.home_service--deco.circle2 {
  left: 3%;
  bottom: 8%;
  z-index: 2;
  background-color: #84c6c6;
}
.home_service--info {
  padding-bottom: 114px;
  background: linear-gradient(0deg, rgba(248, 248, 248, 1) 30%, rgba(248, 248, 248, 0) 30%);
}
.home_service--lead {
  max-width: 1280px;
  width: 86%;
  margin-inline: auto;
  margin-bottom: 40px;
}
.home_service--lead .txtarea {
  padding-bottom: 2.08vw;
  line-height: 1.875;
}
.home_service--lead .catch {
  margin-bottom: 32px;
  font-size: clamp(rem(30), vw(38),rem(38));
  line-height: 1.8;
  font-family: var(--font-jp-b);
}
.home_service--lead .imgarea {
  flex: 1;
}
.home_service--flow {
  position: relative;
  border-radius: 16px;
  background-color: var(--clr-wht);
  box-shadow: 0 0 40px rgba(59, 131, 175, 0.1);
}
.home_service--flow::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  margin: 0 auto;
  width: 60px;
  height: 30px;
  background-color: var(--clr-wht);
  clip-path: polygon(100% 0, 0 0, 50% 100%);
}
.home_service--flow li {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.home_service--flow .ttl {
  position: relative;
  padding: 28px;
  padding-left: 3.75vw;
  color: var(--clr-wht);
}
.home_service--flow .ttl::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  right: -2.6vw;
  width: 2.6vw;
  height: 100%;
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
}
.home_service--flow li:nth-child(1) .ttl {
  border-radius: 16px 0 0 0;
  background-color: var(--clr-sub-01);
}
.home_service--flow li:nth-child(2) .ttl {
  background-color: var(--clr-sub-02);
}
.home_service--flow li:nth-child(3) .ttl {
  border-radius: 0 16px 0 0;
  background-color: var(--clr-primary-default);
}
.home_service--flow li:nth-child(1) .ttl::before {
  background-color: var(--clr-sub-01);
}
.home_service--flow li:nth-child(2) .ttl::before {
  background-color: var(--clr-sub-02);
}
.home_service--flow .ttl .num {
  font-weight: bold;
}
.home_service--flow .ttl .txt {
  display: block;
  font-size: 150%;
  font-family: var(--font-jp-b);
}
.home_service--flow .txtarea {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px 3.75vw;
}
.home_service--flow .btn-en {
  margin-top: auto;
}
.home_service--flow .btn-en .arrow {
  width: 40px;
  height: 40px;
}
.service_contents {
  position: relative;
  height: 100vh;
}
.produce_ttl {
  padding-top: 24px;
  margin-bottom: 24px;
}
.produce_ttl .en {
  display: block;
  margin-bottom: 16px;
  font-size: 150%;
}
.produce_ttl .jp {
  font-size: clamp(rem(32), vw(58), rem(58));
  line-height: 1.286;
  font-family: var(--font-jp-b);
}
.service_contents_pager {
  position: absolute;
  top: 0;
  left: 2.92vw;
  bottom: 0;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 162px;
  background-color: var(--clr-blk);
  border-radius: 60px;
  z-index: 5;
}
.service_contents_pager span {
  display: inline-block;
  height: 16px;
  color: #7a7876;
  line-height: 1;
  font-weight: 600;
  transition: all 0.4s ease;
}
.service_contents_pager span:nth-child(1) {
  letter-spacing: 0.15em;
}
.service_contents_pager span:not(:last-child) {
  margin-bottom: 24px;
}
.service_contents_pager span:last-child {
  color: var(--clr-text-secondary);
}
.service_contents_pager span.current {
  width: 16px;
  height: 16px;
  background-color: var(--clr-sub-02);
  border-radius: 50%;
  font-size: 0;
}
.service_content {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 1;
  padding-top: 80px;
}
.service_content.current {
  z-index: 2;
}
.service_content::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  transition: all 0.4s ease;
}
.service_content.content1::before {
  background-color: #f8f8f8;
}
.service_content.content2::before {
  background-color: #f8f8f8;
}
.service_content.content3::before {
  background-color: #f8f8f8;
}
.service_content.current::before {
  height: 100%;
}
.service_content .box {
  display: flex;
  align-items: center;
  width: 90%;
  height: 100%;
}
.service_content .clr_box {
  margin-top: 1.5em;
  padding: 4% 6%;
  background-color: var(--clr-bg-02);
  border-radius: 24px;
}

.service_content .clr_box .editor-ttl3 {
  margin-bottom: 2%;
}
.service_content .list {
  margin-block: 1em;
}
.service_content .list li {
  position: relative;
  margin: 0;
  border-bottom: none;
  padding: 0;
  margin-left: 2em;
  padding-top: 0.5em;
}
.service_content .list li::before {
  content: "";
  position: absolute;
  top: 1.05em;
  left: -1.35em;
  width: 10px;
  height: 10px;
  border: 1px solid var(--clr-sub-01);
  border-radius: 50%;
  background-color: var(--clr-sub-01);
}
.service_content .imgarea {
  width: min(37.4vw, 718px);
  height: min(37.4vw, 718px);
  overflow: hidden;
  border-radius: 50%;
  margin-left: 2.5vw;
}
.service_content .txtarea {
  position: relative;
  width: 46%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: max(136px, 5vw);
}
.service_content .txtarea .deco {
  position: absolute;
  top: 54px;
  left: 0;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s ease;
  transition-delay: 0.25s;
}
.home_service--detail {
  padding-block: min(vw(72),rem(72)) min(vw(224),rem(224));
  background-color: #f8f8f8;
}
.home_service--detail .inner {
  position: relative;
  z-index: 2;
}
.home_service--detail li {
  width: calc(100% / 4 - (2.5vw * 3 / 4));
}
.home_service--detail li a {
  display: block;
  background-color: var(--clr-wht);
  padding: 40px 16px;
  text-align: center;
  border-radius: 16px;
  box-shadow: 0 0 40px rgba(59, 131, 175, 0.1);
}
.home_service--detail li a:hover {
  background-color: var(--clr-primary-default);
  color: var(--clr-wht);
}
.home_service--detail li .en {
  display: block;
  color: var(--clr-sub-02);
  font-weight: bold;
  transition: all 0.4s ease-out;
}
.home_service--detail li a:hover .en {
  color: rgb(255 255 255 / 70%);
}
.home_service--detail li .jp {
  font-size: min(vw(24),rem(24));
  font-family: var(--font-jp-b);
}
@media screen and (min-width: 768px) {
  .service_content .step .step--deco {
    position: absolute;
    top: 3.13vw;
    right: 0;
    font-size: clamp(rem(120), vw(208), rem(208));
    letter-spacing: -0.02em;
    line-height: 1;
    font-weight: 600;
    color: var(--clr-sub-02);
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.6s ease;
    transition-delay: 0.25s;
  }
  .service_content .step .step--num {
    position: relative;
    display: inline-block;
    font-size: 6.25vw;
    line-height: 1;
    letter-spacing: 0.02em;
    color: #0d1e28;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.6s ease;
    transition-delay: 0.35s;
  }
  .service_content .imgarea {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.6s ease;
  }
  .service_content .produce_ttl {
    opacity: 0;
  }
  .service_content.current .produce_ttl {
    opacity: 1;
  }
  .service_content.current .txtarea .deco,
  .service_content.current .step .step--deco,
  .service_content.current .step .step--num,
  .service_content.current .imgarea {
    opacity: 1;
    transform: translateY(0);
  }
  .service_content.content1 .step .step--num {
    color: #0d1e28;
  }
  .service_content--ttl,
  .service_content--lead {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.6s ease;
  }
  .service_content .btn-en {
    opacity: 0;
    margin-top: 8px;
    transform: translateY(20px);
    transition: all 0.6s ease;
  }
  .service_content--ttl {
    margin-bottom: min(vw(24),rem(24));
    font-size: clamp(rem(24), vw(32), rem(32));
    line-height: 1.5;
    letter-spacing: 0.04em;
    transition-delay: 0.4s;
    font-family: var(--font-jp-b);
  }
  .service_content--ttl .font-en {
    display: block;
    font-size: clamp(rem(16), vw(20), rem(20));
    color: var(--clr-sub-02);
  }
  .service_content--ttl .font-en::after {
    content: "";
    display: block;
    width: 24px;
    height: 1px;
    background-color: var(--clr-sub-02);
    margin-top: 16px;
    margin-bottom: 24px;
  }
  .service_content--lead {
    margin-bottom: min(vw(19),rem(19));
    transition-delay: 0.45s;
  }
  .service_content .btn-en {
    transition-delay: 0.6s;
  }
  .service_content.current .service_content--ttl,
  .service_content.current .service_content--lead,
  .service_content.current .btn-en {
    opacity: 1;
    transform: translateY(0);
  }
}
@media screen and (min-width: 1921px) {
  .service_content .box {
    max-width: 1800px;
  }
}
@media screen and (max-width: 1550px), screen and (min-width: 768px) {
  .service_content.current .service_content--lead {
    margin-bottom: min(vw(24), rem(24));
  }
}
@media screen and (max-width: 1550px) {
  .home .box .txtarea .btn-en a {
    font-size: 1rem;
  }
  .home .box .txtarea .btn-en .arrow {
    width: 48px;
    height: 48px;
  }
  .service_content .txtarea {
    width: 46%;
    margin-left: 4%;
    padding-right: 0;
  }
  .produce_ttl .en {
    margin-bottom: 8px;
  }
  .note-none {
    display: none;
  }
  .service_content--ttl .font-en::after {
    margin-top: 8px;
    margin-bottom: 16px;
  }
  .produce_ttl {
    padding-top: 0;
    margin-bottom: 8px;
  }
  .service_content--ttl {
    margin-bottom: 16px;
  }
  .service_content .box {
    height: 100%;
  }
  .service_content .clr_box {
    margin-top: 1em;
    padding: 16px 24px;
  }
  .service_content .imgarea {
    height: auto;
    margin-left: auto;
    margin-right: 16px;
  }
  .service_content .clr_box .editor-ttl3 {
    margin-bottom: 0.625vw;
    font-size: 1rem;
  }
  .service_content.current .step .step--deco {
    opacity: 0.4;
  }
}
@include mq(med, min, ps) {
  @include mq(1180) {
    .service_content .txtarea {
      padding-top: 6vw;
    }
    .service_content .step .step--deco {
      top: 5vw;
    }
  }
}
@include mq(sp, min, ps) {
  @include mq(1180) {
    .service_contents_pager {
      width: 48px;
      height: 140px;
      top: 64px;
      left: vw(40);
    }
    .service_content .box {
      width: 94%;
    }
    .service_content .imgarea {
      margin-left: vw(80);
      margin-right: 0;
    }
    .service_content .txtarea {
      width: 64%;
    }
    .service_content--ttl br {
      display: none;
    }
  }
}
@media screen and (max-width: 767px) {
  .home_service .ttl-01 + p {
    font-size: 1rem;
  }
  .home_service--deco.triangle1 {
    bottom: 0;
    width: 300px;
    height: 270px;
  }
  .home_service--deco.circle {
    width: 60px;
    height: 60px;
  }
  .home_service--info {
    padding-bottom: 64px;
  }
  .home_service--lead {
    flex-direction: column-reverse;
  }
  .home_service--lead .catch {
    margin-bottom: 16px;
    font-size: 5vw;
    text-align: center;
  }
  .home_service--lead .txtarea {
    padding-bottom: 0;
  }
  .home_service--flow .ttl {
    padding: 20px;
    padding-left: 20px;
  }
  .home_service--flow li:nth-child(1) .ttl {
    border-radius: 16px 16px 0 0;
  }
  .home_service--flow li:nth-child(3) .ttl {
    border-radius: 0;
  }
  .home_service--flow .ttl::before {
    display: none;
  }
  .home_service--flow .ttl .num {
    font-size: 5.2vw;
  }
  .home_service--flow .ttl .txt {
    display: inline;
    font-size: 5.2vw;
  }
  .home_service--flow .txtarea {
    padding: 20px 20px 32px;
  }
  .home_service--flow .txtarea .mgn-btm16 {
    margin-bottom: 16px;
  }
  .produce_ttl {
    margin-bottom: 0;
    padding-bottom: 24px;
    background-color: #f8f8f8;
    text-align: center;
  }
  .produce_ttl .en {
    margin-bottom: 8px;
    font-size: 5.5vw;
    letter-spacing: 0.02em;
  }
  .produce_ttl .jp {
    font-size: 6.5vw;
    line-height: 1.55;
  }
  .service_contents {
    height: auto;
  }
  .service_contents_pager {
    display: none;
  }
  .service_content {
    position: relative;
    height: auto;
    padding-bottom: 24px;
    padding-top: 40px;
  }
  .service_content::before {
    height: 100%;
  }
  .service_content .imgarea {
    width: 90%;
    height: auto;
    margin-left: 0;
    margin-bottom: 0;
    aspect-ratio: 1/1;
  }
  .service_content .txtarea {
    width: 100%;
    margin-top: -13%;
    padding-right: 0;
    margin-left: 0;
  }
  .service_content .step {
    display: none;
    margin-bottom: 24px;
  }
  .service_content .step .step--deco {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 5rem;
    letter-spacing: 0.05em;
    line-height: 1;
    font-weight: 600;
    color: var(--clr-sub-02);
  }
  .service_content .step .step--num {
    font-size: 2.5rem;
    line-height: 1;
    color: #0d1e28;
  }
  .service_content--ttl {
    font-size: 1.4rem;
    margin-bottom: 18px;
  }
  .service_content--ttl .font-en {
    display: block;
    font-size: 100%;
    color: var(--clr-sub-02);
  }
  .service_content--ttl .font-en::after {
    content: "";
    display: block;
    width: 24px;
    height: 1px;
    background-color: var(--clr-sub-02);
    margin-top: 16px;
    margin-bottom: 24px;
  }
  .service_content--lead + .btn-en {
    margin-top: 24px;
    text-align: center;
  }
  .home_service--detail li {
    width: 100%;
  }
  .home_service--detail li:not(:last-child) {
    margin-bottom: 16px;
  }
  .home_service--detail li a {
    padding: 24px 16px;
  }
  .home_service--detail li .jp {
    font-size: 120%;
  }
}

/* home_other_service
********************************************** */
.home_other_service {
  padding-top: 7.76vw;
  padding-bottom: 18.75vw;
}
.home_other_service--contents {
  height: 640px;
}
.home_other_service--contents .item {
  position: absolute;
}
.home_other_service--contents .item a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  filter: drop-shadow(0 0 40px rgba(69, 131, 175, 0.3));
  color: var(--clr-wht);
}
.home_other_service--contents .item.item-large a {
  width: 240px;
  height: 240px;
}
.home_other_service--contents .item.wh a {
  color: var(--clr-sub-01);
}
.home_other_service--contents .item a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all 0.6s ease;
}
.home_other_service--contents .item1 a:hover::before,
.home_other_service--contents .item4 a:hover::before,
.home_other_service--contents .item6 a:hover::before,
.home_other_service--contents .item9 a:hover::before,
.home_other_service--contents .item11 a:hover::before,
.home_other_service--contents .item15 a:hover::before,
.home_other_service--contents .item17 a:hover::before {
  -webkit-mask-image: url(../images/home/other_service_triangle.png);
  mask-image: url(../images/home/other_service_triangle.png);
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top right;
  mask-position: top right;
  border-radius: 0;
}
.home_other_service--contents .item.bl a::before {
  background-color: var(--clr-sub-02);
}
.home_other_service--contents .item.sky a::before {
  background-color: var(--clr-sub-01);
}
.home_other_service--contents .item.gr a::before {
  background-color: var(--clr-sub-03);
}
.home_other_service--contents .item.wh a::before {
  background-color: var(--clr-wht);
}
.home_other_service--contents .item1 {
  top: 36px;
  left: 20px;
}
.home_other_service--contents .item2 {
  top: 0;
  left: 264px;
}
.home_other_service--contents .item3 {
  top: 26px;
  left: 446px;
}
.home_other_service--contents .item4 {
  top: 36px;
  right: 395px;
}
.home_other_service--contents .item5 {
  top: 18px;
  right: 222px;
}
.home_other_service--contents .item6 {
  top: 100px;
  right: 60px;
}
.home_other_service--contents .item7 {
  top: 264px;
  left: -66px;
}
.home_other_service--contents .item8 {
  top: 188px;
  left: 240px;
}
.home_other_service--contents .item9 {
  top: 214px;
  left: 498px;
}
.home_other_service--contents .item10 {
  top: 292px;
  right: 450px;
}
.home_other_service--contents .item11 {
  top: 198px;
  right: 186px;
}
.home_other_service--contents .item12 {
  top: 268px;
  right: -6px;
}
.home_other_service--contents .item13 {
  bottom: 108px;
  left: 84px;
}
.home_other_service--contents .item14 {
  bottom: 22px;
  left: 234px;
}
.home_other_service--contents .item15 {
  bottom: 4px;
  left: 414px;
}
.home_other_service--contents .item16 {
  bottom: 0;
  right: 450px;
}
.home_other_service--contents .item17 {
  bottom: 20px;
  right: 274px;
}
.home_other_service--contents .item18 {
  bottom: 60px;
  right: 100px;
}
.home_other_service--contents .item span {
  position: relative;
  z-index: 1;
}
.home_other_service .deco_txt {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 183px;
  background-image: url(../images/home/deco_txt.png);
  z-index: 1;
  mix-blend-mode: multiply;
  animation: txtroop 50s linear infinite;
}
.home_other_service--deco.triangle1 {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: -80px;
  margin: 0 auto;
  transform: rotate(14deg);
  animation: 30s linear infinite rotation4;
}

/* home_company
********************************************** */
.home_company {
  padding-block: min(vw(360),rem(360)) min(vw(96),rem(96));
}
.home_company--deco {
  position: absolute;
}
.home_company--deco.triangle1 {
  top: 31%;
  left: -20%;
  transform: rotate(-15deg);
  animation: 20s linear infinite rotation2;
}
.home_company--deco.triangle2 {
  top: 47%;
  left: -23%;
  transform: rotate(-45deg);
  z-index: 1;
  animation: 20s linear infinite rotation6;
}
.home_company--deco.circle {
  top: 55%;
  left: 17%;
  width: min(6.45833vw,124px);
  height: min(6.45833vw,124px);
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: var(--clr-sub-02);
  mix-blend-mode: multiply;
  z-index: 2;
  animation: 20s fuwafuwa infinite;
}
.home_company--ttl {
  margin-bottom: 8px;
}
.home_company--ttl .font-en {
  margin-right: 16px;
  font-size: 250%;
  clip-path: inset(100% 0 0 0);
  transform: translateY(10px);
  transition: all 0.6s ease;
}
.home_company--ttl .jp {
  font-size: 113%;
  font-family: var(--font-jp-b);
  clip-path: inset(100% 0 0 0);
  transform: translateY(10px);
  transition: all 0.6s ease;
}
.home_company--ttl.active .font-en,
.home_company--ttl.active .jp {
  transform: translateY(0);
  clip-path: inset(0 0 0 0);
}
.home_company--catch {
  margin-bottom: min(vw(112), rem(112));
  font-size: min(vw(80), rem(80));
  font-family: var(--font-jp-b);
  transition: all 0.6s ease;
  clip-path: inset(100% 0 0 0);
  transform: translateY(10px);
}
.home_company--catch.active {
  transform: translateY(0);
  clip-path: inset(0 0 0 0);
}
.home_company--txt {
  position: relative;
  z-index: 1;
  width: fit-content;
  margin-left: auto;
  margin-right: 10%;
}
.home_company--txt > p {
  font-size: 150%;
  line-height: 2.417;
  font-family: var(--font-jp-b);
}
.home_company--txt > p span {
  display: block;
  clip-path: inset(100% 0 0 0);
  transform: translateY(10px);
  transition: all 0.6s ease;
}
.home_company--txt > p span.active {
  transform: translateY(0);
  clip-path: inset(0 0 0 0);
}
.home_company--txt .btn {
  max-width: 360px;
  width: 100%;
}
.home_company--txt .out_link {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}
svg.icon-svg.icon-pdf {
  left: 40px;
  width: 25px;
  height: auto;
  aspect-ratio: 1/1;
}
@media screen and (max-width: 767px) {
  .home_company {
    padding-top: 104px;
    padding-bottom: 50px;
  }
  .home_company--deco.triangle1 {
    top: auto;
    bottom: -12%;
    left: -50%;
    z-index: 1;
    width: 300px;
    height: 270px;
  }
  .home_company--deco.triangle2,
  .home_company--deco.circle {
    display: none;
  }
  .home_company .deco_txt {
    top: 40px;
  }
  .home_company--ttl {
    text-align: center;
    margin-bottom: 24px;
  }
  .home_company--ttl .font-en {
    display: block;
    margin-right: 0;
    line-height: 1.5;
  }
  .home_company--txt {
    width: 100%;
  }
  .home_company--catch {
    margin-bottom: 24px;
    font-size: 6vw;
  }
  .home_company--txt > p {
    margin-bottom: 32px;
    font-size: 107%;
    line-height: 1.8;
    font-family: var(--font-jp);
  }
  .home_company--txt > p span {
    display: inline;
    clip-path: none;
  }
  .home_company--txt > p span.active {
    clip-path: none;
  }
  .home_company--txt > .flex {
    margin-top: 32px;
    align-items: center;
  }
}

/* home_news
********************************************** */
.home_news {
  padding-top: 100px;
  padding-bottom: 100px;
}
.home_news .inner {
  z-index: 1;
}
.home_news .txtarea {
  display: flex;
  flex-direction: column;
}
.home_news .ttl-01,
.home_blog .ttl-01,
.home_advice .ttl-01 {
  text-align: left;
}
.home_news .ttl-01 .en,
.home_blog .ttl-01 .en,
.home_advice .ttl-01 .en {
  font-size: min(vw(80),rem(80));
  line-height: 1.15;
}
.home_news .btn-en {
  margin-top: auto;
}
.home_news .news_list {
  flex: 1;
  margin-left: 7.29vw;
}

@media screen and (max-width: 767px) {
  .home_news {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .home_news .ttl-01 {
    text-align: center;
  }
  .home_news .ttl-01 .en,
  .home_blog .ttl-01 .en,
  .home_advice .ttl-01 .en {
    font-size: 9.66vw;
  }
  .home_news .ttl-01 .jp,
  .home_blog .ttl-01 .jp,
  .home_advice .ttl-01 .jp {
    font-size: 1.0667rem;
  }
  .home_news .txtarea {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }
  .home_news .btn-en {
    margin-top: 24px;
    text-align: center;
  }
  .home_news .news_list {
    margin-left: 0;
  }
  .home_news .news_list .post--ttl {
    font-size: 90%;
    line-height: 1.55;
  }
}

/* home_blog
********************************************** */
.home_blog {
  padding-top: 100px;
  padding-bottom: 100px;
}
.home_blog--deco {
  position: absolute;
}
.home_blog--deco.triangle1 {
  top: -530px;
  right: -830px;
  transform: rotate(30deg);
}
.home_blog--tab {
  padding: 8px;
  background-color: var(--clr-blk);
  border-radius: 40px;
}
.home_blog--tab li {
  color: var(--clr-wht);
  cursor: pointer;
}
.home_blog--tab li > span {
  display: inline-block;
  width: 185px;
  padding: 4px;
  text-align: center;
  border-radius: 40px;
  transition: all 0.4s ease;
}
.home_blog--tab li:hover > span {
  color: var(--clr-sub-01);
}
.home_blog--tab li.current > span {
  background-color: var(--clr-wht);
  color: var(--clr-blk);
}
.home_blog .posts_content {
  position: relative;
  display: none;
  padding-top: 40px;
}
.home_blog .posts_scroll {
  overflow-x: scroll;
}
.home_blog .posts_scroll::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}
.home_blog .posts_scroll::-webkit-scrollbar-track {
  background-color: #dfdfdf;
}
.home_blog .posts_scroll::-webkit-scrollbar-thumb {
  background-color: var(--clr-blk);
}
.home_blog :is(.btn-left, .btn-right) {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 40px;
  height: 40px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  cursor: pointer;
}
.home_blog :is(.btn-left, .btn-right).disable {
  opacity: .2;
  pointer-events: none;
}
.home_blog .btn-left {
  left: -60px;
  transform: rotate(45deg);
}
.home_blog .btn-right {
  right: -60px;
  transform: rotate(-135deg);
}
.home_blog .posts_content.current {
  display: flex;
  animation: slideup 0.5s forwards;
}
.home_blog .posts-blog {
  width: max-content;
}
.home_blog .posts-blog .post {
  width: 480px;
  margin-right: 38px;
}
.home_blog--links .btn-en {
  display: none;
}
.home_blog--links .btn-en.current {
  display: block;
}

@media screen and (max-width: 767px) {
  .home_blog {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .home_blog .ttl-01 {
    margin-bottom: 24px;
    text-align: center;
  }
  .home_blog--deco.triangle1 {
    top: -20%;
    right: -150px;
    width: 300px;
    height: 270px;
  }
  .home_blog--tab {
    width: 100%;
  }
  .home_blog--tab li {
    width: calc(100% / 3);
  }
  .home_blog--tab li > span {
    width: 100%;
    padding: 4px 14px;
  }
  .home_blog .posts_content {
    padding-top: 0;
  }
  .home_blog--posts {
    margin-bottom: 32px;
  }
  .home_blog .posts-blog .post {
    width: 270px;
    margin-right: 16px;
    margin-bottom: 16px;
  }
  .home_blog .btn-left,
  .home_blog .btn-right {
    display: none;
  }
}

/* home_advice
********************************************** */
.home_advice {
  padding-top: 120px;
  padding-bottom: 100px;
  padding-bottom: calc(60px + 12.5vw);
  border-radius: 12.5vw 12.5vw 0 0;
  background-color: var(--clr-mono-01);
}
.home_advice--deco {
  position: absolute;
}
.home_advice--deco.triangle {
  top: 16px;
  left: 0;
  translate: -80% 0;
  transform: rotate(-45deg);
  animation: 20s linear infinite rotation9;
  z-index: 1;
}
.home_advice--deco.circle1,
.home_advice--deco.circle2 {
  width: min(6.45833vw,124px);
  height: min(6.45833vw,124px);
  aspect-ratio: 1;
  border-radius: 50vw;
  mix-blend-mode: multiply;
  z-index: 2;
}
.home_advice--deco.circle1 {
  top: 140px;
  left: 16px;
  background-color: var(--clr-sub-02);
  animation: 20s fuwafuwa infinite;
}
.home_advice--deco.circle2 {
  right: 40px;
  bottom: calc(12.5vw + 40px);
  background-color: var(--clr-sub-01);
  animation: 12s fuwafuwa2 infinite;
}
.home_advice .ttl-01 {
  position: relative;
  z-index: 1;
}
.home_advice--txt {
  position: relative;
  font-size: 1.5rem;
  z-index: 1;
}
.home_advice--posts {
  position: relative;
  z-index: 10;
}
.home_advice--txt.anime {
  clip-path: inset(100% 0 0 0);
  transform: translateY(20px);
  transition: transform 0.4s ease, clip-path 0.4s ease;
}
.home_advice--txt.anime.active {
  clip-path: inset(0 0 0 0);
  transform: translateY(0);
}
@media screen and (max-width: 767px) {
  .home_advice {
    padding-top: 12.5vw;
    padding-bottom: calc(12.5vw + 40px);
    overflow: hidden;
  }
  .home_advice--txt {
    font-size: 1rem;
  }
  .home_advice--deco.triangle {
    top: 104px;
    left: 0;
    width: 300px;
    height: 270px;
  }
  .home_advice--deco.circle1 {
    top: 6vw;
    left: 16px;
    animation: 20s fuwafuwa3 infinite;
  }
  .home_advice--deco.circle2 {
    right: 16px;
    bottom: calc(12.5vw + 16px);
  }
  
}

/* ##############################################################################

    PAGE

############################################################################## */

/* sitemap
*************************************************** */
.sitemap--menu {
  flex: 1;
}
.sitemap--menu a,
.sitemap--menu span {
  display: block;
}
.sitemap--menu a {
  position: relative;
}
.sitemap--menu a:hover {
  color: #1d7fb7;
}
.sitemap--menu > li > a {
  font-size: 1.125rem; /* 18px */
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 0.5em;
  margin-bottom: 1.25em;
}
.sitemap_sub_nav--wrap {
  padding-left: 1.25em;
  margin-bottom: 1.5em;
}
.sitemap_sub_nav a {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  padding-left: 1.25em;
}
.sitemap_sub_nav a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  border-top: 1px solid #1d7fb7;
  border-right: 1px solid #1d7fb7;
  transform: rotate(45deg);
}

@media print, screen and (min-width: 768px) {
  .sitemap--menu:not(:last-child) {
    margin-right: 7.41%; /* 80px */
  }
}
@media screen and (max-width: 767px) {
  .sitemap--menu:not(:last-child) {
    margin-bottom: 24px;
  }
}

/* contact
********************************************** */
.contact_flow--ttl {
  margin-bottom: 16px;
  font-size: 1.25rem;
}
.contact_flow--step::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 11px;
  width: 1px;
  height: 96%;
  border-right: 2px dotted #88cccc;
}
.contact_flow--step li {
  position: relative;
  counter-increment: number;
  padding-left: 38px;
}
.contact_flow--step li:not(:last-child) {
  margin-bottom: 16px;
}
.contact_flow--step li::before {
  content: counter(number);
  position: absolute;
  top: 2px;
  left: 0;
  background-color: #88cccc;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0;
  color: var(--clr-wht);
}
.form_detail {
  gap: 40px;
}
@media screen and (min-width: 768px) {
  .form_detail--txtarea {
    position: fixed;
    left: 0%;
    width: 60%;
    height: calc(100vh - 96px);
    padding-inline: 5%;
    padding-bottom: 96px;
    overflow: scroll;
  }
  .form_detail--txtarea::-webkit-scrollbar {
    display: none;
  }
}

.form_detail--txtarea .ttl-01 {
  text-align: left;
}
.form_detail--txtarea .box {
  padding: 24px 40px;
  border: 1px solid #dfdfdf;
}
.form_detail--copy {
  width: 100%;
  height: 96px;
  padding-top: 16px;
}
.form_detail--copy .copy {
  display: grid;
  place-content: center;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .form_detail--copy {
    position: absolute;
    width: 100%;
    left: 0;
    height: 96px;
  }
}
@media screen and (max-width: 768px) {
  .form_detail--copy {
    height: 64px;
    padding-top: 16px;
  }
}

.contact_companies_logo h3 {
  padding-bottom: 5px;
  position: relative;
  padding-left: 18px;
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: 0.04em;
  font-family: outfit, var(--font-jp-b), sans-serif;
  font-weight: 500;
}
.contact_companies_logo h3::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 90%;
  border-radius: 4px;
  background: var(--clr-blk);
}
.contact_companies_logo img {
  padding-block: 24px 32px;
  width: 100%;
  height: auto;
}
.contact_companies_logo.companies_logo .pc {
  display: block;
}
.contact_supporter--lists {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 14px;
}

/* .contact_voice {
  margin-bottom: 64px;
  padding: 40px 60px;
  border: 1px solid;
}
.contact_voice .txt {
  flex: 1;
  margin-left: 40px;
  text-align: justify;
}
.contact_voice .ttl {
  margin-bottom: 16px;
  font-size: 1.25rem;
  line-height: 1.77;
}
.contact_voice .img {
  position: relative;
}
.contact_voice .img .deco {
  position: absolute;
  width: 45px;
  height: auto;
  right: -20px;
  bottom: -6px;
  transform: rotate(75deg);
}
.contact_voice .img .voice_img {
  border-radius: 50%;
} */

/* .contact--intro .inner {
  padding: 60px 40px;
  border-radius: 24px;
  background-color: var(--clr-bg-02);
}
.contact--intro .img {
  flex: 1;
  margin-right: 24px;
}
.contact--intro .txt {
  text-align: justify;
  text-justify: inter-ideograph;
}
.contact--intro ul {
  list-style: disc;
  margin-left: 1.5em;
} */

#formy_form {
  margin-inline: auto;
  margin-bottom: auto;
}
#formy_form .linebox {
  background-color: var(--clr-wht);
}

@media screen and (min-width: 768px) {
  .form_wrap {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 40%;
    background-color: #EEE;
  }
  .form_wrap::before {
    content: "";
    position: absolute;
    right: 100%;
    top: 12%;
    width: 48px;
    height: 80px;
    background-color: #EEE;
    clip-path: polygon(100% 0, 0 50%, 100% 100%);
  }
  .form_wrap .innerbox {
    padding: 56px 10%;
    height: 100%;
    overflow: scroll;
  }
  .form_wrap .innerbox::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  #formy_form {
    padding: 16px 20px;
    border-radius: 16px;
    background-color: #EEE;
  }
  .form_wrap {
    margin-inline: 7%;
  }
}

/* ##############################################################################

  FORM

############################################################################## */
.nf-form-cont .nf-form-content label,
.nf-form-cont .nf-field-label .nf-label-span {
  font-size: min(16px, 1.125rem);
}
@media screen and (max-width: 47.9375em) {
  .nf-form-cont .nf-form-content label,
  .nf-form-cont .nf-field-label .nf-label-span {
    margin-bottom: 6px !important;
  }
  .nf-form-cont .nf-form-content label,
  .nf-form-cont .nf-field-label .nf-label-span {
    font-size: 1rem;
  }
}

.nf-form-cont .nf-field-label,
.nf-form-cont .nf-form-content .nf-label-span {
  font-family: "Noto Sans JP", YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
}

.nf-form-cont .nf-form-fields-required {
  display: none !important;
}

.nf-form-cont .nf-field-element {
  font-family: "Noto Sans JP", YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  font-weight: 500 !important;
}

#ninja_forms_required_items,
.nf-form-cont .nf-field-container {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  #ninja_forms_required_items,
  .nf-form-cont .nf-field-container {
    margin-bottom: 1.1428571429rem;
  }
}

.nf-form-cont .nf-form-content .list-select-wrap .nf-field-element > div,
.nf-form-cont .nf-form-content input:not([type="button"]),
.nf-form-cont .nf-form-content textarea {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
}

.nf-form-cont .nf-form-content .list-select-wrap .nf-field-element > div,
.nf-form-cont .nf-form-content input:not([type="button"]),
.nf-form-cont .nf-form-content textarea {
  background: var(--clr-wht);
  background: var(--clr-wht);
  color: #404044;
  color: var(--clr-text-primary);
  border-radius: 0.1875rem;
  vertical-align: bottom;
  font-size: 16px;
  transition: all 0.4s ease-out;
  transition: var(--transit-default, all 0.4s ease-out);
  font-weight: 500;
}

.nf-form-cont .nf-form-content .list-select-wrap .nf-field-element > div:focus,
.nf-form-cont .nf-form-content input:not([type="button"]):focus,
.nf-form-cont .nf-form-content textarea:focus {
  color: #404044;
  color: var(--clr-text-primary);
}

.nf-form-content .list-checkbox-wrap .nf-field-element li label,
.nf-form-content .list-image-wrap .nf-field-element li label,
.nf-form-content .list-radio-wrap .nf-field-element li label {
  cursor: pointer;
}

.nf-form-content input[type="checkbox"]:focus + label:after,
.nf-form-content input[type="radio"]:focus + label:after {
  box-shadow: none;
}

.checkbox-wrap .nf-field-element label.nf-checked-label:before,
.checkbox-wrap .nf-field-label label.nf-checked-label:before,
.listcheckbox-wrap .nf-field-element label.nf-checked-label:before,
.listcheckbox-wrap .nf-field-label label.nf-checked-label:before {
  color: #1780b8;
}
.nf-form-cont .nf-form-content .submit-wrap {
  text-align: center;
}

.nf-form-cont .nf-form-content .submit-wrap input:not([type="button"]) {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
}

.nf-form-cont .nf-form-content .submit-wrap input:not([type="button"]) {
  max-width: 100%;
  width: 20rem;
  height: 4rem;
  font-size: max(16px, 1.125rem);
  line-height: 1;
  letter-spacing: 0.1em;
  color: var(--clr-wht);
  background-color: #f83835;
  border-radius: 3.125rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  transition: all 0.4s ease-out;
  transition: var(--transit-default, all 0.4s ease-out);
}
.nf-form-cont .nf-form-content .submit-wrap input:not([type="button"]):hover {
  background-color: #55b2eb;
}
.nf-pass.field-wrap .nf-field-element:after {
  display: none;
}
@media screen and (min-width: 48em) {
  .nf-form-cont .nf-form-content .submit-wrap input:not([type="button"]) {
    margin-top: 1.7142857143rem;
  }
}

.nf-form-cont .nf-form-errors {
  margin-top: -1em;
  text-align: center;
}
.mce-content-body > .hearing_ninja_form form {
  border-radius: 24px;
  padding: 40px;
  border-radius: 20px;
  border: 1px solid #ddd;
  background-color: var(--clr-wht);
  font-family: "Noto Sans JP", YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "sans-serif" !important;
}
.mce-content-body > .hearing_ninja_form form ul li::before {
  display: none;
}
.mce-content-body > .hearing_ninja_form .nf-form-content .nf-field-label label,
.mce-content-body > .hearing_ninja_form .nf-field-label .nf-label-span {
  width: 100%;
  font-weight: 400 !important;
}
.mce-content-body > .hearing_ninja_form .nf-form-fields-required {
  display: none;
}
.hearing_ninja_form .nf-field-label,
.hearing_ninja_form .nf-field-element {
  padding: 16px;
  font-size: 16px;
}
.hearing_ninja_form .nf-field-element {
  padding: 0;
  margin: 16px;
}
.hearing_ninja_form .textarea-container .nf-field-element {
  margin-right: 0;
}
nf-field:last-child .nf-field-label,
nf-field:last-child .nf-field-element {
  border-bottom: none;
}
.hearing_ninja_form .nf-field-description {
  padding-left: calc(50% + 16px);
  margin-left: 0 !important;
  border-top: 1px solid var(--clr-wht);
  transform: translateY(-1px);
  z-index: 999;
}
.hearing_ninja_form .listcheckbox-container .nf-field-element label:after {
  top: 1px;
}
.hearing_ninja_form .listcheckbox-container .nf-field-description {
  margin-bottom: 16px;
  transform: translateY(-16px) !important;
}
.hearing_ninja_form .list-checkbox-wrap .nf-field-element ul,
.hearing_ninja_form .list-radio-wrap .nf-field-element ul {
  margin-top: 1.5em !important;
}
.hearing_ninja_form .nf-field-description p span {
  font-family: "Noto Sans JP", YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "sans-serif" !important;
}
.hearing_ninja_form .nf-field-element {
  flex: 1;
}
.hearing_ninja_form .nf-form-content textarea {
  background-color: var(--clr-wht);
}
.hearing_ninja_form .listcheckbox-wrap .nf-field-element label::after,
.hearing_ninja_form .listradio-wrap .nf-field-element label::after {
  background-color: var(--clr-wht);
}
.hearing_ninja_form .nf-form-content .listcheckbox-wrap li {
  margin-bottom: 1.5em !important;
}
.hearing_ninja_form .nf-form-content input.ninja-forms-field {
  padding: 5px 15px;
}
.hearing_ninja_form .nf-form-content input.ninja-forms-field {
  height: 36px;
}
.hearing_ninja_form .nf-form-content input:not([type="button"]) {
  background-color: var(--clr-wht);
}
.hearing_ninja_form .label-left .nf-field-label {
  width: 50%;
  padding-right: 24px;
  align-items: center !important;
  text-align: left;
}
.hearing_ninja_form .nf-error.field-wrap .nf-field-element:after {
  top: 0;
  bottom: 60;
  right: 0;
  height: 36px;
  width: 36px;
  line-height: 36px;
}
.page-contact.page-product-contact #formy_form,
.mce-content-body > .hearing_ninja_form {
  width: auto;
  max-width: 960px;
  margin-inline: auto;
  padding: 26px 40px 20px;
  background: rgb(244, 240, 236);
  border-radius: 24px;
}
.mce-content-body > .hearing_ninja_form {
  max-width: 1080px;
}
.nf-after-field .nf-error-msg {
  margin-left: 23.5%;
}
.page-improvement .form_wrap {
  padding-top: 80px;
}
.form_wrap .mce-content-body {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .contact_flow--step::before {
    height: 97%;
  }
  .contact_companies_logo.companies_logo .pc {
    display: none;
  }
  .label-left .nf-after-field {
    margin-left: 0 !important;
  }
  .nf-after-field .nf-error-msg {
    margin: 0;
  }
  .nf-error-msg {
    font-size: 12px;
  }
  /* .contact_voice {
    padding: 24px;
  }
  .contact_voice .img {
    margin: 0 auto;
  }
  .contact_voice .txt {
    margin-top: 24px;
    margin-left: 0;
  }
  .contact_voice .ttl {
    font-size: 1rem;
  } */

  /* .contact--intro .inner {
    padding: 32px 20px;
    border-radius: 16px;
  }
  .contact--intro .txt {
    font-size: 0.888rem;
  }
  .contact--intro .img {
    margin-right: 0;
    margin-bottom: 16px;
  } */

  .form_detail {
    gap: 24px;
  }

  .page-contact #formy_form,
  .page-contact.page-product-contact #formy_form,
  .mce-content-body > .hearing_ninja_form,
  .single-e-book #formy_form {
    width: 100%;
    padding: 16px 20px;
    margin-inline: auto;
    border-radius: 16px;
    @include mq(sp) {
      padding: sprem(24) sprem(8);
    }
  }
  .page-contact.page-product-contact #formy_form .linebox {
    padding-top: 24px;
  }
  .mce-content-body > .hearing_ninja_form form {
    padding: 24px;
  }
  .hearing_ninja_form .label-left .nf-field-label {
    width: 100%;
    padding-right: 0;
    border-bottom: none;
  }
  .hearing_ninja_form .nf-field-label,
  .hearing_ninja_form .nf-field-element {
    padding: 0;
  }
  .hearing_ninja_form .nf-field-element {
    margin: 0;
    padding-bottom: 8px;
  }
  /* .hearing_ninja_form .nf-field-element h3 {
    border-bottom: 1px solid #fff;
    transform: translateY(1px);
  } */
  .hearing_ninja_form .listcheckbox-container .nf-field-description {
    margin-bottom: 0;
    transform: translateY(0) !important;
  }
  .hearing_ninja_form .nf-field-description {
    margin-left: 0 !important;
    padding: 0 !important;
  }
  .hearing_ninja_form .textarea-wrap .nf-field-description,
  .hearing_ninja_form .list-radio-wrap .nf-field-description {
    order: 50;
  }

  .hearing_ninja_form .label-left .nf-field-label {
    margin-right: 0;
  }
  .hearing_ninja_form .nf-error.field-wrap .nf-field-element:after {
    top: 0;
    right: 1px;
  }
}

/* thanks
********************************************** */
.page .posts-interview .post--img img {
  margin: 0;
  height: 100%;
}
.page .posts-interview .post--catch {
  position: static;
  background: none;
  padding: 0;
  margin: 0 0 16px;
  line-height: 1.5;
  text-align: justify;
  text-justify: inter-ideograph;
}
.page .posts-interview .post--catch a {
  text-decoration: none;
  color: #333;
}
.page-thanks .footer {
  margin-top: 0;
}
.interview--post--img {
  position: relative;
  width: 100%;
  height: 21.5vw;
  overflow: hidden;
}
.interview--post--img img {
  transition: all .4s ease;
}

@media screen and (max-width: 767px) {
  .page .posts-works .post {
    width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }
  .page .posts-interview .post {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .page .posts-interview .post--catch {
    margin-bottom: 8px;
  }
  .page .posts-interview .post--catch a {
    display: block;
    font-size: 1rem;
    line-height: 1.4;
  }
  .page .posts-interview .post--txt {
    flex: 1;
  }
}

/* ##############################################################################

    ARCHIVE

############################################################################## */

/* modal_search
**************************************** */
.modal_search {
  position: absolute;
  z-index: 10000;
  opacity: 0;
  pointer-events: none;
  transition: opacity .4s;
}
.modal_search.is-show {
  opacity: 1;
  pointer-events: auto;
}
.modal_search--main {
  position: fixed;
  inset: 0;
  padding-block: 4.5rem 10rem;
  background-color: rgba(238, 238, 238, 0.90);
  backdrop-filter: blur(1.25rem);
  height: 100%;
  overflow: auto;
  overscroll-behavior-y: contain;
}
.modal_search_close {
  position: fixed;
  right: 4.5rem;
  top: 4.5rem;
  z-index: 100000;
  display: grid;
  place-content: center;
  width: 3.125rem;
  border: 2px solid;
  aspect-ratio: 1;
  border-radius: 50%;
  cursor: pointer;
}
.modal_search_item:first-child {
  padding-top: 1rem;
  border-top: 1px solid var(--clr-mono-03);
}
.modal_search_item:not(:last-child) {
  margin-bottom: 1rem;
}

/* color-tag */
.color-tag {
  display: inline-block;
  width: 1.25em;
  aspect-ratio: 1;
  border-radius: 50%;
  margin-right: .3rem;
  vertical-align: middle;
  overflow: clip;
  background-color: var(--color);
}
.color-tag:is([style*="--color:#ffffff"], [style*="--color: #ffffff"]) {
  border: 1px solid var(--clr-text-placeholder);
}
.color-tag.colorful::before,
.color-tag.colorful::after {
  content: '';
  display: block;
  width: 100%;
  height: 50%;
}
.color-tag.colorful::before {
  background: linear-gradient(90deg, rgba(233,82,106,1) 50.555%, rgba(0,169,235,1) 50.556%);
}
.color-tag.colorful::after {
  background: linear-gradient(90deg, rgba(169,207,82,1) 50.555%, rgba(255,243,57,1) 50.556%);
}

/* modal_search_item--check */
.modal_search_item--check ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 1.5rem 2rem;
  border-radius: .5rem;
  background-color: var(--clr-bg-01);
}
.modal_search_item--check :is(input[type="checkbox"], input[type="radio"]) {
  display: none !important;
}
.modal_search_item--check label {
  --a_padding: 2rem;

  position: relative;
  display: block;
  padding: .2em;
  padding-left: var(--a_padding);
  font-size: 1.125rem;
  line-height: 1.8;
  letter-spacing: 0.05em;
  cursor: pointer;
}
.modal_search_item--check label:hover {
  opacity: 0.7;
}
.modal_search_item--check label::before {
  --dot_w: 1rem;

  content: "";
  width: var(--dot_w);
  aspect-ratio: 1;
  float: inline-start;
  margin-block: calc((1lh - var(--dot_w)) / 2);
  margin-inline-start: calc(var(--a_padding) * -1);
  border-radius: 4px;
  border: 2px solid var(--clr-text-placeholder);
}
.modal_search_item--check input[type="radio"] + label::before {
  border-radius: 10px;
}
.modal_search_item--check :is(input[type="radio"], input[type="checkbox"]):checked + label {
  color: var(--clr-sub-03);
  font-weight: bold;
}
.modal_search_item--check :is(input[type="radio"], input[type="checkbox"]):checked + label::before {
  border-color: var(--clr-sub-03);
  opacity: 0.5;
}
.modal_search_item--check :is(input[type="radio"], input[type="checkbox"]):checked + label::after {
  --dot_w: 1rem;

  content: "";
  display: block;
  border-right: 2px solid var(--clr-sub-03);
  border-bottom: 2px solid var(--clr-sub-03);
  transform: rotate(45deg);
  width: calc(var(--dot_w) / 2);
  float: inline-start;
  margin-block: calc((1lh - var(--dot_w)) / 4);
  margin-inline-start: calc(var(--a_padding)* -0.8);
  aspect-ratio: 1 / 2;
}

/* modal_search_item--more */
.modal_search_item--more {
  margin-top: 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--clr-mono-03);
  background-color: var(--clr-wht);
}
.modal_search_item--more .toggle {
  padding: 1.5rem 2rem;
  cursor: pointer;
  transition: opacity .4s ease-out;
}
.modal_search_item--more .toggle:hover {
  opacity: 0.5;
}
.modal_search_item--more .toggle span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  border-radius: 50%;
  aspect-ratio: 1;
  background-color: var(--clr-primary-default);
}
.modal_search_item--more .toggle span::before,
.modal_search_item--more .toggle span::after {
  content: '';
  display: block;
  width: 1.125rem;
  color: var(--clr-wht);
  border-bottom: 2px solid;
  transition: opacity .2s ease-out;
}
.modal_search_item--more .toggle span::before {
  position: absolute;
  transform: rotate(90deg);
}
.modal_search_item--more .toggle.is-active span::before {
  opacity: 0;
}
.modal_search_item--child {
  display: none;
  padding-bottom: 1.5rem;
  padding-inline: 2rem;
}

/* modal_search--fix */
.modal_search--fix {
  position: fixed;
  bottom: 0;
  inset-inline: 0;
  z-index: 10000;
  padding-block: 1rem;
  background-color: rgba(0, 0, 0, 0.80);
}

/* modal_search_btns */
.modal_search_btns {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.modal_search_btns--result {
  display: flex;
  align-items: baseline;
  gap: .3em;
  font-size: 1.25rem;
  padding-inline: 2.5rem;
}
.modal_search_btns--result .cnt {
  font-size: 2.5rem;
}
.modal_search_btns--btn {
  display: flex;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 288px;
  border-radius: 0.5rem;
  border: none;
  color: var(--clr-mono-04);
  border: 1px solid var(--clr-mono-04);
  font-size: 1.25rem;
  line-height: 1.7;
  letter-spacing: 0.05em;
  cursor: pointer;
  transition: opacity .4s ease-out;
}
.modal_search_btns--btn[type="submit"] {
  width: 480px;
  color: var(--clr-wht);
  background-color: var(--clr-sub-02);
  border: none;
}
.modal_search_btns--btn:hover {
  opacity: 0.5;
}
.modal_search_btns--btn svg {
  fill: var(--clr-wht);
}

@media screen and (max-width: 767px) {
  .modal_search--main {
    padding-block: 2rem 10rem;
  }
  .modal_search_item--child {
    padding-inline: 1.5rem;
  }
  .modal_search--ttl {
    gap: 8px;
  }
  .modal_search--ttl svg {
    width: 1.4285714286rem;
    height: auto;
  }
  .modal_search_close {
    right: 1rem;
    top: 1rem;
  }

  /* modal_search_item--check */
  .modal_search_item--check ul {
    grid-template-columns: repeat(1, 1fr);
    padding: .5rem 1rem;
  }
  .modal_search_item--check label {
    font-size: 1rem;
  }

  .modal_search_item--more {
    margin-block: 1.5rem;
  }
  .modal_search_item--more .toggle {
    padding: .7rem 1.3rem;
    font-size: 1rem;
  }
  .modal_search_item--more .toggle span {
    width: 2.5rem;
  }

  /* modal_search_btns */
  .modal_search_btns {
    flex-wrap: wrap;
  }
  .modal_search_btns--result {
    font-size: 1rem;
  }
  .modal_search_btns--result .cnt {
    font-size: 1.75rem;
  }
  .modal_search_btns {
    gap: .5rem;
  }
  .modal_search_btns--btn {
    gap: .5rem;
    width: calc(50% - 7px) !important;
    padding: .7rem;
    font-size: .9rem;
    letter-spacing: 0;
  }
  .modal_search_btns--btn svg {
    width: 1rem;
    height: fit-content;
  }
}

/* main_column
**************************************** */
.main_column {
  flex: 1;
  margin-left: 6.06vw;
}
.single-blog .main_column {
  width: calc(100% - 280px - 6.06vw);
}
.container {
  overflow: clip;
}

/* --- post --- */
.post {
  position: relative;
}
.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}
.post--date {
  color: var(--clr-blk);
  font-size: 0.875rem;
  line-height: 1.5;
}
.post--info.flex .post--date + .cat_list {
  margin-left: 16px;
  flex: 1;
}
.cat_list {
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.825rem;
  background-color: var(--clr-bg-02);
  padding: 0 12px;
  float: left;
  white-space: nowrap;
  border-radius: 24px;
  margin: 2px;
}
.cat_list a:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1400px) {
  .main_column {
    margin-left: 4vw;
  }
  .single-blog .main_column {
    width: calc(100% - 280px - 4vw);
  }
}
@media screen and (max-width: 1100px) {
  .container > .inner.flex {
    display: block;
  }
  .main_column,
  .single-blog .main_column {
    width: 100%;
    margin-bottom: 40px;
    margin-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .cat_list a {
    font-size: 0.7857rem;
    padding: 0 10px;
  }
}

/* mini_fixed */
.mini_fixed {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 10;
  display: flex;
  padding-block: min(vw(20),20px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: min(vw(20),20px);
  color: var(--clr-sub-04);
  font-size: 0.8125rem;
  border-radius: 0.5rem 0 0 0.5rem;
  background-color: var(--clr-wht);
  box-shadow: var(--shadow-01);
}
.mini_fixed .link {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
  padding: min(vw(16),16px) min(vw(20),20px);
  font-size: clamp(12px, vw(18), 16px);
  cursor: pointer;
  transition: opacity .4s ease-out;
}
.mini_fixed .link:hover {
  opacity: .5;
}
.bookmark-cnt {
  position: relative;
}
.bookmark-cnt[data-cnt]::before {
  content: attr(data-cnt);
  position: absolute;
  right: -8px;
  top: -4px;
  display: grid;
  place-content: center;
  width: 17px;
  color: var(--clr-wht);
  text-align: center;
  font-family: var(--font-en);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: var(--clr-secondary-default);
}
@media screen and (max-width: 767px) {
  .mini_fixed {
    position: sticky;
    top: 56px;
    padding: 0;
    border-block: 1px solid var(--clr-mono-02);
    border-radius: 0;
    box-shadow: none;
  }
  .mini_fixed.sticky {
    box-shadow: var(--shadow-01);
  }
  .mini_fixed ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
  .mini_fixed li:first-child {
    border-right: 1px solid var(--clr-mono-02);
  }
  .mini_fixed .link {
    justify-content: center;
    flex-direction: row-reverse;
    gap: 8px;
    padding: 1rem;
    font-size: 12px;
  }
  .mini_fixed--icon svg,
  .mini_fixed--icon img {
    width: fit-content;
    height: fit-content;
    max-height: 20px;
    max-width: 20px;
  }
  .bookmark-cnt[data-cnt]::before {
    width: 12px;
    font-size: 8px;
  }
}

/* side_column
**************************************** */
.side_column {
  position: relative;
  width: 240px;
  margin-bottom: 40px;
}
.sidebar_search {
  position: relative;
  margin-bottom: 40px;
}
@media screen and (max-width: 1100px) {
  .side_column {
    width: 100%;
  }
}

.side_section:not(:last-child) {
  margin-bottom: 32px;
}
.side_bnr {
  &:has(+ .side_bnr) {
    margin-bottom: rem(24);
  }
  a:hover {
    opacity: 0.7;
  }
  @include mq(sp) {
    width: 86%;
    margin-inline: auto;
  }
}
.count_box {
  padding-bottom: .3em;
  font-size: 1.25rem;
  letter-spacing: 0;
  line-height: 1.1;
  border-bottom: 3px solid;
}
.count_box--num {
  font-size: 2.5rem;
}
.side--btn {
  display: flex;
  gap: .5rem;
  margin-inline: -1em;
  padding: 1em;
  color: var(--clr-text-primary);
  font-size: 1.125rem;
  line-height: 1;
  letter-spacing: 0.05em;
}
.side--btn img {
  transform: rotate(90deg);
}
.side--btn:hover {
  opacity: .5;
}
.side--ttl {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 20px;
  font-size: 1.5rem;
  letter-spacing: 0.2em;
  line-height: 1;
}
.side--ranking .side--ttl {
  margin-bottom: 24px;
}
.side--ttl .en {
  display: inline-block;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  font-weight: 500;
}
.side_fix {
  position: sticky;
  top: 110px;
}
.side_fix:not(.cta_side) {
  transition: 0.4s all ease;
}
.side_fix:not(.cta_side):hover {
  opacity: 0.7;
}
.cta_side .btn-cta a {
  padding-inline: rem(16);
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: 16px;
}
.posts-side .post--img {
  margin-right: 16px;
  width: 64px;
  height: 64px;
}
.posts-side .txtarea {
  flex: 1;
}
.posts-side .post--date {
  margin-bottom: 0;
}
.posts-side .post--ttl {
  line-height: 1.4;
}

/* --- archive --- */
.side_column .archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
}
.side_column .archive_list--ttl::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(135deg);
  transition: all 0.4s ease;
}
.side_column .archive_list--ttl.active::after {
  transform: rotate(315deg);
}
.side_column .archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 1;
}
.archive-pulldown .archive_list {
  position: relative;
}
.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 24px;
}
.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
}
.archive-pulldown .archive_list a:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.archive-pulldown .archive_list a:hover {
  background-color: #eee;
}
.archive-pulldown .archive_list--label {
  margin-right: 8px;
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.25em 4em 0.25em 2em;
  background-color: var(--clr-wht);
  border: 1px solid #ccc;
  font-family: "YuGothic", "游ゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: 0.08em;
}
.archive-pulldown .archive_list--btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0.4em;
  right: 0.8em;
  margin: auto;
  width: 0.6em;
  height: 0.6em;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
  transition: all 0.2s ease;
}
.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: var(--clr-wht);
  border: 1px solid #ccc;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all 0.2s ease;
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: 16px;
}
.posts-side .post--img {
  margin-right: 16px;
  width: 72px;
  height: 72px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.15);
}
.posts-side .post--num {
  position: absolute;
  left: -8px;
  top: -8px;
  z-index: 1;
  width: 22px;
  height: 22px;
  line-height: 20px;
  border-radius: 11px;
  text-align: center;
  color: var(--clr-wht);
  font-size: 80%;
  letter-spacing: 0em;
  background-color: #283648;
  border: 1px solid;
}
.posts-side .post:nth-child(-n + 3) .post--num {
  width: 28px;
  height: 28px;
  line-height: 26px;
  border-radius: 14px;
  font-size: 100%;
  top: -12px;
  left: -12px;
}
.posts-side .post:nth-child(1) .post--num {
  background-color: #827800;
}
.posts-side .post:nth-child(2) .post--num {
  background-color: var(--clr-text-secondary);
}
.posts-side .post:nth-child(3) .post--num {
  background-color: #d93e18;
}
.posts-side .txtarea {
  flex: 1;
}
.posts-side .post--date {
  font-size: 80%;
  margin-bottom: 8px;
}
.posts-side .post--ttl {
  line-height: 1.7;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.side--news.side--ranking .txtarea,
.side--mailmagazine .side--ranking .txtarea {
  padding-left: 28px;
}
.side--news.side--ranking .post--num,
.side--mailmagazine .side--ranking .post--num {
  top: -5px !important;
  left: 0 !important;
}
.side--news .post,
.side--mailmagazine .post {
  margin-bottom: 16px;
  padding-bottom: 16px;
  padding-inline: 8px;
  margin-inline: 8px;
}
.side--news .post:not(:last-child),
.side--mailmagazine .post:not(:last-child) {
  border-bottom: 1px dotted #ccc;
}

/* --- accordion --- */
.accordion_cont {
  display: none;
}
.accordion_btn .icon {
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  display: block;
  margin-left: auto;
  margin-block: calc((1lh - 18px) / 2);
  width: 18px;
  aspect-ratio: 1;
  background-color: var(--clr-mono-03);
  border-radius: 50%;
  cursor: pointer;
}
.accordion_btn .icon::before,
.accordion_btn .icon::after {
  content: "";
  position: absolute;
  inset: 4px;
  margin: auto;
  width: calc(100% - 8px);
  height: 2px;
  background-color: var(--clr-wht);
  transition: transform 0.4s ease;
}
.accordion_btn .icon::after {
  transform: rotate(90deg);
}
.accordion_btn.is-open .icon::after {
  transform: rotate(0);
}
.accordion_btn-child {
  position: relative;
  width: 18px;
  margin-left: auto;
  border-radius: 50%;
  aspect-ratio: 1;
}
.accordion_btn-child .icon {
  background-color: var(--clr-mono-02);
  margin-block: 0;
}

/* --- side cat --- */
.side--ttl_cat {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding-block: 8px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: opacity .4s ease-out;
}
.side--ttl_cat:hover {
  opacity: .5;
}
.side--cat .side--list {
  margin-block: 10px;
}
.side--cat .side--list:not(.faq_cat) {
  padding-left: 16px;
}
.side--cat .side--list li {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.side--cat .side--list > li:not(:last-child) {
  margin-bottom: 10px;
}
.side--cat .side--list a {
  position: relative;
  display: block;
  padding-right: 16px;
  font-size: 0.9rem;
  line-height: 1.35;
  letter-spacing: 0.03em;
}
.side--cat .side--list a:hover {
  opacity: 0.5;
}
.side--ttl .jp {
  font-size: 0.875rem;
  letter-spacing: 0.05em;
}
.side--cat .side--link_list {
  display: none;
  width: 100%;
  margin-top: 10px;
  padding-left: 16px;
}
.side--cat .side--link_list li {
  margin-bottom: 8px;
}
.side--cat .side--link_list li a {
  color: var(--clr-text-secondary);
}
.side--list li a {
  --padding: 18px;

  position: relative;
  padding-left: var(--padding);
}
.side--cat_list-color {
  padding-left: 0 !important;
}
.side--cat_list-color li a::before {
  display: none;
}
.side--list li a::before {
  --width: 6px;

  content: "";
  width: var(--width);
  float: inline-start;
  margin-block: calc((1lh - var(--width)) / 2);
  margin-inline-start: calc(var(--padding) * -1);
  aspect-ratio: 1;
  background-color: var(--clr-sub-01);
  border-radius: 50%;
}

/* --- side search --- */
.side--search form {
  position: relative;
}
.side--search input[type="text"] {
  width: 100%;
  height: 40px;
  background-color: var(--clr-bg-02);
  border: none;
  padding: 0 16px;
  outline: 0;
}
.side--search button {
  position: absolute;
  top: 0;
  right: 8px;
  bottom: 0;
  margin: auto;
  appearance: none;
  border: none;
  background-color: transparent;
}

/* --- side sort --- */
.side--sort form {
  position: relative;
}
.side--sort select {
  width: 100%;
  height: 40px;
  padding-inline: 16px;
  outline: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  color: inherit;
  font-size: 14px;
  font-family: var(--font-jp-b);
  vertical-align: middle;
  border-radius: 4px;
  border: 1px solid var(--clr-mono-02);
  background-color: var(--clr-mono-01);
  background-image: url(../images/common/arrow-line.svg);
  background-position: right 10px center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 767px) {
  .archive .container .lps_sec > .inner,
  .single .container .lps_sec > .inner,
  .page-support-faq .lps_sec > .inner,
  .page-support-faq .lps_sec > .inner {
    flex-direction: column-reverse;
  }
}

/* works
**************************************** */
.posts-works {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: min(vw(68),68px) min(vw(43),43px);
  @include mq(sp) {
    grid-template-columns: repeat(1, 1fr);
    gap: 32px;
  }
}
.posts-works .bookmark_btn button {
  position: absolute;
  inset: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}
.posts-works .bookmark_btn button .loading-spinner {
  position: absolute;
  inset: 3px;
  border: 3px solid var(--clr-sub-01);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotate 1s linear infinite;
}
.posts-works .post--ttl {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.5rem;
}
.posts-works .post--ttl .bookmark_btn {
  --dot_w: 24px;

  position: relative;
  z-index: 2;
  display: grid;
  place-content: center;
  flex-shrink: 0;
  width: var(--dot_w);
  aspect-ratio: 1;
  margin-block: calc((1lh - var(--dot_w)) / 2);
}
.posts-works .post--ttl .bookmark_btn:hover {
  opacity: .5;
}
.posts-works .post--img {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  margin-bottom: 16px;
  overflow: hidden;
  box-shadow: 0 0 40px 0 rgba(69, 131, 175, 0.3);
  @include mq(sp) {
    box-shadow: 0 0 16px 0 rgba(69, 131, 175, 0.3);
  }
}
.posts-works .post--img img {
  transition: all 0.4s ease;
  // z-index: -1;
}
.posts-works .post:has(a:hover) .post--img > img {
  transform: scale(1.05);
}
.posts-works .post--terms li {
  color: var(--clr-mono-05);
}
.posts-works .post--terms li:not(:last-child)::after {
  content: "/";
  margin-inline: 2px;
  color: var(--clr-mono-02);
}
.post--award {
  position: absolute;
  left: 0;
  top: 50%;
  padding: rem(16) rem(8);
  height: 80%;
  translate: 0 -50%;
  background-color: #74BBB2;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: rem(8);
  span {
    font-family: var(--font-en);
    color: var(--clr-wht);
    font-size: rem(11);
    letter-spacing: 0.05em;
    font-weight: 600;
    line-height: 1;
    @include write-v;
  }
  img {
    width: rem(24);
  }
  @include mq(sp) {
    padding: sprem(12) sprem(8);
    span {
      font-size: sprem(12);
    }
    img {
      width: sprem(16);
    }
  }

}


/* works_info */
.works_info {
  gap: .5rem 1.5rem;
  padding-bottom: .75em;
  border-bottom: 3px solid;
}
.works_info .ttl {
  flex-shrink: 0;
  gap: 2rem;
}
.works_info--terms {
  gap: 4px;
  margin-right: 0;
  margin-left: auto;
}
.works_info--terms a {
  display: inline-block;
  padding: 0.125rem 0.625rem;
  color: var(--clr-text-secondary);
  line-height: 1.6;
  letter-spacing: 0.05em;
  border-radius: 2px;
  background-color: var(--clr-mono-01);
}
.works_info--terms a.current,
.works_info--terms a:hover {
  color: var(--clr-text-light);
  background: var(--clr-text-secondary);
}
.works_info--conditions {
  gap: 1.5rem;
  flex-grow: 1;
}
.works_info--conditions::before {
  content: '';
  width: 2rem;
  border-bottom: 2px solid;
  margin-block: calc(1lh - 1em);
}
.works_info--conditions-list:not(:last-child)::after {
  content: '｜';
}
.works_info--conditions-list span:not(:last-child)::after {
  content: ',';
}
.works_info--btns {
  flex-shrink: 0;
  margin-left: auto;
  margin-block: calc(1lh - 2em);
}
.works_info--btns a,
.works_info--btns li[data-modal_toggle] {
  padding: .5em 1em;
  line-height: 1;
  cursor: pointer;
  background-color: var(--clr-wht);
}
.works_info--btns li[data-modal_toggle] {
  gap: .5rem;
  background-color: var(--clr-mono-01);
  border-radius: 4px;
}
.works_info--btns li:hover {
  opacity: .5;
}

@media screen and (max-width: 767px) {
  .works_info {
    padding-bottom: 1em;
  }
  .works_info--conditions::before {
    display: none;
  }
  .works_info--terms {
    justify-content: center;
  }
  .works_info--btns {
    width: 100%;
    justify-content: center;
  }
}

/* interview
**************************************** */
.posts-interview {
  gap: 48px 4.16666vw; /* vw 80px */
  padding-inline: 2.5vw; /* vw 48px */
}
.posts-interview.size-l {
  padding-inline: 3.333vw; /* vw 64px */
}
.posts-interview .post {
  display: flex;
  align-items: center;
  gap: 1.666vw; /* vw 32px */
}
.posts-interview.size-l .post {
  gap: 2.08333vw; /* vw 40px */
}
.posts-interview .post--img {
  position: relative;
  width: clamp(160px, 12.5vw, 224px); /* vw 280px */
  aspect-ratio: 1;
  border-radius: 50% 50% 50% 8px;
  overflow: hidden;
}
.posts-interview.size-l .post--img {
  width: clamp(180px, 14.58333333333333vw , 254px); /* vw 280px */
}
.posts-interview .post--img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease;
}
.posts-interview .post--img img {
  transition: all 0.4s ease;
}
.posts-interview .post:hover .post--img img {
  transform: scale(1.05);
}
.posts-interview .post--txt {
  flex: 1;
}
.posts-interview .post--catch {
  font-size: 1.25rem;
  line-height: 1.5;
  font-family: var(--font-jp-b);
}
.posts-interview.size-l .post--catch {
  line-height: 1.7;
}
body:not(.archive-interview) .posts-interview .cat_list {
  display: none;
}
.posts-interview .cat_list {
  z-index: 10;
}
.interview_parts .cat_list a,
.posts-interview .cat_list a {
  letter-spacing: normal;
}

@media screen and (max-width: 1400px) {
  .posts-interview {
    gap: 48px 2.9166vw; /* vw 56px */
  }
}
@media screen and (max-width: 767px) {
  .posts-interview,
  .posts-interview.size-l {
    padding-inline: 0;
    gap: 32px 24px;
  }
  .posts-interview .post,
  .posts-interview.size-l .post {
    gap: 12px;
  }
  .posts-interview .post--img,
  .posts-interview.size-l .post--img {
    width: 120px;
  }
  .posts-interview .post--catch,
  .posts-interview.size-l .post--catch {
    font-size: 1rem;
    line-height: 1.4;
    margin-bottom: 8px;
  }
  .posts-interview .post--txt .txt-sm {
    line-height: 1.4;
  }
}

/* voice
**************************************** */
.posts-voice .post {
  padding: 0 24px 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #dfdfdf;
}
.posts-voice .post .imgarea .triangle {
  right: 30px;
  bottom: 0;
  transform: rotate(35deg);
}
.posts-voice .post .imgarea .circle {
  top: -10px;
  left: 34px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--clr-sub-02);
}
.posts-voice .post .img_in {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  -webkit-mask-image: url(../images/home/other_service_triangle.png);
  mask-image: url(../images/home/other_service_triangle.png);
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top right;
  mask-position: top right;
}
.posts-voice .post--ttl {
  font-size: 1.125rem;
}
.posts-voice .searvice_list {
  display: none;
}
.posts-voice.flex .searvice_list li,
.posts-voice .searvice_list li {
  margin-right: 24px;
  margin-top: 8px;
}
.voice_more {
  background-color: rgba(255, 255, 255, 0.75);
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 32px;
  border-radius: 4px;
}
.voice_more--faq {
  display: none;
}
.voice_more--faq_bloc:not(:last-child) {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e2e4df;
}
.voice_more--faq_q,
.voice_more--faq_a {
  padding-left: 24px;
}
.voice_more--faq_q {
  margin-bottom: 6px;
  line-height: 1.6;
}
.voice_more--faq_a {
  line-height: 1.8;
}
.voice_more--faq_q::before,
.voice_more--faq_a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  font-family: "futura-pt", sans-serif;
  font-weight: 500;
}
.voice_more--faq_q::before {
  content: "Q.";
}
.voice_more--faq_a::before {
  content: "A.";
  left: 1px;
  opacity: 0.5;
}
.voice_more--flag {
  position: absolute;
  right: 16px;
  top: 6px;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  background-color: #e2e4df;
}
.voice_more--flag::before,
.voice_more--flag::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.75);
  transform: translate(-50%, -50%);
  transform-origin: left top;
  transition: 0.4s cubic-bezier(0.48, 0.18, 0, 0.98);
}
.voice_more .voice_more--flag::after {
  transform: rotate(90deg) translate(-50%, -50%);
}
.voice_more.open {
  background-color: rgba(255, 255, 255, 0.5);
  padding-top: 32px;
  padding-bottom: 32px;
}
.voice_more.open .voice_more--flag {
  top: 16px;
}
.voice_more.open .voice_more--flag::before,
.voice_more.open .voice_more--flag::after {
  background-color: rgba(255, 255, 255, 0.5);
}
.voice_more.open .voice_more--flag::after {
  transform: rotate(0deg) translate(-50%, -50%);
}

@media screen and (max-width: 767px) {
  .posts-voice .post .imgarea .triangle {
    right: 0;
  }
  .posts-voice .post .imgarea .circle {
    left: 0;
  }
}

/* faq
**************************************** */
.faq_search_box {
  z-index: 10;
  background-color: var(--clr-wht);
  padding: 54px 0;
  border-radius: 24px;
  box-shadow: 0 0 24px rgba(91, 154, 153, 0.15);
  margin-top: 130px;
}
.faq_search_box--ttl {
  margin-bottom: 24px;
  text-align: center;
  font-size: 40px;
  line-height: 1;
  font-family: var(--font-jp-b);
}
#faq_search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
#faq_search input[type="text"] {
  width: 80%;
  height: 72px;
  border-radius: 8px;
  background-color: #f9f7f5;
  border: 1px solid #dbd6d1;
  font-size: 20px;
  padding: 24px;
  font-family: var(--font-jp-b);
  outline: 0;
}
#faq_search button[type="submit"] {
  width: 18%;
  height: 72px;
  border-radius: 8px;
  background-color: var(--clr-sub-01);
  border: 1px solid var(--clr-sub-01);
  font-size: 20px;
  line-height: 1;
  font-family: var(--font-jp-b);
  transition: all 0.4s ease-out;
  cursor: pointer;
}
#faq_search button[type="submit"]:hover {
  background-color: var(--clr-sub-02);
}
#faq_search button[type="submit"] svg {
  margin-right: 8px;
}
.popular_word li {
  margin: 4px;
}
.popular_word a {
  display: inline-block;
  border-radius: 99px;
  background-color: #f9f7f5;
  border: 1px solid #dbd6d1;
  padding: 2px 24px;
  transition: all 0.5s ease;
}
.popular_word a:hover {
  background-color: #dbd6d1;
}
.faq-ttl {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.faq-ttl .en {
  font-size: 64px;
  font-weight: 400;
}
.faq-ttl .jp {
  margin-left: 16px;
  margin-right: auto;
  font-size: 18px;
  font-family: var(--font-jp-b);
}
.faq-ttl a {
  font-size: 18px;
}
.faq_cat_nav {
  margin-bottom: 40px;
}
.faq_cat_nav li {
  position: relative;
  width: calc(100% / 3 - (16px * 3 / 2));
  background-color: #ccc;
  border-radius: 8px;
  padding: 16px;
  font-size: 24px;
  text-align: center;
  transition: all 0.4s ease-out;
  cursor: pointer;
}
.faq_cat_nav li:hover {
  background-color: var(--clr-sub-01);
}
.faq_cat_nav li:not(:last-child) {
  margin-right: 16px;
}
.faq_cat_nav li::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 32px;
  height: 16px;
  background-color: var(--clr-sub-02);
  clip-path: polygon(100% 0, 0 0, 50% 100%);
  transition: all 0.4s ease-out;
  opacity: 0;
}
.faq_cat_nav li.select {
  background-color: var(--clr-sub-02);
}
.faq_cat_nav li.select::before {
  bottom: -15px;
  opacity: 1;
}
.faq_cat_conts .bloc {
  display: none;
}
.faq_cat_conts .bloc.select {
  display: block;
}
.posts-faq {
  border-bottom: 1px solid #ddd;
}
.posts-faq .post {
  padding: 40px 124px 40px 40px;
  border: 1px solid #ddd;
  border-bottom: none;
  transition: all 0.5s ease;
}
.posts-faq .post:hover {
  background-color: #f5f5f5;
}
.posts-faq .post--ttl {
  margin-bottom: 8px;
  font-size: 24px;
  font-family: var(--font-jp-b);
}
.posts-faq .tag_list a {
  font-size: 15px;
}
.posts-faq .tag_list a:not(:last-child)::after {
  content: ", ";
}
.posts-faq .tag_list svg {
  width: 17px;
  height: 17px;
  vertical-align: middle;
}
.posts-faq .post--date,
.posts-faq .post--view {
  color: #9ea5a9;
  font-size: 15px;
  margin-left: 16px;
}
.posts-faq .post--date svg,
.posts-faq .post--view svg {
  width: 18px;
  height: 18px;
  fill: #9ea5a9;
  vertical-align: middle;
  transform: translateY(-2px);
  margin-right: 4px;
}
.posts-faq .btn-en {
  position: absolute;
  top: 0;
  right: 40px;
  bottom: 0;
  margin: auto 0;
  height: 48px;
}
.posts-faq .btn-en .arrow {
  width: 48px;
  height: 48px;
  background-color: var(--clr-sub-01);
}

.posts-views-faq .post {
  counter-increment: number;
  padding-left: 106px;
}
.posts-views-faq .post::before {
  content: "0" counter(number) ".";
  position: absolute;
  top: 27px;
  left: 40px;
  font-size: 32px;
  color: #88cccc;
  font-family: var(--font-en);
  font-weight: 700;
}

.search_contact_form {
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
}
.search_contact_form input[type="submit"] {
  font-size: 18px;
}

@media screen and (max-width: 767px) {
  .faq_search_box {
    padding: 28px 0;
    margin-top: -40px;
    border-radius: 8px;
  }
  .faq_search_box--ttl {
    font-size: 20px;
  }
  #faq_search {
    position: relative;
    margin-bottom: 0;
  }
  #faq_search input[type="text"],
  #faq_search button[type="submit"] {
    height: 46px;
    border-radius: 8px;
  }
  #faq_search input[type="text"] {
    width: 100%;
    padding: 6px 16px;
    font-size: 16px;
  }
  #faq_search button[type="submit"] {
    position: absolute;
    right: 0;
    background-color: transparent;
    border: none;
    font-size: 0;
  }
  #faq_search button[type="submit"] svg {
    margin-right: 0;
  }
  .popular_word a {
    padding: 2px 9px;
    font-size: 13px;
  }
  .faq-ttl {
    flex-direction: column;
  }
  .faq-ttl .en {
    font-size: 24px;
    font-weight: 700;
    color: #88cccc;
  }
  .faq-ttl .jp {
    margin-top: 4px;
    margin-left: 0;
    margin-right: 0;
    font-size: 24px;
    text-align: center;
  }
  .faq_cat_nav {
    margin-bottom: 24px;
  }
  .faq_cat_nav li {
    position: relative;
    width: calc(100% / 3 - (8px * 3 / 2));
    border-radius: 4px;
    padding: 16px 8px;
    font-size: 14px;
    line-height: 1.5;
  }
  .faq_cat_nav li:not(:last-child) {
    margin-right: 8px;
  }
  .faq_cat_nav li::before {
    width: 20px;
    height: 10px;
  }
  .faq_cat_nav li.select::before {
    bottom: -10px;
  }
  .posts-faq {
    margin-bottom: 32px;
  }
  .posts-faq .post {
    padding: 24px 64px 24px 16px;
  }
  .posts-faq .post--ttl {
    font-size: 16px;
    line-height: 1.55;
  }
  .posts-faq .post--info {
    align-items: flex-start;
  }
  .posts-faq .tag_list a,
  .posts-faq .post--date,
  .posts-faq .post--view {
    font-size: 14px;
  }
  .posts-faq .post--date,
  .posts-faq .post--view {
    font-size: 14px;
    margin-left: 0;
    margin-top: 2px;
  }
  .posts-faq .btn-en {
    top: 0;
    right: 16px;
    bottom: 0;
  }
  .posts-faq .btn-en .arrow {
    width: 40px;
    height: 40px;
  }
  .posts-views-faq .post::before {
    display: none;
  }
  .search_contact_form input[type="submit"] {
    font-size: 16px;
  }
}

/* blog
**************************************** */
.posts-blog .post {
  margin-bottom: 24px;
}
.posts-blog .post--img {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  margin-bottom: 16px;
  background-color: var(--clr-wht);
  overflow: hidden;
}
.posts-blog .post--img img {
  transition: all 0.4s ease;
}
.posts-blog .post:hover .post--img img {
  transform: scale(1.05);
}
.posts-blog .cat_list li:first-child a {
  margin-left: 0;
}
/* 右下固定CTAボタン */
.cta_fixed {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 180px;
  height: 180px;
  transition: all 0.3s ease;
  opacity: 0;
  pointer-events: none;
}
.cta_fixed .ttl {
  padding-top: 4px;
}
.cta_fixed svg {
  fill: var(--clr-wht);
}
.cta_fixed.active {
  opacity: 1;
  pointer-events: auto;
}
.cta_fixed > a {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  gap: 4px;
  background-color: var(--clr-secondary-default);
  border-radius: 50%;
  font-family: var(--font-jp-b);
  font-weight: 500;
}
.cta_fixed > a:hover {
  opacity: 0.7;
}
.cta_fixed.active {
  animation: bounce-cta 2s infinite cubic-bezier(0.77, 0, 0.18, 1);
}
.cta_fixed > a .ttl {
  line-height: 1.5;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .cta_fixed {
    display: none;
    bottom: 8px;
    right: 8px;
    width: 104px;
    height: 104px;
  }
  .cta_fixed > a {
    gap: 6px;
    width: 90px;
    height: 90px;
  }
  .cta_fixed > a .ttl {
    font-size: 10px;
  }
}

/* --- blog_slide --- */
.blog_slide .slide {
  position: relative;
  aspect-ratio: 1/0.553;
}
.blog_slide .typesquare_option.slick-dots {
  display: flex;
  justify-content: center;
  padding-block: 16px;
}
.blog_slide .slick-arrow {
  position: absolute;
  z-index: 1;
  top: 40%;
  width: 50px;
  padding: 18px;
  aspect-ratio: 1/1;
  border: none;
  background-color: var(--clr-wht);
  fill: var(--clr-primary-light);
  font-size: 0;
  border-radius: 50%;
  box-shadow: 0 0 2px 1px #eee;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
.blog_slide .slick-arrow.slick-prev {
  left: 2%;
  transform: rotate(180deg);
}
.blog_slide .slick-arrow.slick-next {
  right: 2%;
}
.blog_slide .slick-arrow.slick-prev::before {
  content: "";
  color: var(--clr-wht);
}
.blog_slide .typesquare_option.slick-dots li {
  padding-right: 8px;
}
.blog_slide .typesquare_option.slick-dots button {
  padding: 0;
  width: 8px;
  transform: translateY(-14px);
  aspect-ratio: 1/1;
  background-color: #55b2eb82;
  border-radius: 50%;
  border: none;
  font-size: 0;
}
.blog_slide .typesquare_option.slick-dots li.slick-active button {
  background-color: #55b2eb;
}
.blog_slides .slick-slide {
  height: auto;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
}
.blog_slide .slick-arrow {
  top: calc((100% - 131px) / 2 + 71px - 25px);
}
.blog_slides {
  width: 100%;
  border-radius: 16px;
  border: 2px solid var(--clr-sub-04);
  background-color: var(--clr-bg-02);
}
.blog_slides h2 {
  padding-block: 16px;
  font-size: 30px;
  text-align: center;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  background: var(--clr-sub-04);
  color: var(--clr-wht);
}
.blog_slide {
  position: relative;
  padding-top: 40px;
}
/* .blog_slide #blog_slider {
  position: relative;
} */
.blog_slide_content {
  padding-inline: 10px;
  filter: brightness(1);
  transition: all 0.3s linear;
}
.blog_slide_content.lft01,
.blog_slide_content.rgt01 {
  filter: brightness(0.8);
  transition: all 0.3s linear;
}
.blog_slide .slider {
  position: relative;
  /* aspect-ratio: 16/9; */
}
.blog_slide .slick-list {
  width: 100% !important;
  height: 100% !important;
}
.blog_slide .slick-track {
  height: 100% !important;
  margin-inline: 0 !important;
}
.blog_slide .slick-track img {
  height: 100% !important;
  border: 1px solid #ddd9d5;
}
.blog_slides .caption {
  position: absolute;
  top: 20px;
  left: calc(12% + 10px);
  width: fit-content;
  padding-inline: 15px;
  background-color: var(--clr-blk);
  color: var(--clr-wht);
  letter-spacing: 0.01em;
  font-weight: bold;
  border-radius: 50px;
}
/* .blog_slide_content.slick-slide:not(:has(.slick-active)) {
  filter: brightness(0.7);
} */
.blog_slide--link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 71px;
  padding-top: 8px;
  letter-spacing: 0.01em;
  color: #6f6f6f;
  text-align: center;
}
.blog_slide--link a {
  padding-right: 8px;
  color: #6f6f6f;
  text-decoration: underline;
  font-size: 18px;
}
.blog_slides .btn-blog {
  padding-bottom: 40px;
  text-align: center;
}

.blog_slides .btn-blog a {
  margin-inline: auto;
  background-color: var(--clr-primary-default);
  border-radius: 8px;
}
@media screen and (max-width: 767px) {
  .blog_slide .slick-list {
    overflow: hidden;
  }
  .blog_slide .slick-arrow {
    top: calc((100% - 44px - 51px) / 2 + 44px - 22.5px);
    width: 45px;
    padding: 16px;
  }
  .blog_slide .slick-arrow.slick-prev {
    left: -4%;
  }
  .blog_slide .slick-arrow.slick-next {
    right: -4%;
  }

  .blog_slide .typesquare_option.slick-dots li {
    padding-right: 6px;
  }
  .blog_slide .typesquare_option.slick-dots button {
    padding: 0;
    width: 6px;
    transform: translateY(-14px);
    aspect-ratio: 1/1;
    background-color: #55b2eb82;
    border-radius: 50%;
    border: none;
    font-size: 0;
  }
  .blog_slide .typesquare_option.slick-dots {
    padding-bottom: 8px;
  }
  .blog_slides h2 {
    font-size: 16px;
  }
  .blog_slide {
    padding-top: 36px;
  }
  .blog_slide--link {
    padding-bottom: 8px;
    font-size: 12px;
    text-align: center;
    height: 44px;
  }
  .blog_slide--link a {
    font-size: 12px;
  }
  .blog_slides .btn-blog {
    font-size: 13px;
    margin-inline: 3%;
    padding-bottom: 16px;
  }
  .blog_slides .caption {
    top: 10px;
    left: 10px;
  }
}

/* --- interview_parts --- */
.interview_parts:not(#edit) {
  position: relative;
  padding: 2.5vw 2.9166vw; /* vw 48px 56px */
  margin-block: 40px;
  border-radius: 20px;
  font-family: "Outfit", var(--font-jp-b);
  line-height: 1.75;
  letter-spacing: 0.08em;
}
.interview_parts.anime {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}
.interview_parts.anime.active {
  opacity: 1;
  transform: translateY(0);
}
.single-blog .interview_parts::before {
  content: "";
  position: absolute;
  top: -12px;
  left: 15%;
  width: 24px;
  height: auto;
  aspect-ratio: 1/1;
  background-color: inherit;
  transform: rotate(45deg);
}
.interview_parts .interview_get:not(#edit) {
  line-height: 1.75;
  letter-spacing: 0.1em;
  margin-bottom: 16px;
}
.interview_parts .interview_parts_txt-lg {
  position: absolute;
  top: calc(-32px + -5px); /* 文字の高さの半分+ちょい出し分 */
  right: 3%;
  font-size: clamp(rem(55),vw(64),rem(64));
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
}
.interview_parts .interview_parts_content {
  display: grid;
  grid-template-columns: clamp(200px, 15.625vw, 260px) auto; /* vw 300px */
  grid-template-rows: 1.875vw repeat(4, auto) 1.875vw;
  grid-template-areas:
    "img ."
    "img cat"
    "img ttl"
    "img catch"
    "img interviewer"
    "img .";
  column-gap: 2.08333vw; /* vw 40px */
  align-items: center;
}
.interview_parts .interview_parts_img {
  grid-area: img;
  width: clamp(200px, 15.625vw, 260px); /* vw 300px */
  min-width: clamp(200px, 15.625vw, 260px);
  aspect-ratio: 1/1;
  border-radius: 50% 50% 50% 0.5rem;
  overflow: hidden;
}
.interview_parts .interview_parts_img {
  width: 100%;
}
.interview_parts .interview_parts_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
}
.interview_parts .interview_parts_txtbox {
  width: calc(100% - 200px);
  padding-left: 5%;
}
.interview_parts .cat_list {
  grid-area: cat;
}
.interview_parts .interview_get {
  grid-area: ttl;
  color: var(--clr-sub-02);
  font-size: 18px;
}
.interview_parts .interview_parts_catch:not(#edit) {
  display: flex;
  align-items: center;
  grid-area: catch;
  min-height: 96px;
  margin-bottom: 16px;
  font-size: clamp(rem(24), vw(32), rem(32));
  line-height: 1.5;
  letter-spacing: 0.05em;
}
.interview_parts .interviewer {
  grid-area: interviewer;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  color: var(--clr-text-secondary);
}
.interview_parts .interviewer p {
  display: flex;
  column-gap: 16px;
}
.interview_parts .interviewer .interviewer_info {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  margin-bottom: 0;
}
.interview_parts .interviewer .interviewer_info .flex {
  gap: 16px;
}
.interview_parts .interviewer_btn {
  margin-left: auto;
}
.interview_parts .interviewer_btn a {
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--clr-sub-02);
  font-size: 16px;
  text-decoration: none;
}
.interview_parts .interviewer_btn .icon-arrow {
  display: block;
  width: 16px;
  height: 16px;
  filter: brightness(1);
  transition: 0.5s all;
  fill: var(--clr-sub-02);
}
.interview_parts .interviewer_btn:hover .icon-arrow {
  transform: translateX(5px);
  filter: brightness(1.1);
}
.interview_parts .interviewer_btn a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
@media screen and (min-width: 767px) {
  /* --- TOP用調整 --- */
  .home .interview_parts:not(#edit) {
    z-index: 1;
    padding: 2.08333vw 3.333vw; /* vw 40px 64px */
    margin-bottom: 56px;
  }
  .home .interview_parts .interview_parts_content {
    grid-template-columns: clamp(260px, 20.8333vw, 358px) auto; /* vw 400px */
    grid-template-rows: 3.54166vw repeat(3, auto) 3.54166vw;
    grid-template-areas:
      "img ."
      "img ttl"
      "img catch"
      "img interviewer"
      "img .";
    column-gap: 4.1666vw; /* vw 80px */
  }
  .home .interview_parts .interview_parts_img {
    width: clamp(260px, 20.8333vw, 358px); /* vw 400px */
    min-width: clamp(260px, 20.8333vw, 358px);
  }
  .home .interview_parts .cat_list {
    display: none;
  }
  .home .interview_parts .interview_get {
    font-size: clamp(rem(20), vw(24), rem(24));
  }
  .home .interview_parts .interviewer_btn a {
    font-size: rem(18);
  }
}

@media screen and (min-width: 767px) {
  .interview_parts .interviewer_btn.pc-none {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .interview_parts:not(#edit) {
    padding: 8% 7%;
    border-radius: 10px;
  }
  .home .interview_parts:not(#edit) {
    margin-bottom: 16px;
  }
  .single-blog .interview_parts .interview_parts_txt-lg,
  .interview_parts .interview_parts_txt-lg {
    top: -12px;
    font-size: 24px;
  }
  .interview_parts {
    margin-block: 32px;
    padding: 40px 24px 24px;
  }
  .interview_parts .interview_parts_content {
    display: grid;
    align-items: center;
    grid-template-columns: 120px auto;
    grid-template-rows: auto;
    grid-template-areas:
      "cat cat"
      "ttl ttl"
      "catch catch"
      "img interviewer"
      "btn btn";
    column-gap: 16px;
    row-gap: 8px;
  }
  .interview_parts .interview_parts_img {
    min-width: 0;
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
  }
  .interview_parts .interview_parts_txtbox {
    width: 100%;
    padding: 0;
  }
  .interview_parts .cat_list {
    margin-bottom: 0;
  }
  .interview_parts .interview_get:not(#edit) {
    line-height: 1.75;
    font-size: 18px;
    grid-area: ttl;
    margin-bottom: 0;
  }
  .interview_parts .interview_parts_content .interview_parts_catch:not(#edit) {
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 1.75;
    grid-area: catch;
    margin-bottom: 0;
    min-height: inherit;
  }
  .interview_parts .interviewer p {
    column-gap: 8px;
  }
  .interview_parts .interviewer .interviewer_info {
    max-width: unset;
  }
  .interview_parts .interviewer .interviewer_info .interviewer-corporate {
    width: 100%;
  }
  .interview_parts .interviewer_btn {
    grid-area: btn;
  }
  .interview_parts .interviewer_btn.sp-none {
    display: none;
  }
  .interview_parts .interviewer_btn a {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 0;
    font-size: 13px;
    text-decoration: none;
  }
  .interview_parts .interviewer_btn .icon-arrow {
    transform: translateY(2px);
  }
  .interview_parts .interviewer_btn:hover .icon-arrow {
    transform: translate(3px 2px);
  }
  .interview_parts .interviewer_btn .icon-arrow svg{
    width: 14px;
    height: 14px;
  }
}
/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #111;
}
.news--archive li {
  margin-left: 8px;
  margin-right: 8px;
}
.news--archive a {
  color: var(--clr-wht);
}

/* --- news_list --- */
.news_list {
  border-top: 1px solid #dddddd;
}
.news_list .post {
  position: relative;
  padding: 46px 36px 46px;
  margin-bottom: 0;
  border-bottom: 1px solid #dddddd;
}
.news_list .post.no-link {
  padding: 46px 124px 46px 36px;
}
.news_list .post--info {
  margin-bottom: 12px;
}
.news_list .post--link svg {
  width: 16px;
  height: 16px;
  fill: var(--clr-wht);
}
.news_list .post--link:not([href="javascript:void(0);"]):hover {
  opacity: 0.5;
}
.news_list .post--ttl {
  font-size: 1.125rem;
  line-height: 1.77;
}
.news_list .icon-svg {
  position: absolute;
  top: 0;
  right: 36px;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--clr-mono-03);
}

@media screen and (max-width: 767px) {
  .news_list .post {
    display: block;
    padding: 24px 16px 24px;
  }
  .news_list .post.no-link {
    padding: 24px 72px 24px 16px;
  }
  .news_list .post--info {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .news_list .icon-svg {
    right: 16px;
    width: 40px;
    height: 40px;
  }
}

/* mailmagazine
**************************************** */
.posts-mailmagazine {
  border-top: 1px solid #ddd;
}
.posts-mailmagazine .post {
  padding: 40px 16px;
  border-bottom: 1px solid #ddd;
  transition: all 0.4s ease;
}
.posts-mailmagazine .post:hover {
  opacity: 0.5;
}
.posts-mailmagazine .post--ttl {
  font-size: 1.125rem;
}
.posts-mailmagazine {
  border-top: 1px solid #dddddd;
}
.posts-mailmagazine .post {
  position: relative;
  padding: 46px 36px 46px;
  margin-bottom: 0;
  border-bottom: 1px solid #dddddd;
}
.posts-mailmagazine .post {
  padding: 46px 124px 46px 36px;
}
.posts-mailmagazine .post--info {
  margin-bottom: 12px;
}
.posts-mailmagazine .post--link svg {
  width: 16px;
  height: 16px;
  fill: var(--clr-wht);
}
.posts-mailmagazine .post--ttl {
  font-size: 1.125rem;
  line-height: 1.77;
}
.posts-mailmagazine .icon-svg {
  position: absolute;
  top: 0;
  right: 36px;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--clr-mono-03);
}

@media screen and (max-width: 767px) {
  .posts-mailmagazine .post {
    display: block;
    padding: 24px 16px 24px;
  }
  .posts-mailmagazine .post {
    padding: 24px 72px 24px 16px;
  }
  .posts-mailmagazine .post--info {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .posts-mailmagazine .icon-svg {
    right: 16px;
    width: 40px;
    height: 40px;
  }
}

/* advice
**************************************** */
.archive-advice .main_column {
  margin-left: 0;
}
.posts-advice {
  @include mq(sp, min, ps) {
    gap: vw(80);
  }
}
.posts-advice .post {
  width: calc((100% - vw(80) * 2) / 3);
  margin-bottom: vw(80);
}
.posts-advice .post--img {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  margin-bottom: 24px;
  background-color: var(--clr-wht);
  overflow: hidden;
  box-shadow: 0px 0px 40px 0px rgba(69, 131, 175, 0.30);
}
.posts-advice .post--img img {
  transition: transform 0.4s ease;
}
.posts-advice .post--ttl a {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  font-family: var(--font-jp-b);
  font-size: 1.125rem;
}
.posts-advice .post--ttl .arrow {
  position: relative;
  display: block;
  margin-top: 2px;
  width: 22px;
  aspect-ratio: 1;
  border-radius: 50vw;
  background-color: currentColor;
}
.posts-advice .post--ttl .arrow::after {
  content: "";
  position: absolute;
  top: 50%;
  left: calc(50% + 1px);
  translate: -50% -50%;
  width: 16px;
  aspect-ratio: 1;
  background-image: url(../images/common/arrow-right.svg);
}
.posts-advice .post:hover .post--img img {
  transform: scale(1.05);
}
.posts-advice .cat_list li:first-child a {
  margin-left: 0;
}
.posts-advice .post--info {
  gap: 8px;
}
.posts-advice .post--status {
  display: inline-flex;
  font-size: 0.75rem;
  background-color: var(--clr-secondary-default);
  color: var(--clr-wht);
  padding: 2px 8px;
  white-space: nowrap;
  border-radius: 24px;
}
.posts-advice .post--status.closed {
  background: none;
  border: 1px solid var(--clr-blk);
  color: var(--clr-blk);
  opacity: 0.5;
}
@media screen and (max-width: 767px) {
  .posts-advice .post {
    width: 100%;
    margin-bottom: 24px;
  }
}

.page-contact .posts-advice .post {
  width: calc(100% / 2 - (40px * 1 / 2));
  margin-bottom: 40px;
}

/* ##############################################################################

    SINGLE

############################################################################## */

.single .main_column > article,
.single .breadcrumbs {
  max-width: 960px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.single .breadcrumbs {
  max-width: 1480px;
}
.post-type-faq .breadcrumbs {
  position: absolute;
  right: 5%;
}

@media screen and (max-width: 767px) {
  .post-type-faq .breadcrumbs {
    position: static;
  }
  .breadcrumbs {
    padding-bottom: 12px;
  }
}
.single-mailmagazine .mce-content-body th,
.single-mailmagazine .mce-content-body td {
  border: 0;
}

/* --- アイキャッチ --- */
.eyecatch {
  margin-bottom: 2rem;
}

/* --- ホームページ制作のご相談 --- */
.cta_btns {
  padding: 60px;
  background-color: var(--clr-bg-02);
  border-radius: 24px;
}
.cta_btns--ttl {
  position: relative;
  padding-bottom: 0.7em;
  margin-bottom: 0.7em;
  font-size: 2rem;
  text-align: center;
  font-family: var(--font-jp-b);
}
.cta_btns--ttl::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 32px;
  height: 4px;
  border-radius: 16px;
  background-color: var(--clr-sub-01);
}
.cta_btns--tel {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1;
}
.cta_btns--tel i {
  margin-right: 8px;
  font-size: 2rem;
}
.cta_btns--btn {
  font-size: 1.125rem;
}
.cta_btns--btn > span {
  position: relative;
  display: inline-block;
  margin-bottom: 8px;
  padding: 0 16px;
  font-size: 0.925rem;
}
.cta_btns--btn > span::before,
.cta_btns--btn > span::after {
  content: "";
  position: absolute;
  bottom: 4px;
  width: 1px;
  height: 70%;
  background-color: var(--clr-blk);
}
.cta_btns--btn > span::before {
  left: 0;
  transform: rotate(-25deg);
}
.cta_btns--btn > span::after {
  right: 0;
  transform: rotate(25deg);
}
.cta_btns--btn .cta_btns--tel i {
  margin-right: 8px;
}
.cta_btns .cta_btns--btn a {
  background-color: var(--clr-secondary-default);
}

/* --- 制作実績バナー --- */
.side_works_bnr_area {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.side_works_bnr_area {
  height: auto;
  border-radius: 16px;
  padding: rem(32) rem(24) 0;
  background-image: url(../images/common/side_works_bnr_area.jpg);
  background-color: var(--clr-blk);
}
.side_works_bnr_area .ttl {
  margin-bottom: 24px;
  color: #88cccc;
  font-size: 1.125rem;
}
.side_works_bnr_area.works .ttl {
  color: #1695c8;
}
.side_works_bnr_area .ttl {
  margin-bottom: 16px;
  text-align: center;
  font-size: 1rem;
}
.side_works_bnr_area .ttl .font-en {
  display: block;
  margin-bottom: 8px;
  line-height: 1;
  font-size: rem(36);
  font-weight: 400;
  text-transform: capitalize;
}
.side_works_bnr_area .txt p {
  font-size: 0.8125rem;
}
.side_works_bnr_area .trade_num_txt {
  text-align: center;
  color: var(--clr-wht);
}
.side_works_bnr_area .trade_num_txt span {
  padding: 4px;
  font-size: 150%;
  letter-spacing: normal
}
.side_works_bnr_area .btn a {
  position: static;
  display: inline-block;
  width: auto;
  max-width: none;
  padding: 0;
  border-radius: 4px;
  font-size: 0.9rem;
}
.side_works_bnr_area .btn a {
  width: 100%;
}
.side_works_bnr_area .btn a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.side_works_bnr_area .btn a > span {
  display: block;
  padding: 10px 40px 10px 24px;
}
.side_works_bnr_area .btn a > span {
  padding: 10px;
}
.side_works_bnr_area .btn .icon-arrow {
  display: none;
}
.side_works_bnr_area .imgarea {
  padding-block: 32px;
}
.side_works_bnr_area img {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .cta_btns {
    padding: 24px;
    border-radius: 16px;
  }
  .cta_btns--ttl {
    font-size: 1.5rem;
  }
  .cta_btns--tel {
    font-size: 2rem;
  }
  .cta_btns--tel i {
    font-size: 1.5rem;
  }
  .cta_btns a {
    justify-content: center;
  }
  .cta_btns--btn {
    font-size: 1rem;
  }
}

.post_img {
  position: relative;
  display: block;
  width: 100%;
  max-width: max-content;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.post_img::after {
  content: "";
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 24px;
  height: 24px;
  mix-blend-mode: exclusion;
  background-image: url("data:image/svg+xml;utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 160 160'%3E%3Cpath d='M158,148.64l-38.46-38.47a67.38,67.38,0,1,0-9.44,9.44L148.6,158a6.66,6.66,0,0,0,9.44-9.4Zm-90.61-27.2a54,54,0,1,1,54-54,54.08,54.08,0,0,1-54,54Z'/%3E%3C/svg%3E");
  transition: all 0.5s ease;
}
.post_img:hover::after {
  transform: scale(1.2);
}
@media screen and (max-width: 768px) {
  .post_img {
    width: 100%;
  }
}

/* pass
**************************************** */
#pass p {
  margin-bottom: 1em;
}
#pass input[name="post_password"] {
  padding: 5px;
  border: solid 1px #aaa;
  outline: none;
}
#pass input[type="submit"] {
  padding: 4px 16px;
  letter-spacing: 0.15em;
  background-color: #ddd;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.4s ease;
}
#pass input[type="submit"]:hover {
  opacity: 0.7;
}

/* interview
**************************************** */
.single-interview .main_column {
  margin-left: 11.97vw;
}
.interview--company_name {
  position: relative;
  margin-bottom: 16px;
  padding-left: 26px;
  font-size: 1.125rem;
}
.interview--company_name::before {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 5px solid var(--clr-sub-01);
}
.interview--catch {
  margin-bottom: 24px;
  font-size: 2rem;
  font-family: var(--font-jp-b);
}
.interview--info {
  padding: 54px;
  border-radius: 24px;
  background-color: var(--clr-wht);
}
.interview--info .info_logo {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.interview--info .info_table {
  width: 49%;
  font-size: 0.8375rem;
}
.interview--info .info_table th,
.interview--info .info_table td {
  padding: 4px 0;
}
.interview--info .info_table th {
  padding-right: 16px;
  color: #888;
  text-align: left;
  min-width: 6rem;
}
.interview--info .info_table td a {
  color: var(--clr-sub-01);
}
.interview--info .info_table td a svg {
  fill: var(--clr-sub-01);
  margin-left: 2px;
  transform: translateY(2px);
}
.make_site--cont {
  display: grid;
}

/* 1件の時 */
.make_sites.one .editor-ttl {
  position: absolute;
  left: calc(52% + 56px);
}
.make_sites.one .make_site--cont {
  gap: 56px;
  grid-template-columns: 52% 1fr;
}
.make_sites.one .make_site--txt {
  padding-top: 70px;
}
.make_sites.one .btn-en {
  margin-top: 24px;
}
.make_sites:not(.one) .make_site--cont {
  gap: 32px;
}
.make_site--img {
}
.make_site--txt .terms li:not(:last-child)::after {
  content: "、";
}
.make_sites .btn-en .arrow {
  width: 48px;
  height: 48px;
}

.interview--abstract {
  letter-spacing: 0.08em;
  line-height: 2.125;
}
.interview--content.mce-content-body h2 {
  padding: 0 0 0 2rem;
  background-color: transparent;
  font-size: 2rem;
  line-height: 1.5;
}
.interview--content.mce-content-body h2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  border-radius: 6px;
  background-color: var(--clr-sub-01);
}
.interview--content.mce-content-body h3:not(.post--ttl) {
  font-size: 1.175rem;
}
.interview--content.mce-content-body h3:not(.post--ttl)::before {
  width: 2.5rem;
  height: 1px;
}

@media screen and (max-width: 1400px) {
  .single-interview .main_column {
    margin-left: 6vw;
  }
}
@media screen and (max-width: 767px) {
  .single-interview .main_column {
    margin-left: 0;
  }
  .interview--catch {
    font-size: 1.5rem;
    line-height: 1.65;
  }
  .interview--info {
    padding: 24px;
    border-radius: 16px;
  }
  .interview--info .info_logo {
    width: 70%;
    margin: 0 auto 16px;
  }
  .interview--info .info_table {
    width: 100%;
  }
  .interview--info .info_table th {
    min-width: 80px;
  }
  .interview--info .info_table td {
    padding-left: 8px;
  }
  .make_sites.one .editor-ttl {
    position: relative;
    left: auto;
  }
  .make_sites.one .make_site--txt {
    padding-top: 0;
  }
  .make_sites.one .make_site--cont {
    grid-template-columns: 1fr;
    gap: 16px;
  }
  .make_sites:not(.one) .make_site--cont {
    gap: 16px;
  }
  .interview--content.mce-content-body h2 {
    padding-top: 16px;
    font-size: 1.5rem;
  }
  .interview--content.mce-content-body h2::before {
    top: 0;
    left: 0;
  }
}

/* blog
**************************************** */
#clone_bnr {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(249, 249, 249, 0.75);
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
#clone_bnr.show {
  opacity: 1;
  pointer-events: all;
  z-index: 9999;
}
.clone_bnr_wrap {
  display: inline-flex;
  transition: var(--transit-default);
}
#clone_bnr #clone_bnr_close {
  position: absolute;
  top: 40px;
  right: -24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--clr-wht);
  border: 2px solid var(--clr-blk);
  font-size: 2rem;
  line-height: 1;
  letter-spacing: 0;
  transition: all 0.4s ease-out;
  cursor: pointer;
}
#clone_bnr #clone_bnr_close:hover {
  opacity: 0.7;
}
#clone_bnr #clone_bnr_close::before,
#clone_bnr #clone_bnr_close::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: 18px;
  border-bottom: 3px solid var(--clr-blk);
  border-radius: 50vw;
}
#clone_bnr #clone_bnr_close::before {
  rotate: -30deg;
}
#clone_bnr #clone_bnr_close::after {
  rotate: 30deg;
}

.single-blog .main_column,
.single-note .main_column,
.single-pr .main_column {
  padding-left: 114px;
}
.blog--info {
  gap: 24px;
}
.blog--info .blog--info-left {
  flex: 1;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.blog--info .blog--info-tag {
  width: 100%;
}
.blog--info .blog--info-right {
  width: 25%;
  min-width: 200px;
  height: auto;
}
.blog--info .blog--info-right img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top;
}
.blog--info .cat_list {
  flex: 1;
}
.single--info .post--views {
  margin-left: auto;
  display: table;
  font-size: 0.72222rem;
  background-color: #9fa3a5;
  color: var(--clr-wht);
  line-height: 1;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 6px;
}

/* この記事を書いた人 */
.writer_box {
  position: relative;
  z-index: 1;
  padding: 4px 40px 24px;
  margin-inline: 32px;
  border: 2px solid #0d1e28;
}
.writer_box--ttl {
  width: 100%;
  transform: translateY(-20px);
}
.writer_box--ttl span:first-child {
  display: inline-block;
  padding: 6px 24px;
  border-radius: 40px;
  color: var(--clr-wht);
  background-color: #0d1e28;
  font-size: 0.875rem;
}
.writer_box .txtarea {
  flex: 1;
  margin-left: 16px;
}
.writer_box-sm {
  margin-left: 1.5rem;
}
.writer_box-sm .writer_img {
  width: 1.75rem;
  aspect-ratio: 1 / 1;
  margin-right: 0.75rem;
  border-radius: 50%;
  overflow: hidden;
}
.writer_box-sm .writer_img img {
  vertical-align: baseline;
}
.writer_box-sm .writer_name {
  font-size: 80%;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .writer_box {
    margin-inline: 0;
    margin-top: 32px;
    padding-inline: 16px;
    padding-bottom: 16px;
    flex-direction: column;
  }
  .writer_box .writer_box--ttl {
    width: 100%;
    justify-content: flex-end;
  }
  .writer_box--ttl span:first-child {
    padding: 6px 74px 6px 24px;
  }
  .writer_box--ttl span.img {
    position: absolute;
    top: 50%;
    right: 0;
    width: 60px;
    height: 60px;
    transform: translateY(-50%);
    border-radius: 50%;
    border: 2px solid #0d1e28;
    background-color: #0d1e28;
  }
  .writer_box--ttl span.img img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: var(--clr-wht);
  }
  .writer_box .txtarea {
    margin-left: 0;
  }
}

/* --- share --- */
.works_share,
.blog_share {
  position: absolute;
  top: 0;
  left: 0;
  letter-spacing: 0.06em;
}
.works_share--list li,
.blog_share--list li {
  transition: 0.5s all ease;
}
.works_share--list li:hover,
.blog_share--list li:hover {
  transform: scale(0.95);
}
.works_share--list li:not(:last-child),
.blog_share--list li:not(:last-child) {
  margin-bottom: 8px;
}
.works_share--list a,
.blog_share--list a,
.works_share--list .copy input,
.blog_share--list .copy input {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  color: var(--clr-wht);
}
.works_share--list svg,
.blog_share--list svg {
  fill: var(--clr-wht);
}
.works_share--list .tw,
.blog_share--list .tw {
  background-color: var(--clr-blk);
}
.works_share--list .fb,
.blog_share--list .fb {
  background-color: #3d599b;
  color: #3d599b;
}
.works_share--list .line,
.blog_share--list .line {
  background-color: #1cc121;
}
.works_share--list .pin {
  background-color: #e72424;
}
.blog_share--list .linkedin {
  background-color: #0077b5;
}
.blog_share--list .hatena {
  background-color: #3c68e4;
}
.works_share--list .copy,
.blog_share--list .copy {
  position: relative;
  color: var(--clr-wht);
}
.works_share--list .copy input,
.blog_share--list .copy input {
  background-color: var(--clr-mono-03);
  cursor: pointer;
}
.works_share--list .copy svg,
.blog_share--list .copy svg {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  fill: transparent;
  pointer-events: none;
}

@media screen and (max-width: 767px) {
  .single-blog .main_column,
  .single-note .main_column,
  .single-pr .main_column {
    padding-left: 0;
  }
  .blog--info {
    flex-direction: column;
  }
  .blog--info .writer_box-sm {
    margin-right: 0;
    margin-left: auto;
    margin-top: 0.25rem;
  }
  .blog--info .blog--info-left {
    gap: 16px;
  }
  .blog--info .blog--info-tag {
    align-items: flex-start;
  }
  .clone_bnr_wrap {
    width: 90%;
  }
  #clone_bnr #clone_bnr_close {
    top: -8px;
    right: 0;
  }
}

/* works
**************************************** */
.single-works .lps_sec {
  z-index: 3;
}
.single-works .main_column {
  padding-left: 5.76vw;
}
.works_info--ttl {
  margin-bottom: 32px;
}
.page_image--eyecatch {
}
.page_image a:hover {
  color: var(--clr-sub-01);
}
.page_image a svg {
  fill: var(--clr-sub-01);
  margin-left: 4px;
  transform: translateY(1px) rotate(-45deg);
}
.works_info--ttl {
  position: relative;
  padding-left: 24px;
  font-size: 1.125rem;
  font-family: var(--font-jp-b);
}
.works_info--ttl::before {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 5px solid var(--clr-sub-01);
}
.works_info--box {
  width: 90%;
  margin: 0 auto 72px;
  padding: 40px 60px;
  border-radius: 24px;
}
.works_info--table th,
.works_info--table td {
  padding: 8px 0;
}
.works_info--table th {
  width: 128px;
  padding-right: 16px;
  font-family: var(--font-en);
  text-align: left;
  letter-spacing: 0.06em;
  font-size: 0.875rem;
  word-break: keep-all;
}
.works_info--table td a {
  color: var(--clr-sub-01);
}
.works_info--table td a svg {
  transform: rotate(-45deg);
  fill: var(--clr-sub-01);
}
.works_info--table td ul li:not(:last-child) {
  margin-right: 4px;
  margin-bottom: 4px;
}
.works_info--table td ul li a {
  display: inline-block;
  background-color: var(--clr-bg-02);
  padding: 8px 16px;
  border-radius: 4px;
  line-height: 1;
  color: var(--clr-blk);
}
.works_info--table td ul li a:hover {
  opacity: 0.7;
}
.single-works .sub_ttl {
  margin-bottom: 32px;
  font-size: 0.875rem;
  font-family: var(--font-en), sans-serif;
}

/* --- awwwards --- */
.works_awwwards {
  display: flex;
  align-items: center;
  background-color: var(--clr-sub-05);
  color: var(--clr-wht);
  background-image: url(/wp/wp-content/themes/leapy22/images/works/img-works-award.svg);
  background-repeat: no-repeat;
  background-size:  auto 118%;
  background-position: left 3.541% bottom 140%;
  transition: var(--transit-default);
  @include mq(sp, min, ps) {
    min-height: 200px;
    padding-left: 41.66%;
  }
  @include mq(sp) {
    background-size: sprem(88) auto;
    background-position: right sprem(16) top sprem(8);
  }
  &:hover {
    opacity: 0.7;
  }
}
.works_awwwards .txtarea {
  padding-block: rem(24);
  @include mq(sp) {
    padding: sprem(24) sprem(16);
    width: 100%;
  }
}
.works_awwwards--logo {
  @include mq(sp) {
    width: sprem(180);
  }
}
.works_awwwards--ttl {
  font-size: rem(24);
  font-weight: 600;
  line-height: 1;
  @include mq(sp) {
    font-size: sprem(18);
  }
}
.works_awwwards--txt {
  font-size: rem(14);
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.05em;
}
.works_awwwards--ico {
  position: absolute;
  right: rem(8);
  bottom: rem(8);
  rotate: -45deg;
  fill: var(--clr-wht);
}

/* お客様インタビュー */
.work--interview .interview_bloc {
  overflow: hidden;
  border-radius: 24px;
  background-color: var(--clr-wht);
}
.work--interview .img {
  width: 34%;
  height: 240px;
}
.work--interview .txt {
  flex: 1;
  padding: 40px;
}
.work--interview .link {
  margin-top: -1em;
  text-align: right;
}
.work--interview .link a {
  display: inline-flex;
  align-items: center;
}
.work--interview .link .icon-svg {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--clr-secondary-default);
  margin-left: 16px;
  transition: all 0.4s ease;
}
.work--interview .link a:hover .icon-svg {
  background-color: var(--clr-sub-01);
}

/* コンセプトシート */
.work_concept_sheet {
  background-color: var(--clr-wht);
}
#concept_sheet_slide .slick-track {
  display: flex;
}
#concept_sheet_slide .slick-track,
#concept_sheet_slide .slick-slide {
  width: 100% !important;
}
#concept_sheet_slide .slick-slide {
  flex: 1 0 100%;
}
#concept_sheet_slide {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
}
#concept_sheet_slide .slick-slide img {
  width: 100%;
}
#concept_sheet_nav {
  display: flex;
  height: 68px;
}
#concept_sheet_nav .slick-list {
  overflow: visible;
}
#concept_sheet_nav .slick-slide {
  cursor: pointer;
  width: 120px !important;
  height: 68px;
}
#concept_sheet_nav .slick-slide:not(:last-child) {
  margin-right: 15px;
}

/* capture */
.works_capture img {
}
.works_capture .design_capture svg {
  position: absolute;
  right: 16px;
  bottom: 16px;
  fill: var(--clr-wht);
  mix-blend-mode: exclusion;
}
.single-works .capture--cont::before {
  top: 47px;
  right: 17px;
  left: 3px;
  bottom: 3px;
}
.single-works .capture--cont-pc .capture--cont {
  margin-right: 36px;
  width: 50%;
}
.single-works .capture--cont-pc .capture--cont.capture--pc_full {
  width: 100%;
}
.capture--cont--img {
  position: relative;
  overflow-y: scroll;
  max-height: 720px;
}
.capture--cont--img::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.capture--cont--img::-webkit-scrollbar-track {
  background-color: #dfdfdf;
}
.capture--cont--img::-webkit-scrollbar-thumb {
  background-color: var(--clr-blk);
}
.capture--cont svg {
  position: absolute;
  right: 16px;
  bottom: 16px;
  mix-blend-mode: exclusion;
  fill: var(--clr-wht);
}

/* 印刷物用スタイル */
.capture_print .capture--cont:last-of-type {
  margin-right: 0;
}
.capture_print .capture--cont:only-child {
  width: 55%;
}
.single-works .capture_print .capture--cont::before {
  top: 3px;
  right: 3px;
}
.capture_print .capture--cont--img {
  max-height: none;
  overflow-y: visible;
  text-align: center;
}

.relation_works--tab {
  padding: 8px;
  background-color: var(--clr-blk);
  border-radius: 40px;
  margin-bottom: 54px;
}
.relation_works--tab li {
  color: var(--clr-wht);
  cursor: pointer;
  transition: all 0.6s ease;
}
.relation_works--tab li:hover {
  color: var(--clr-sub-01);
}
.relation_works--tab li > span {
  display: inline-block;
  width: 185px;
  padding: 4px;
  text-align: center;
  border-radius: 40px;
}
.relation_works--tab li.current > span {
  background-color: var(--clr-wht);
  color: var(--clr-blk);
}
.relation_works--contents {
  padding: 48px 0 24px;
  margin-bottom: 60px;
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
}
.relation_works--content {
  display: none;
}
.relation_works--content.current {
  display: block;
  animation: slideup 0.5s forwards;
}
.relation_works--content > h3 {
  margin-bottom: 54px;
}
.relation_works--content > h3 .ttl {
  position: relative;
  display: inline-block;
  padding-left: 28px;
  font-size: 1.5rem;
  font-family: var(--font-jp-b);
}
.relation_works--content > h3 .ttl::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 5px solid var(--clr-sub-01);
}

/* -- パンくず -- */
.works_breadcrumbs {
  margin-top: 60px;
  height: 1.75rem;
  overflow: clip;
}
.works_breadcrumbs.open {
  height: auto;
}
.works_breadcrumbs .inner {
  padding-right: 3rem;
}
.works_breadcrumbs--toggle {
  position: absolute;
  right: 0;
  top: 0;
  display: grid;
  place-items: center;
  width: 1.75rem;
  height: 1.75rem;
  background-color: #88cccc;
  border-radius: 50%;
}
.works_breadcrumbs--toggle::before,
.works_breadcrumbs--toggle::after {
  content: "";
  position: absolute;
  display: block;
  width: 10px;
  height: 2px;
  background-color: var(--clr-wht);
}
.works_breadcrumbs--toggle::before {
  rotate: 90deg;
}
.works_breadcrumbs--toggle.open::before {
  display: none;
}
.works_breadcrumb {
  margin-left: 4px;
  margin-right: 4px;
}
.works_breadcrumb:not(:last-child) {
  margin-bottom: 8px;
}
.works_breadcrumb--list {
  display: inline-flex;
  flex-wrap: wrap;
  background-color: var(--clr-bg-02);
  padding: 2px 24px;
  border-radius: 80px;
  height: 1.75rem;
}
.works_breadcrumb--list li:not(:last-child)::after {
  content: ">";
  display: inline-block;
  margin: 0 8px;
}
.works_breadcrumb--list a {
  text-decoration: underline;
}
.works_breadcrumb--list a:hover {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .single-works .main_column {
    padding-left: 0;
  }
  .single-works .main_column hr {
    margin-bottom: 2.5em 0;
  }
  .single-works .sub_ttl {
    margin-bottom: 20px;
  }
  .works_info--ttl {
    margin-bottom: 24px;
  }
  .works_info--box {
    width: 100%;
    margin: 0 auto 40px;
    padding: 24px;
    border-radius: 16px;
  }
  .works_info--table th {
    width: 4rem;
  }
  .works_info--table td {
    padding-left: 8px;
    font-size: 0.875rem;
  }
  .works_info--table td ul li {
    margin-bottom: 5px;
  }
  .works_info--table td ul li a {
    padding: 6px 8px;
  }
  #concept_sheet_slide {
    width: 100%;
    margin-bottom: 24px;
  }
  #concept_sheet_nav {
    height: auto;
  }
  #concept_sheet_nav .slick-list {
    padding: 0 !important;
  }
  #concept_sheet_nav .slick-track {
    display: flex;
    flex-wrap: wrap;
  }
  #concept_sheet_nav .slick-slide {
    width: calc(100% / 3 - (8px * 2 / 3)) !important;
    height: auto;
  }
  #concept_sheet_nav .slick-slide:not(:last-child) {
    margin-right: 8px;
  }
  .work--interview .interview_bloc {
    border-radius: 8px;
    padding: 16px;
    align-items: inherit;
  }
  .work--interview .img {
    width: 30%;
    height: auto;
    margin-right: 16px;
  }
  .work--interview .txt {
    padding: 0;
  }
  .work--interview .txt .editor-ttl2 {
    font-size: 1rem;
  }
  .works_single--capture_wrap .capture--cont {
    margin-bottom: 16px;
  }
  .single-works .capture--cont-pc .capture--cont {
    width: 100%;
    margin-right: 0;
  }
  .works_share,
  .blog_share {
    position: static;
    display: flex;
    align-items: center;
  }
  .works_share--ttl,
  .blog_share--ttl {
    margin-right: 16px;
    margin-bottom: 0;
  }
  .works_share--list,
  .blog_share--list {
    display: flex;
  }
  .works_share--list li:not(:last-child),
  .blog_share--list li:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 0;
  }

  .relation_works--tab {
    width: 100%;
  }
  .relation_works--tab li {
    width: calc(100% / 3);
  }
  .relation_works--tab li span {
    width: 100%;
    padding: 4px 15px;
  }
  .relation_works--content > h3 {
    margin-bottom: 32px;
  }
  .relation_works--content > h3 .ttl {
    margin-bottom: 16px;
  }
  .relation_works--content > h3 .ttl + .btn-en a {
    background-color: #ddd;
    padding: 8px 15px;
    border-radius: 24px;
    font-size: 1rem;
  }
  .relation_works--content > h3 .ttl + .btn-en a::after {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-right: 1px solid;
    border-bottom: 1px solid;
    transform: rotate(-45deg);
    vertical-align: middle;
    margin-left: 6px;
  }
  .relation_works--content .btn-en .arrow {
    display: none;
  }

  .works_breadcrumbs,
  .works_breadcrumb--list {
    height: auto;
  }
  .works_breadcrumbs .inner {
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    width: 100vw;
    max-width: initial;
    margin-inline: calc(50% - 50vw);
    overflow: auto;
    padding-inline: 7%;
  }
  .works_breadcrumb--list {
    flex-wrap: nowrap;
  }
  .works_breadcrumbs--toggle {
    display: none;
  }
}

/* e-book
**************************************** */
/* .single-e-book {
  overflow-x: hidden;
} */

.single-e-book .header {
  position: absolute;
  height: 60px;
}
.single-e-book .header > .inner {
  justify-content: flex-start;
}
.single-e-book .header--logo {
  position: relative;
  left: auto;
  padding-top: 0;
  width: 110px;
  top: 12px;
}
.single-e-book .header--logo a {
  background: none;
}
.single-e-book .e-book_flex {
  width: 40.27vw;
}
.single-e-book .e-book_flex .caution {
  padding-top: 24px;
}
.single-e-book .e-book_flex .caution a {
  color: var(--clr-primary-default);
}
.single-e-book .e-book_flex a {
  text-decoration: underline;
}
.single-e-book_content {
  gap: 24px;
}
.single-e-book_editor {
  flex: 1;
}
.single-e-book_content .single-e-book_img {
  width: 230px;
}

.download_cta {
  padding: 40px;
  border-radius: 24px;
  background-color: #fbf9f7;
}
.download_cta a {
  margin-right: 8px;
  font-size: 2rem;
  font-weight: 600;
  text-decoration: none !important;
  color: #333 !important;
}
.download_cta a i {
  font-size: 1.6rem;
  display: inline-block;
  transform: rotate(8deg);
  margin-right: 4px;
}
.single-e-book #breadcrumbs > span > span > span > a {
  display: none;
}
.download_book {
  position: relative;
  z-index: 99;
  width: 100%;
  height: 100%;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
.download_book_pickup {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3vw;
  column-gap: 5vw;
  background-color: #efefefc0;
}
.download_book_pickup .book_thumbnail {
  overflow: hidden;
  width: 35.3%;
  height: auto;
}
.download_book_pickup .flex {
  flex-direction: column;
  max-width: 45%;
}
.download_book_pickup .book_terms {
  font-size: 14px;
  padding-bottom: 8px;
}
.download_book_pickup .book_terms a {
  color: #7a7a7a;
}
.download_book_pickup .book_title {
  padding-bottom: 48px;
}
.book_title h2 {
  display: inline;
  padding-bottom: 0;
  background-image: linear-gradient(90deg, #000426, #000426);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 0% 1px;
  transition: background-size 0.6s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.download_book_pickup .book_download_btn a {
  min-width: 350px;
  margin-left: 0;
}
.book_download_btn {
  font-size: 16px;
}
.book_download_btn.btn span.pos_rel {
  display: flex;
  justify-content: center;
  align-items: center;
}
.book_download_btn.btn .icon-svg {
  position: unset;
  transform: translateY(0);
  padding-left: 5%;
}
.download_book_posts .book_terms a {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.825rem;
  background-color: var(--clr-bg-02);
  padding: 0 12px;
  white-space: nowrap;
  border-radius: 24px;
  margin: 2px;
}
.book_title {
  font-size: 100%;
}
.book_download_btn {
  text-align: center;
}
.grid-item .book_download_btn a {
  width: 85%;
  margin: 0 auto;
}
.download_book_posts {
  padding-top: 50px;
}
.download_book_posts .book_thumbnail {
  width: 100%;
  height: auto;
  aspect-ratio: 413/276;
  background-color: #1695c8;
  overflow: hidden;
}
.download_book_posts .book_title {
  padding-inline: 3%;
}
.download_book_posts .book_terms {
  font-size: 70%;
  padding-inline: 3%;
}
.book_flex {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px 20px;
  padding-bottom: 80px;
}
.grid-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}
.cover_link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.grid-item:hover .book_thumbnail img,
.download_book_pickup:hover .book_thumbnail img {
  transform: scale(1.1);
  transition: 0.6s all ease-in-out;
}
.grid-item:hover .book_title h2,
.download_book_pickup:hover .book_title h2 {
  background-position: 0% 100%;
  background-size: 100% 1px;
}
.cover_link + .book_thumbnail img {
  transition: transform 1s ease;
}
@media screen and (max-width: 768px) {
  .single-e-book .e-book_flex {
    width: 100%;
  }
  .single-e-book_content .single-e-book_img {
    display: none;
  }
  .single-e-book #formy_btn > p {
    width: 100%;
  }
  .download_book_pickup {
    flex-direction: column;
    padding: 10vw 3vw;
    margin-left: -5%;
    gap: 10px;
    width: 110%;
    background-color: #efefefc0;
  }
  .download_book_pickup .flex {
    width: 100%;
    max-width: 100%;
  }
  .download_book_pickup .book_download_btn {
    width: 90%;
    margin-inline: auto;
  }
  .download_book_pickup .book_thumbnail {
    width: 100%;
  }
  .download_book_pickup .book_terms,
  .download_book_pickup .book_title {
    padding-left: 3%;
  }
  .download_book_pickup .book_title {
    padding-bottom: 24px;
  }
  .download_book_pickup .book_download_btn a {
    min-width: auto;
  }
  .book_download_btn {
    font-size: 80%;
  }
  .book_title {
    font-size: 90%;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 80px 20px;
  }
}
/* ターム一覧 */
.term_slct {
  position: relative;
}
#anchor-term_slct {
  position: absolute;
  top: -100px;
}
.term_slct ul {
  padding-top: 64px;
  display: flex;
  padding-inline: 32px;
  border-bottom: 1px solid #ccc;
}
.term_slct li {
  position: relative;
  padding: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  transition: all 0.5s;
}
.term_slct li:hover {
  opacity: 0.6;
}
.term_slct li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.term_slct li.this_term {
  border: 1px solid #ccc;
  border-bottom: 0;
}
@media screen and (max-width: 768px) {
  #anchor-term_slct {
    top: -50px;
  }
  .term_slct ul {
    position: relative;
    padding-top: 56px;
    padding-left: 0;
    padding-right: 24px;
    overflow: scroll;
  }
  .term_slct li {
    padding: 8px;
    font-size: 14px;
    white-space: nowrap;
  }
}

/* advice
**************************************** */
.single-advice header {
  position: unset;
  height: 60px;
}
.single-advice header .inner {
  justify-content: flex-start;
}
.single-advice .header--logo {
  position: relative;
  left: auto;
  padding-top: 0;
  width: 110px;
}
.single-advice .advice_flex {
  width: 40.27vw;
}
.single-advice .advice_flex .caution {
  padding-top: 24px;
}
.single-advice .advice_flex .caution a {
  color: var(--clr-primary-default);
}
.single-advice .advice_flex a {
  text-decoration: underline;
}
.single-advice_content {
  gap: 24px;
}
.single-advice_editor {
  flex: 1;
}
.single-advice_content .single-advice_img {
  width: 230px;
}
@media screen and (max-width: 768px) {
  .single-advice .advice_flex {
    width: 100%;
  }
  .single-advice #formy_btn > p {
    width: 100%;
  }
}

/* wp-pagenavi
**************************************** */
.wp-pagenavi {
  font-family: var(--font-en);
  font-size: 1.5rem;
  text-align: center;
}
.wp-pagenavi .pages {
  display: block;
}
.wp-pagenavi .page,
.wp-pagenavi .current {
  position: relative;
  display: inline-block;
  min-width: 24px;
  margin: 8px;
  text-align: center;
}
.wp-pagenavi .page::before,
.wp-pagenavi .current::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #777777;
  opacity: 0;
  transition: all 0.4s ease;
}
.wp-pagenavi .page:hover::before,
.wp-pagenavi .current::before {
  opacity: 1;
}
.wp-pagenavi .previouspostslink,
.wp-pagenavi .nextpostslink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: var(--clr-primary-default);
  font-size: 0;
}
.wp-pagenavi .previouspostslink {
  margin-right: 24px;
}
.wp-pagenavi .nextpostslink {
  margin-left: 24px;
}
.wp-pagenavi .previouspostslink:hover,
.wp-pagenavi .nextpostslink:hover {
  background-color: #3361a6;
}
.wp-pagenavi .previouspostslink::before,
.wp-pagenavi .nextpostslink::before {
  content: "";
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' fill='rgb(255, 255, 255)' viewBox='0 0 16 16'%3E%3Cpath d='M8.057,12.671 L13.453,7.295 C13.667,7.082 13.787,6.793 13.787,6.491 C13.787,6.189 13.667,5.900 13.453,5.688 L8.057,0.311 C7.616,-0.128 6.905,-0.125 6.469,0.320 C6.034,0.763 6.037,1.478 6.478,1.917 L9.934,5.361 L1.335,5.361 C0.716,5.361 0.213,5.866 0.213,6.491 C0.213,7.115 0.716,7.621 1.335,7.621 L9.934,7.621 L6.478,11.064 C6.037,11.504 6.034,12.219 6.469,12.663 C6.905,13.108 7.616,13.111 8.057,12.671 Z'/%3E%3C/svg%3E");
}
.wp-pagenavi .previouspostslink::before {
  transform: rotate(-180deg) translateY(1px);
}
.wp-pagenavi .nextpostslink::before {
  transform: scaleY(-1) translate(2px, 1px);
}

@media screen and (max-width: 768px) {
  .wp-pagenavi {
    font-size: 1rem;
  }
  .wp-pagenavi .page,
  .wp-pagenavi .current {
    width: 16px;
  }
  .wp-pagenavi .previouspostslink,
  .wp-pagenavi .nextpostslink {
    width: 40px;
    height: 40px;
  }
  .wp-pagenavi .previouspostslink::before,
  .wp-pagenavi .nextpostslink::before {
    width: 14px;
    height: 14px;
  }
}

/* wp-pagenavi-single
**************************************** */
.wp-pagenavi-single {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}
.wp-pagenavi-single a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 24px;
  border-radius: 60px;
  background-color: var(--clr-mono-03);
  color: var(--clr-wht);
  font-family: "Outfit", var(--font-jp-b);
}
.wp-pagenavi-single a[rel="next"],
.wp-pagenavi-single a[rel="prev"] {
  padding: 12px;
}
.wp-pagenavi-single a[rel="next"] {
  margin-right: 16px;
}
.wp-pagenavi-single a[rel="prev"] {
  margin-left: 16px;
}
.wp-pagenavi-single a[rel="next"]::before,
.wp-pagenavi-single a[rel="prev"]::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' fill='rgb(255, 255, 255)' viewBox='0 0 16 16'%3E%3Cpath d='M8.057,12.671 L13.453,7.295 C13.667,7.082 13.787,6.793 13.787,6.491 C13.787,6.189 13.667,5.900 13.453,5.688 L8.057,0.311 C7.616,-0.128 6.905,-0.125 6.469,0.320 C6.034,0.763 6.037,1.478 6.478,1.917 L9.934,5.361 L1.335,5.361 C0.716,5.361 0.213,5.866 0.213,6.491 C0.213,7.115 0.716,7.621 1.335,7.621 L9.934,7.621 L6.478,11.064 C6.037,11.504 6.034,12.219 6.469,12.663 C6.905,13.108 7.616,13.111 8.057,12.671 Z'/%3E%3C/svg%3E");
}
.wp-pagenavi-single a[rel="next"]::before {
  transform: rotate(-180deg);
}

/* ##############################################################################

    SEARCH

############################################################################## */

#search-list .section_pdg {
  padding-top: 50px;
  padding-bottom: 50px;
}
#search-list .ttl-01 {
  margin: 70px 0 0;
}
.search_line_box {
  padding: 40px;
  border: 1px solid #ddd;
}


/* ##############################################################################

    FORM

############################################################################## */
.single-e-book .single-e-book_flex,
.single-advice .single-advice_flex {
  width: calc(100% - 40.27vw);
  padding-right: 3%;
}
.single-e-book .mce-content-body .single-e-book_title,
.single-advice .mce-content-body .single-advice_title {
  position: relative;
  font-size: 2rem;
  line-height: 1.5;
  letter-spacing: 0.04em;
  font-family: "Outfit", var(--font-jp-b);
  font-weight: 500;
}
.single-e-book .form,
.single-advice .form {
  width: 100%;
  height: fit-content;
  padding: 32px 40px;
  background-color: var(--clr-bg-02);
  border-radius: 24px;
}
.single-e-book .form table,
.single-e-book .form #crmWebToEntityForm,
.single-advice .form table,
.single-advice .form #crmWebToEntityForm,
.blog_form table {
  width: 100% !important;
  background-color: transparent !important;
  color: #1d2737 !important;
}
.single-e-book #formy_form > p,
.single-advice #formy_form > p {
  padding-bottom: 16px;
  text-align: center;
}
.single-e-book #formy_form td,
.single-advice #formy_form td,
.blog_form table td {
  display: block;
  border-bottom: none;
  line-height: 1.6em;
  width: 100% !important;
  color: #1d2737 !important;
  padding-bottom: 0;
}
.single-e-book #formy_form td span.requiredIcon,
.single-advice #formy_form td span.requiredIcon,
.blog_form table td span.requiredIcon {
  color: #e90606;
  background: none;
  font-size: 20px;
}
.single-e-book #formy_form td span.requiredIcon::before,
.single-advice #formy_form td span.requiredIcon::before,
.blog_form table td span.requiredIcon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--clr-wht);
  white-space: nowrap;
  font-size: 77%;
  letter-spacing: 0em;
}
/* .single-e-book #formy_form td:last-child,
.blog_form table td:last-child {
  padding-top: 2px;
  padding-bottom: 8px;
} */
.single-e-book #formy_form input[type="text"],
.single-e-book #formy_form input[type="email"],
.single-e-book #formy_form input[type="tel"],
.single-e-book #formy_form input[type="date"],
.single-e-book #formy_form input[type="password"],
.single-e-book #formy_form select,
.single-e-book #formy_form textarea,
.single-advice #formy_form input[type="text"],
.single-advice #formy_form input[type="email"],
.single-advice #formy_form input[type="tel"],
.single-advice #formy_form input[type="date"],
.single-advice #formy_form input[type="password"],
.single-advice #formy_form select,
.single-advice #formy_form textarea,
.blog_form input[type="text"],
.blog_form input[type="email"],
.blog_form input[type="tel"],
.blog_form input[type="date"],
.blog_form input[type="password"],
.blog_form select,
.blog_form textarea {
  width: 100% !important;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
}
/* .single-e-book #formy_form input[type="submit"],
.single-advice #formy_form input[type="submit"],
.blog_form input[type="submit"] {
  position: relative;
  background-color: transparent;
  color: #555;
  padding: 8px 27px;
  min-width: auto;
  font-size: 92%;
  border: 1px solid #aaa;
  border-radius: 32px;
} */
.single-e-book .ebook_form #formy_form input[type="submit"],
.single-advice .advice_form #formy_form input[type="submit"] {
  font-size: 123%;
  background: #aaa;
}
@media screen and (max-width: 1080px) {
  .single-e-book .ebook_form #formy_form input[type="submit"],
  .single-advice .advice_form #formy_form input[type="submit"] {
    letter-spacing: 0.05em;
  }
  .single-e-book_content .single-e-book_img,
  .single-advice_content .single-e-book_img {
    width: 150px;
  }
}
.single-e-book .ebook_form #formy_form input[type="submit"]:not(:disabled):hover,
.single-advice .advice_form #formy_form input[type="submit"]:not(:disabled):hover {
  background-color: var(--clr-secondary-dark2);
  opacity: 1;
}
.single-e-book #formy_form input[type="submit"]:not(:disabled):hover,
.single-advice #formy_form input[type="submit"]:not(:disabled):hover,
.blog_form input[type="submit"]:hover {
  background-color: var(--clr-secondary-dark2);
  opacity: 1;
}
.blog_form input[type="submit"] {
  margin-top: 8px;
}
.single-e-book #formy_form input[type="reset"],
.single-advice #formy_form input[type="reset"] {
  background: transparent;
  border: none;
  border-bottom: 2px solid;
  color: #1d2737 !important;
  opacity: 0.5;
}
.single-e-book .wpcf7 .help_text,
.single-advice .wpcf7 .help_text {
  margin-top: 5px;
}
.wpcf7-not-valid-tip {
  color: #e04242;
  font-size: 89% !important;
  margin-top: 4px;
}
div.wpcf7-response-output {
  // display: none !important;
}
div.wpcf7-response-output.wpcf7-mail-sent-ok {
  display: block !important;
  margin-top: 0;
  font-family: "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro",
    メイリオ, Meiryo, sans-serif;
  font-size: 89%;
  line-height: 1.7em;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border: 2px solid #62bd2f;
  color: #62bd2f;
  font-weight: bold;
}
#formy_form.form_1on1 .help_text {
  margin-top: 0;
}
.single-e-book .txt-attention-kome,
.single-advice .txt-attention-kome {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .single-e-book #formy_form input[type="reset"]:hover,
  .single-advice #formy_form input[type="reset"]:hover {
    opacity: 1;
  }
  .single-e-book .ebook_form #formy_form input[type="submit"],
  .single-advice .ebook_form #formy_form input[type="submit"] {
    font-size: 113%;
  }
}
@media screen and (max-width: 768px) {
  .single-e-book .mce-content-body .editor-ttl,
  .single-advice .mce-content-body .editor-ttl {
    margin-bottom: 32px;
  }
  .single-e-book .single-e-book_flex,
  .single-advice .single-advice_flex {
    width: 100%;
    padding-right: 0;
  }
  .single-e-book .mce-content-body table,
  .single-advice .mce-content-body table {
    min-width: 100%;
  }
  .single-e-book .mce-content-body .txtarea br,
  .single-advice .mce-content-body .txtarea br {
    display: block;
  }
  .single-e-book p.txt-sm,
  .single-e-book p.font-deco-en,
  .single-advice p.txt-sm,
  .single-advice p.font-deco-en {
    text-align: center;
  }
  .download_cta {
    padding: 24px;
    margin-bottom: 32px;
    border-radius: 16px;
  }
  .download_cta p.txt-lg + p {
    width: 100%;
    text-align: center !important;
    line-height: 1.5;
  }
  .download_cta a {
    display: block;
    margin-right: 0;
    line-height: 1.2;
  }
  .single-e-book .form,
  .single-advice .form {
    border: none;
    width: 100%;
    padding: 24px 16px;
  }
  .single-e-book #formy_form input[type="text"],
  .single-e-book #formy_form input[type="email"],
  .single-e-book #formy_form input[type="tel"],
  .single-e-book #formy_form input[type="date"],
  .single-e-book #formy_form input[type="password"],
  .single-e-book #formy_form select,
  .single-e-book #formy_form textarea,
  .single-advice #formy_form input[type="text"],
  .single-advice #formy_form input[type="email"],
  .single-advice #formy_form input[type="tel"],
  .single-advice #formy_form input[type="date"],
  .single-advice #formy_form input[type="password"],
  .single-advice #formy_form select,
  .single-advice #formy_form textarea,
  .blog_form input[type="text"],
  .blog_form input[type="email"],
  .blog_form input[type="tel"],
  .blog_form input[type="date"],
  .blog_form input[type="password"],
  .blog_form select,
  .blog_form textarea {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .single-e-book .txt-attention-kome,
  .single-advice .txt-attention-kome {
    font-size: 0.7rem;
  }
}


/* ##############################################################################

    CTA

############################################################################## */
/* common
********************************************** */
.cta_area {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 5.29vw 0;
  margin-inline: auto;
  background-color: var(--clr-sub-04);
  color: var(--clr-wht);
  border: 2px solid var(--clr-sub-04);
  border-radius: 5vw;
}
.cta_area .inner {
  position: relative;
  z-index: 1;
}

/* .cta_area--deco */
.cta_area--deco {
  position: absolute;
}
.cta_area--deco.triangle1 {
  width: 25vw;
  top: -15%;
  left: 7%;
  transform: rotate(70deg);
  translate: -100% 0;
  animation: 20s linear infinite rotation8;
}
.cta_area--deco.triangle2 {
  right: 26%;
  top: 20%;
  transform: rotate(12.94deg);
  translate: 100%;
  animation: 20s linear infinite rotation1;
  right: 29%;
  top: 0;
  width: 30vw;
  translate: 100%;
}
.cta_area--deco.circle {
  width: 105px;
  height: 105px;
  border-radius: 50%;
  background-color: var(--clr-sub-02);
  animation: 20s fuwafuwa infinite;
}
.cta_area--deco.circle1 {
  top: 20%;
  left: 60%;
}
.cta_area--deco.circle2 {
  left: 0;
  bottom: 15%;
}

/* cta_area--txtarea */
.cta_area--txtarea {
  max-width: 616px;
  width: 50%;
}
.cta_area--txtarea .btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  min-width: 380px;
  margin-left: 0;
  line-height: 1.75;
}
.cta_area--txtarea .btn .icon-svg {
  right: 20px;
}
.cta_area--txtarea .ttl-01 {
  padding: 0;
  margin: 0;
  margin-bottom: 32px;
  text-align: left;
}
.cta_area.works .cta_area--txtarea .ttl-01 .jp,
.cta_area.works .cta_area--txtarea .ttl-01 .en {
  color: #1695c8;
}
.cta_area--txtarea .ttl-01 .jp,
.cta_area--txtarea .ttl-01 .en {
  color: #88cccc;
  margin-bottom: 0;
}
.cta_area:not(.foot_cta) .cta_area--lead {
  font-size: 90%;
  line-height: 1.889;
}
.cta_area--txtarea .tel a {
  font-size: 250%;
  color: var(--clr-wht);
  letter-spacing: -0.02em;
}
.cta_area--txtarea .tel a svg {
  width: 30px;
  height: 30px;
  translate: 0 4px;
}
.cta_area .cta_area--catch {
  margin: 0;
  margin-bottom: 24px;
  font-size: 180%;
  text-align: left;
  line-height: 1.5;
  padding-left: 0;
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.cta_area .cta_area--catch::before {
  display: none;
}
.cta_area--btn li {
  margin-left: 0 !important;
  padding-left: 0 !important;
}
.cta_area--btn li::before {
  display: none;
}

/* .cta_area--imgarea */
.cta_area--imgarea {
  position: absolute;
  top: -20%;
  right: -5vw;
  display: flex;
  gap: 12%;
  width: 46%;
  height: 100%;
  @include mq( pc ) {
    width: 40%;
  }
}
.cta_area--slide {
  width: 50%;
  rotate: 30deg;
  transform-origin: right center;
}
.cta_area--slide1 {
  animation: loop_scroll-top 100s infinite linear 0.5s both;
}
.cta_area--slide2 {
  animation: loop_scroll-btm 100s infinite linear 0.5s both;
}
.cta_area--slide-item {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  margin-bottom: 8%;
}
.cta_area--slide-item img {
  height: 100% !important;
}
@media screen and (min-width: 768px) {
  .cta_area--btn {
    width: fit-content;
  }
  .cta_area:not(.foot_cta) .cta_area--lead {
    max-width: 75%;
  }
}
@media screen and (max-width: 767px) {
  .cta_area--txtarea,
  .cta_area--lead {
    width: 100%;
  }
  .cta_area {
    padding: 24px 0;
    margin-bottom: 0;
    margin-inline: calc(50% - 50vw);
    border-radius: 0 !important;
    border: none;
  }
  .cta_area--deco.circle {
    display: none;
  }
  .cta_area--deco.triangle2 {
    top: 4%;
    right: 16%;
    width: 40vw;
  }
  .cta_area--txtarea .ttl-01 {
    margin-bottom: 24px;
    text-align: center;
  }
  .cta_area--txtarea .ttl-01 .en {
    font-size: 9.66vw;
  }
  .cta_area--catch {
    max-width: 100%;
    margin-bottom: 24px;
    font-size: 6vw;
  }
  .cta_area p:where(:not(.tel)):where(:not(.btn)) {
    max-width: none;
    max-width: 100%;
    font-size: 94%;
  }
  .cta_area .tel {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  .cta_area .tel a {
    font-size: 8vw;
  }
  .cta_area--txtarea .btn a {
    min-width: auto;
  }
  .cta_area--imgarea {
    position: relative;
    top: 0;
    right: 0;
    height: auto;
    display: block;
    margin-top: 32px;
  }
  .cta_area--slide {
    display: flex;
    width: 100%;
    rotate: 0.00000001deg !important;
  }
  .cta_area--slide .cta_area--slide-item {
    width: 160px;
    margin-right: 16px;
    margin-bottom: 0;
  }
  .cta_area--slide1 {
    margin-right: 0;
    margin-bottom: 16px;
    animation: loop_scroll-top 20s infinite linear 0.5s both;
  }
  .cta_area--slide2 {
    animation: loop_scroll-btm 20s infinite linear 0.5s both;
  }
  .cta_area--slide > div {
    display: flex;
  }
}

/* foot_cta
********************************************** */
.cta_area.foot_cta {
  z-index: 2;
  margin-top: -12.5vw;
  padding-top: 120px;
  padding-bottom: calc(120px + 12.5vw);
  background-color: var(--clr-sub-04);
  color: var(--clr-wht);
  overflow: hidden;
  border-radius: 12.5vw 12.5vw 0 0;
  border: none;
}
.cta_area.foot_cta .cta_area--lead {
  width: 90%;
  color: #ccc;
}
.cta_area.foot_cta .cta_area--txtarea {
  max-width: 750px;
}
.cta_area.foot_cta .cta_area--txtarea .ttl-01 .en {
  margin: 0;
  margin-bottom: 0;
  color: #88cccc;
}
@media screen and (max-width: 1700px) {
  .foot_cta .cta_area--deco.triangle1,
  .foot_cta .cta_area--deco.triangle2,
  .foot_cta .cta_area--deco.triangle3 {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .cta_area.foot_cta {
    padding-top: 12.5vw;
    padding-bottom: calc(12.5vw + 40px);
    border-radius: 12.5vw 12.5vw 0 0 !important;
    overflow: hidden;
  }
  .cta_area.foot_cta .inner {
    position: relative;
    z-index: 1;
  }
  .cta_area.foot_cta .ttl-01 {
    margin-bottom: 20px;
    text-align: center;
  }
  .cta_area.foot_cta .flex .btn + .btn {
    margin-top: 16px;
    margin-left: 0;
  }
  .cta_area.foot_cta .tel small {
    display: block;
  }
  .cta_area.foot_cta--deco.triangle1 {
    top: 190px;
    left: 59%;
    width: 300px;
    height: 270px;
  }
  .cta_area.foot_cta--deco.circle {
    width: 58px;
    height: 58px;
  }
}

/* cta main_column（ショートコードなど）
********************************************** */
.main_column .cta_area {
  padding-block: 2.5vw;
  margin-block: 4vw;
  border-radius: 16px;
}
.main_column .cta_area--catch {
  max-width: 90%;
  font-size: 180%;
  letter-spacing: 0.03em;
}
.main_column .cta_area--lead {
  max-width: 85%;
  width: 100%;
  font-size: 80%;
}
.main_column .cta_area--imgarea {
  right: -15%;
}
.main_column .cta_area--deco.triangle2 {
  bottom: 45%;
  right: 35%;
  width: 25vw;
}
@media screen and (min-width: 768px) {
  .main_column .cta_area .ttl-01 .en {
    font-size: 3vw;
  }
}
@media screen and (max-width: 767px) {
  .main_column .cta_area {
    padding: 40px 0;
  }
  .main_column .cta_area--catch {
    font-size: 160%;
  }
  .main_column .cta_area--lead {
    max-width: unset;
  }
  .main_column .cta_area .cta_area--slide {
    width: auto;
  }
}

/* cta side_column
********************************************** */
.side_column .cta_area--imgarea {
  position: relative;
  top: 0;
  right: 0;
  height: auto;
  display: block;
  margin-top: 32px;
}

.side_column .cta_area--slide {
  display: flex;
  width: 100%;
  height: auto;
  rotate: 0.00000001deg !important;
}
.side_column .cta_area--slide > div {
  display: flex;
}
.side_column .cta_area--slide .cta_area--slide-item {
  width: 120px;
  aspect-ratio: 16 / 9;
  margin-right: 8px;
  margin-bottom: 0;
}
.side_column .cta_area--slide1 {
  margin-right: 0;
  margin-bottom: 8px;
  animation: loop_scroll-top-vertical 20s infinite linear 0.5s both;
}
.side_column .cta_area--slide2 {
  animation: loop_scroll-btm-vertical 20s infinite linear 0.5s both;
}


/* ##############################################################################

    FORMY

############################################################################## */
#formy_form {
  #formy_btn {
    width: fit-content;
    margin-inline: auto;
  }
}
#formy_btn p .wpcf7-spinner {
  @include mq(sp, min, ps) {
    position: absolute;
    top: 50%;
    right: rem(-8);
    margin: 0;
    translate: 100% -50%;
  }
}  
#formy_form table {
  width: 100%;
}
#formy_form th,
#formy_form td {
  padding: 16px;
  vertical-align: middle;
  border-bottom: solid 1px #eee;
  font-family: "Noto Sans JP", YuGothic, "Yu Gothic", "繝偵Λ繧ｮ繝手ｧ偵ざ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
}
#formy_form th {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
  width: 34%;
}
.form_wrap #formy_form th,
.form_wrap #formy_form td {
  width: 100%;
  vertical-align: middle;
  border-bottom: solid 1px #eee;
  font-family: "Noto Sans JP", YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
  display: block;
}
.form_wrap #formy_form th {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px 8px;
  font-weight: bold;
  white-space: nowrap;
  text-align: left;
}
.form_wrap #formy_form td {
  padding: 0 16px 4px;
  @include mq(sp) {
    padding-bottom: sprem(8);
  }
}
#formy_form table input[type="text"],
#formy_form table input[type="email"],
#formy_form table input[type="tel"],
#formy_form table input[type="date"],
#formy_form table input[type="password"],
#formy_form table textarea {
  width: 100%;
  border-radius: 3px;
  vertical-align: bottom;
}
#formy_form table input[type="text"],
#formy_form table input[type="email"],
#formy_form table input[type="tel"],
#formy_form table input[type="date"],
#formy_form table input[type="password"],
#formy_form select,
#formy_form textarea {
  margin: 0;
  padding: 6px 12px;
  border: 1px solid #ccc;
  font: inherit;
  font-size: 16px;
  font-family: "Noto Sans JP", YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
  border-radius: 4px;
}
#formy_form table input[readonly="readonly"] {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.5);
}
#formy_form textarea {
  height: 100px;
}
#formy_form select {
  height: 40px;
}
#formy_form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#formy_form input:hover {
  opacity: 0.7;
}
#formy_form textarea:hover {
  opacity: 0.7;
}
#formy_form input:focus {
  outline: none;
}
#formy_form .parsley-validated {
  background-color: #eee;
}
#formy_form .parsley-error {
  background-color: #fee;
}
#formy_form .parsley-success {
  background-color: var(--clr-wht);
}
.help_text {
  font-size: 0.875rem; /* 14px */
  color: var(--clr-text-secondary);
}
.hidden_help {
  display: none;
}
.formy_privacy div {
  overflow-y: scroll;
  height: 140px;
  border: solid 1px #ccc;
  font-size: 0.875rem; /* 14px */
  padding: 8px 16px;
}
.requiredIcon {
  background-color: #e90606;
  color: var(--clr-wht);
  margin: 0 0 0 1em;
  font-size: 0.75rem; /* 12px */
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 3px;
  display: inline-block;
  @include mq(sp) {
    display: inline-flex;
    align-items: center;
    height: fit-content;
    padding: 0.25em sprem(6) 0;
    line-height: 1.6;
  }
}
#formy_btn {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-top: 24px;
  text-align: center;
  order: 2;
}
.page-contact:not(.page-product-contact) #formy_btn,
.single-advice:not(.page-product-contact) #formy_btn,
.single-e-book:not(.page-product-contact) #formy_btn,
.page-interview-request #formy_btn,
.page-inquiry #formy_btn {
  @include mq(sp) {
    margin-top: sprem(24);
    padding-top: 0;
  }
}
.page-contact:not(.page-product-contact) #formy_btn input,
.single-advice:not(.page-product-contact) #formy_btn input,
.single-e-book:not(.page-product-contact) #formy_btn input,
.page-interview-request #formy_btn input,
.page-inquiry #formy_btn input {
  @include mq(sp) {
    margin-bottom: 0;
  }
}
.page-contact:not(.page-product-contact) #formy_btn .formy_btn--txt,
.single-advice:not(.page-product-contact) #formy_btn .formy_btn--txt,
.single-e-book:not(.page-product-contact) #formy_btn .formy_btn--txt,
.page-interview-request #formy_btn .formy_btn--txt,
.page-inquiry #formy_btn .formy_btn--txt {
  position: absolute;
  left: 50%;
  top: 51%;
  transform: translate(-50%, -50%);
  color: var(--clr-wht);
  font-size: 123%;
  z-index: 1;
  pointer-events: none;
  white-space: nowrap;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN",
    sans-serif;
  transition: all 0.4s ease;
}
#formy_btn input {
  min-width: 320px;
  border: none;
  cursor: pointer;
  color: var(--clr-wht);
  border-radius: 80px;
  padding: 24px 32px;
  margin-bottom: 24px;
  -webkit-appearance: none;
  appearance: none;
  letter-spacing: 0.1em;
  transition: all 0.4s ease;
  background-color: #aaa;
  color: transparent;
  @include mq(sp) {
    padding: 20px 24px;
  }
}
.wpcf7 #formy_btn input:not([disabled]),
#formy_btn input:not(.formy_submit_disabled) {
  background-color: #e02828;
}
#formy_form ul li input[type="radio"],
#formy_form ul li input[type="checkbox"],
.wpcf7-form input[type="checkbox"],
.wpcf7-form input[type="radio"] {
  display: none !important;
}
#formy_form ul li label,
.wpcf7-list-item label {
  position: relative;
  display: inline-block;
  padding: 0.25em 8px 0.25em 40px;
  line-height: 1.6;
  vertical-align: top;
  cursor: pointer;
}
#formy_form ul li label:hover,
.wpcf7-list-item label:hover {
  opacity: 0.7;
}
#formy_form ul li label::before,
.wpcf7-list-item label::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border: 2px solid #788b93;
  left: 16px;
  top: 8px;
}
#formy_form ul li input[type="radio"] + label::before,
.wpcf7-form .wpcf7-radio .wpcf7-list-item label::before {
  border-radius: 10px;
}
#formy_form ul li input[type="radio"]:checked + label,
#formy_form ul li input[type="checkbox"]:checked + label,
.wpcf7-form input[type="radio"]:checked + .wpcf7-list-item-label,
.wpcf7-form input[type="checkbox"]:checked + .wpcf7-list-item-label {
  color: #e90606;
  font-weight: bold;
}
#formy_form ul li input[type="radio"]:checked + label::before,
#formy_form ul li input[type="checkbox"]:checked + label::before,
.wpcf7-form input[type="radio"]:checked + .wpcf7-list-item-label::before,
.wpcf7-form input[type="checkbox"]:checked + .wpcf7-list-item-label::before {
  border-color: #e90606;
}
#formy_form ul li input[type="radio"]:checked + label::after,
#formy_form ul li input[type="checkbox"]:checked + label::after,
.wpcf7-form input[type="radio"]:checked + .wpcf7-list-item-label::after,
.wpcf7-form input[type="checkbox"]:checked + .wpcf7-list-item-label::after {
  content: "";
  width: 10px;
  height: 18px;
  top: 2px;
  left: 20px;
  border-right: 2px solid #e90606;
  border-bottom: 2px solid #e90606;
  display: block;
  position: absolute;
  z-index: 10;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.formy_send,
.formy_submit {
  background-color: var(--clr-secondary-default);
}
.formy_send:hover,
.formy_submit:not(.formy_submit_disabled):hover {
  background-color: var(--clr-secondary-dark2) !important;
}
.formy_confirm {
  background-color: #ff8633;
}
.formy_submit_disabled {
  background-color: #ccc;
}
#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}
#formy_btn input.autoConfirmBack {
  position: relative;
  background-color: transparent;
  color: #555;
  padding: 8px 27px;
  min-width: auto;
  font-size: 92%;
  border: 1px solid #aaa;
  border-radius: 32px;
}
#total_required {
  padding: 16px;
  background-color: #e90606;
  color: #FFF;
  text-align: center;
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 999;
  display: none;
}
#entry-form-id {
  display: flex;
  flex-direction: column;
}
#entry-form-id table {
  order: 1;
}
#entry-form-id .help_text {
  color: var(--clr-text-secondary);
}
#entry-form-id .formy_error {
  color: #e90606 !important;
}
@media screen and (max-width: 1280px) {
  #formy_form th,
  #formy_form td {
    display: block;
    width: auto;
    padding-left: 0;
    padding-right: 0;
  }
  #formy_form th {
    border-bottom: none;
    padding-bottom: 8px;
    white-space: normal;
    font-weight: bold;
  }
  #formy_form td {
    padding-top: 0;
  }
}
@media print, screen and (min-width: 768px) {
  #formy_form .confirm-message {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  #formy_form table {
    table-layout: fixed;
    word-break: break-all;
    word-wrap: break-all;
  }

  #formy_btn input {
    width: 100%;
    min-width: 100%;
    background-size: contain;
    background-position: center;
  }
  .autoConfirmBack {
    margin-bottom: 8px;
  }
  #formy_form select {
    width: 100%;
    font-size: 12px;
  }
  span.wpcf7-list-item-label {
    font-size: 13.5px;
  }
  .page-product-contact .wpcf7 .help_text {
    font-size: 12.5px;
  }
}

/* ##############################################################################

    iziModal カスタム

############################################################################## */

/* --- モーダルを開いた時に固定 --- */
html.iziModal-isOverflow body,
html.iziModal-isAttached body {
  overflow: visible;
}
.iziModal,
.iziModal .iziModal-header {
  box-shadow: none;
}
.iziModal .iziModal-content {
  text-align: center;
}
.iziModal .iziModal-header.iziModal-noSubtitle {
  padding-right: 0 !important;
}
.iziModal .iziModal-header.iziModal-noSubtitle .iziModal-header-title {
  font-family: "futura-pt", "A1 Gothic M", sans-serif;
  text-align: center;
}
.iziModal.hasShadow::after {
  content: none;
}

/* ##############################################################################

    目次

############################################################################## */
#toc_container {
  position: relative;
  padding: 40px 32px;
  background-color: var(--clr-bg-02);
  border-radius: 24px;
  margin: 2rem 0;
}
#toc_container .toc_title {
  position: relative;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-family: var(--font-jp-b);
  line-height: 1;
  cursor: pointer;
}
#toc_container .toc_title::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-mono-03);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: var(--clr-wht);
  line-height: 1;
}
#toc_container .toc_title::after {
  content: "+";
  position: absolute;
  bottom: 1px;
  right: -1px;
  display: inline-block;
  width: 48px;
  height: auto;
  text-align: center;
  line-height: 1;
  color: var(--clr-wht);
}
#toc_container .toc_title.close::after {
  content: "-";
  position: absolute;
  bottom: 1px;
  right: -1px;
  display: inline-block;
  width: 48px;
  height: auto;
  text-align: center;
  line-height: 1;
  color: var(--clr-wht);
}
.mce-content-body #toc_container ul.toc_list {
  display: none;
  margin-top: 16px;
}
.mce-content-body #toc_container ul.toc_list li {
  margin-left: 0;
}
.mce-content-body #toc_container ul.toc_list li::before {
  display: none;
}
.mce-content-body #toc_container ul.toc_list > li > a {
  font-family: var(--font-jp-b);
}
.mce-content-body #toc_container ul.toc_list li > ul {
  margin-top: 0;
  margin-left: 1em;
}
.mce-content-body #toc_container ul.toc_list li > ul li {
  font-size: 90%;
}
.mce-content-body #toc_container ul.toc_list li > ul li a {
  color: #3d3d3d;
}
.mce-content-body #toc_container ul.toc_list a {
  color: var(--clr-blk);
  text-decoration: none;
}

/*インタビュー*/
.single-interview .mce-content-body #toc_container ul.toc_list {
  display: block;
}
.single-interview .mce-content-body #toc_container ul.toc_list > li > ul {
  display: none;
}

@media screen and (max-width: 767px) {
  #toc_container {
    padding: 0 16px 24px;
    margin-top: 0;
    border-radius: 16px;
  }
  #toc_container .toc_title {
    padding-top: 24px;
    font-size: 1.125rem;
  }
  #toc_container .toc_title::before {
    top: 14px;
    display: inline-block;
    transform: translateY(0);
    width: 36px;
    height: 36px;
  }
  #toc_container .toc_title::after,
  #toc_container .toc_title.close::after {
    width: 36px;
    height: auto;
  }
  .hbg--nav_service li:not(:last-child) {
    margin-right: 13px;
  }
}

/* code装飾 */
.hljs-wrap {
  position: relative; /* 絶対配置の基準 */
}
.hljs-copy-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(201, 213, 245, 0.1);
  border: none;
  padding: 3px 10px;
  color: #999;
  cursor: pointer;
  transition: color .3s;
}
.hljs-copy-btn:hover {
  color: #eee
}

#wpcf7-f20784-o1 #formy_btn input,
.wpcf7 #formy_btn input[disabled],
#wpcf7-f20783-o1 #formy_btn input,
.wpcf7 #formy_btn input[disabled] {
  background-color: #aaa;
}
#wpcf7-f20784-o1 #formy_btn input:not([disabled]),
#wpcf7-f20783-o1 #formy_btn input:not([disabled]) {
  background-color: var(--clr-primary-default);
}
/* リキャプチャ */
.grecaptcha-badge {
  display: none;
}
.recaptcha_policy {
  padding: 0;
  margin-top: 2rem;
  color: #333;
  font-size: 0.625rem;
  text-align: center;
  letter-spacing: 0;
}
.recaptcha_policy a {
  color: #69abdb;
  font-size: 0.75rem;
  text-decoration: underline;
}

/* ##############################################################################

    Chatbot チャットボット

############################################################################## */
.chatbot_balloon {
  position: fixed;
  bottom: 12px;
  right: 78px;
  display: none;
  z-index: 100;
  background-color: var(--clr-wht);
  padding: 8px 34px;
  border-radius: 16px;
  font-size: 82%;
  line-height: 1.6;
  box-shadow: 0 0 16px 0 rgba(69, 131, 175, 0.3);
  cursor: pointer;
}
.chatbot_balloon--close {
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: var(--clr-mono-03);
  right: -12px;
  top: -12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#dify-chatbot-bubble-button {
  z-index: 150 !important;
}
@media screen and (max-width: 767px) {
  .chatbot_balloon {
    opacity: 0;
    display: none;
    bottom: 73px;
  }
  #dify-chatbot-bubble-button {
    opacity: 0;
    bottom: 76px !important;
  }
  #dify-chatbot-bubble-window {
    max-height: calc(-240px + 100vh) !important;
  }
  #dify-chatbot-bubble-button.active,
  .chatbot_balloon {
    transition: all .6s ease;
    transform: translateY(20px);
  }
  #dify-chatbot-bubble-button.active,
  .chatbot_balloon.active {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }
}
