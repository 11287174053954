@use "../abstracts" as *;

/* native-lazyload ブラウザ対応 */
_:-ms-lang(x)::-ms-backdrop,
.no-js .native-lazyload-js-fallback {
  display: block !important;
}
:lang(x) + :-webkit-full-screen-document,
.no-js .native-lazyload-js-fallback {
  display: block !important;
}

/* ##############################################################################

    Loops モジュール

############################################################################## */
.btn .icon-svg {
  position: absolute;
  top: 52%;
  display: inline-block;
  line-height: 1;
  transform: translateY(-50%);
  transition: all 0.4s ease;
}
.icon-arrow svg {
  width: 16px;
  height: 16px;
}
.btn .icon-arrow {
  right: 30px;
}
.btn .icon-arrow i {
  display: inline-block;
  height: 16px;
  line-height: 1;
  overflow: hidden;
}
.btn .icon-svg--pdf {
  display: flex;
  margin-left: 8px;
}
.btn .icon-svg--pdf svg {
  width: auto !important;
  height: 1.2rem !important;
}
@keyframes arrow {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  25% {
    transform: translateX(16px);
    opacity: 1;
  }
  50% {
    transform: translateX(-16px);
    opacity: 0;
  }
  75% {
    transform: translateX(-16px);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.btn a:hover .icon-arrow svg {
  animation: 0.4s linear arrow;
}
.icon-new_tab svg {
  width: 14px;
  height: 14px;
}
.btn .icon-new_tab {
  right: 30px;
}
.icon-pdf svg {
  width: 27px;
  height: 12px;
}
.btn .icon-pdf {
  left: 15px;
}
.lps_sec {
  position: relative;
}
.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}


.lps_sec:nth-child(even) *:not(.bg-wh) :is(.box, .list-2-column) {
  background-color: #F2F2F2;
}
.lps_sec:nth-child(even) .bg-wh :is(.box, .list-2-column) {
  background-color: #f9f9f9;
}
.lps_sec:nth-child(odd) *:not(.bg-wh) :is(.box, .list-2-column) {
  background-color: #fff;
  border: 1px solid #ddd;
}
.lps_sec:nth-child(odd) .bg-wh :is(.box, .list-2-column) {
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .btn .icon-new_tab {
    right: 24px;
  }
  .btn .icon-pdf {
    left: 12px;
  }
  .sec-cta_area {
    width: 100%;
    max-width: inherit;
  }
  .sec-cta_area .cta_area {
  border-radius: 0;
  }
}

/* lps_sec
********************************************** */
.anchor_toc {
  border-bottom: 1px solid #cedde2;
  text-align: center;
}
.anchor_toc--list {
  font-size: 0.9375rem;
}
.anchor_toc--list li {
  padding: 0 42px;
}
.anchor_toc--list li a {
  display: block;
  padding-top: 54px;
  padding-bottom: 54px;
}
.anchor_toc--list li a:hover {
  color: #80d4d4;
}

.lps_sec--deco {
  position: absolute;
}
.lps_sec--deco.triangle1 {
  top: 150px;
  left: -770px;
  transform: rotate(-45deg);
}
.lps_sec--deco.triangle2 {
  top: 40%;
  left: 12%;
  z-index: -1;
  transform: rotate(-45deg);
}
.lps_sec--deco.circle {
  top: 290px;
  left: 2%;
  width: 123px;
  height: 123px;
  border-radius: 50%;
  background-color: #4fbfec;
  mix-blend-mode: multiply;
}
.lps_sec:nth-of-type(even) .lps_sec--deco.triangle1 {
  top: 50%;
  left: auto;
  right: -880px;
  transform: rotate(135deg);
}
.lps_sec:nth-of-type(even) .lps_sec--deco.triangle2 {
  display: none;
}
.lps_sec:nth-of-type(even) .lps_sec--deco.circle {
  top: 75%;
  left: auto;
  right: 5%;
}

.fix_toc {
  position: fixed;
  top: 200px;
  left: 2.6vw;
  width: 224px;
  background-color: #fff;
  padding: 32px 16px 32px 38px;
  border-radius: 24px;
  box-shadow: 0 0 40px 0 rgba(69, 131, 175, 0.2);
  z-index: 99999;
  transform: translateX(-125%);
  transition: all 0.4s ease;
  opacity: 0;
  pointer-events: none;
}
.fix_toc.show {
  opacity: 1;
  pointer-events: all;
  transform: translateX(0);
}
.fix_toc--list li {
  line-height: 1;
}
.fix_toc--list a {
  position: relative;
  display: inline-block;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 0.925rem;
  line-height: 1.5;
}
.fix_toc--list a:hover {
  opacity: 0.5;
}
.fix_toc--list a::before {
  content: "";
  position: absolute;
  top: 13px;
  left: -15px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #80d4d4;
  transition: all 0.4s ease-out;
  opacity: 0;
}
.fix_toc--list a.current {
  color: #80d4d4;
}
.fix_toc--list a.current::before {
  opacity: 1;
}
.fix_toc--list + .btn {
  margin-top: 24px;
}

.fix_toc .side--cta {
  margin-top: 32px;
  border-radius: 16px !important;
}
.fix_toc .side--cta .cta_btns--tel {
  font-size: 1.25rem;
}
.fix_toc .side--cta .btn a {
  font-size: 0.88888rem;
}

@media screen and (max-width: 767px) {
  .fix_toc--deco {
    left: -46px;
    width: 46px;
    height: 170px;
  }
  .fix_toc--deco span {
    word-break: keep-all;
  }
  .fix_toc--deco i {
    width: 14px;
    height: 15px;
    margin-left: 8px;
    background-size: 6px 6px;
  }

  .lps_sec--deco {
    display: none;
  }
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
}
.lps_parts .img-detail::before {
  padding-top: 66.666%;
}
.lps_parts + .ttl-02,
.lps_parts--button + .ttl-02,
.lps_parts--button + .lps_parts,
.lps_parts + .lps_parts {
  margin-top: 80px;
}
.lps_parts--column + .lps_parts--column,
.lps_parts + .lps_parts--button {
  margin-top: 40px;
}
.lps_parts iframe {
  width: 100%;
}
.lps_parts .text:not(:last-child) {
  margin-bottom: 24px;
}
.lps_parts--column .btn,
.lps_parts--slide .btn {
  text-align: center;
}
.lps_parts--column .btn {
  margin-top: auto;
}
.lps_parts--column .btn a {
  margin-left: auto;
  margin-right: auto;
}
.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .lps_parts .btn {
    text-align: center;
  }
  .lps_parts + .ttl-02,
  .lps_parts--button + .ttl-02,
  .lps_parts--button + .lps_parts,
  .lps_parts + .lps_parts {
    margin-top: 40px;
  }
  .lps_parts--column + .lps_parts--column,
  .lps_parts + .lps_parts--button {
    margin-top: 16px;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
  .lps_parts .mce-content-body:not(:last-child) {
    margin-bottom: 16px;
  }
}

/* lps_parts--column
********************************************** */
.lps_parts--column:not(.column-1) + .lps_parts--column {
  margin-top: 24px;
}
.lps_parts--column .column_item.bg-wh {
  box-shadow: 0 0 40px 0 rgba(69, 131, 175, 0.2);
}
.lps_parts--column .text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.lps_parts--column .img + .text {
  padding-top: 24px;
}
.lps_parts--column .text.bg-wh {
  padding: 48px 56px;
  height: 100%;
}
.lps_parts--column.column-3 .text.bg-wh,
.lps_parts--column.column-4 .text.bg-wh {
  padding: 24px;
}
.lps_parts--column:not(.column-1) table {
  width: 100%;
}
.lps_parts--column.column-1 .box {
  padding: 40px;
}
.lps_parts--column.column-3 .btn a,
.lps_parts--column.column-4 .btn a {
  min-width: 100%;
}
.lps_parts--column.column-3 .mce-content-body,
.lps_parts--column.column-4 .mce-content-body {
  font-size: 0.888888rem; /*16px*/
  line-height: 2;
}

/* 角丸設定 *************************************/

/* 白背景 */
.lps_parts--column .column_item.bg-wh,
.lps_parts--column .column_item .text.bg-wh {
  border-radius: 16px;
}
.lps_parts--column .column_item.bg-wh .img img {
  border-radius: 16px 16px 0 0;
}
.lps_parts--column .column_item.bg-wh .img + .text {
  border-radius: 0 0 16px 16px;
}

/* 白背景なし */
.lps_parts--column .column_item:not(.bg-wh) .img img {
  border-radius: 16px;
}

/* 角丸設定 *************************************/

@media screen and (max-width: 767px) {
  .lps_parts--column + .lps_parts--column,
  .lps_parts--column:not(.column-1) + .lps_parts--column {
    margin-top: 24px;
  }
  .lps_parts--column .lps_parts--child {
    display: block;
  }
  .lps_parts--column {
    width: 100%;
    max-width: 100%;
  }
  .lps_parts--column .column_item {
    width: 100%;
  }
  .lps_parts--column .column_item:not(:last-child) {
    margin-bottom: 24px;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
  .lps_parts--column .column_item:not(:last-child) {
    margin-right: 0;
  }
  .lps_parts--column .text {
    height: auto;
  }
  .lps_parts--column .text.bg-wh,
  .lps_parts--column.column-1 .box {
    padding: 24px;
  }
  .lps_parts--column .img-detail {
    border-radius: 8px;
  }
}

/* lps_parts--img_text
********************************************** */
.lps_parts--img_text .lps_parts--child {
  display: flex;
  justify-content: space-between;
}
.lps_parts--img_text .inner-lg {
  max-width: 76.4vw;
}
.lps_parts--img_text .lps_parts--child.img_right {
  flex-direction: row-reverse;
}
.lps_parts--img_text .img_text--child {
  width: 50%;
}
.lps_parts--img_text .lps_parts--child:not(.inner-lg).bg-wh {
  box-shadow: 0 0 40px 0 rgba(69, 131, 175, 0.2);
}
.lps_parts--img_text .lps_parts--child.bg-wh .img_text--child {
  width: 50%;
}
.lps_parts--img_text .img_text--child:only-child,
.lps_parts--img_text .lps_parts--child.bg-wh .img_text--child:only-child {
  width: 100%;
}
.lps_parts--img_text .lps_parts--child .img_text--txt {
  padding-left: 56px;
}
.lps_parts--img_text .lps_parts--child.img_right .img_text--txt {
  padding-left: 0;
  padding-right: 56px;
}
.lps_parts--img_text .lps_parts--child.bg-wh .img_text--txt {
  padding: 56px;
}
.lps_parts--img_text .lps_parts--child .img_text--img:only-child .img {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
.lps_parts--img_text .img_text--child .img-detail {
  height: 100%;
  overflow: hidden;
}
.lps_parts--img_text .lps_parts--child:not(.bg-wh) .img_text--child .img-detail {
  box-shadow: 0 0 40px 0 rgba(69, 131, 175, 0.2);
}
.lps_parts--img_text .bg-wh .img_text--txt {
  padding: 64px 56px;
}
.lps_parts--img_text .img_text--txt--inner {
  width: 100%;
}
.lps_parts--img_text .text {
  justify-content: flex-start;
}
.lps_parts--img_text .img_text--txt table {
  width: 100%;
}
.lps_parts--img_text .inner-lg .img_text--img {
  position: absolute;
  top: 0;
}
.lps_parts--img_text .inner-lg:not(.img_right) .img_text--img {
  left: 0;
}
.lps_parts--img_text .inner-lg.img_right .img_text--img {
  right: 0;
}
.lps_parts--img_text .inner-lg:not(.img_right) .img_text--txt {
  margin-left: auto;
}
.lps_parts--img_text .inner-lg.img_right .img_text--txt {
  margin-right: auto;
}

/* 角丸設定 *************************************/
/* 白背景 */
.lps_parts--img_text .lps_parts--child.bg-wh:not(.inner-lg) {
  border-radius: 16px;
}
@media screen and (min-width: 768px) {
  .lps_parts--img_text .lps_parts--child.bg-wh:not(.inner-lg) .img_text--img .img-detail,
  .lps_parts--img_text .lps_parts--child.bg-wh:not(.inner-lg) .img_text--img img {
    border-radius: 16px 0 0 16px;
  }
  .lps_parts--img_text .lps_parts--child.bg-wh:not(.inner-lg).img_right .img_text--img .img-detail,
  .lps_parts--img_text .lps_parts--child.bg-wh:not(.inner-lg).img_right .img_text--img img {
    border-radius: 0 16px 16px 0;
  }
}

/* 白背景なし */
.lps_parts--img_text .lps_parts--child:not(.bg-wh) .img_text--img .img-detail,
.lps_parts--img_text .lps_parts--child:not(.bg-wh) .img_text--img img {
  border-radius: 16px;
}
@media screen and (min-width: 768px) {
  /* lg */
  .lps_parts--img_text .lps_parts--child.inner-lg:not(.bg-wh) .img_text--img .img-detail,
  .lps_parts--img_text .lps_parts--child.inner-lg:not(.bg-wh) .img_text--img img {
    border-radius: 0 16px 16px 0;
  }
  .lps_parts--img_text .lps_parts--child.inner-lg:not(.bg-wh).img_right .img_text--img .img-detail,
  .lps_parts--img_text .lps_parts--child.inner-lg:not(.bg-wh).img_right .img_text--img img {
    border-radius: 16px 0 0 16px;
  }
}
@media screen and (max-width: 767px) {
  .lps_parts--img_text .lps_parts--child.bg-wh .img_text--img .img-detail,
  .lps_parts--img_text .lps_parts--child.bg-wh .img_text--img img {
    border-radius: 16px 16px 0 0 !important;
  }
  .lps_parts--img_text .lps_parts--child.inner-lg .img_text--img .img-detail,
  .lps_parts--img_text .lps_parts--child.inner-lg .img_text--img img {
    border-radius: 0 !important;
  }
}

/* 角丸設定 *************************************/

@media print, screen and (min-width: 768px) {
  .lps_parts--img_text .inner-lg .img_text--child {
    width: 50%;
  }
  .lps_parts--img_text .inner-lg .img_text--img {
    height: 100%;
  }
  .lps_parts--img_text .inner-lg .img_text--txt {
    display: flex;
    align-items: center;
    width: 42%;
    min-height: 607.64px;
  }
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding: 64px 80px;
  }

  .lps_parts--img_text .btn:not(.btn-editor-cta) a {
    display: inline-block;
    width: auto;
    max-width: none;
    background-color: transparent !important;
    padding: 16px 108px 16px 0;
    color: #000;
    font-size: 1.25rem;
  }
  .lps_parts--img_text .btn a:hover {
    background-color: transparent;
  }
  .lps_parts--img_text .btn .icon-svg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    background-color: #437dd5;
    border-radius: 50%;
    transition: all 0.4s ease-out;
  }
  .lps_parts--img_text .btn a:hover .icon-svg {
    background-color: #55b2eb;
  }
  .lps_parts--img_text .btn .icon-arrow,
  .lps_parts--img_text .btn .icon-new_tab,
  .lps_parts--img_text .btn .icon-pdf {
    right: 0;
  }
}
@media screen and (max-width: 767px) {
  .lps_parts--img_text .lps_parts--child {
    flex-direction: column !important;
  }
  .lps_parts--img_text .img_text--child,
  .lps_parts--img_text .lps_parts--child.bg-wh .img_text--child {
    width: 100%;
  }
  .lps_parts--img_text .img_text--img {
    margin-bottom: 24px;
  }
  .lps_parts--img_text .lps_parts--child.bg-wh .img_text--img {
    margin-bottom: 0;
  }
  .lps_parts--img_text .bg-wh .img_text--txt {
    padding: 24px;
  }
  .lps_parts--img_text .btn {
    text-align: center;
  }
  .lps_parts--img_text .inner-lg {
    max-width: 86%;
  }
  .lps_parts--img_text .inner-lg .img_text--txt {
    margin-right: auto;
  }
  .lps_parts--img_text .lps_parts--child.img_right .img_text--txt {
    padding-right: 0;
  }
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 40px;
  }
  .lps_parts--img_text.bg-wh + .bg-wh {
    margin-top: 0;
  }
  .lps_parts--img_text .img_text--child .img-detail {
    border-radius: 8px;
  }
  .lps_parts--img_text .inner-lg .img_text--img {
    position: relative;
  }
  .lps_parts--img_text .inner-lg .img_text--child .img-detail {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
  .lps_parts--img_text .lps_parts--child.bg-wh .img-detail,
  .lps_parts--img_text .lps_parts--child.img_right.bg-wh .img-detail {
    border-radius: 16px 16px 0 0;
  }
  .lps_parts--img_text .lps_parts--child.img_right.inner-lg .img-detail,
  .lps_parts--img_text .lps_parts--child.inner-lg .img-detail {
    border-radius: 0;
  }

  .lps_parts--img_text .inner-lg:not(.img_right) .img_text--txt,
  .lps_parts--img_text .lps_parts--child:not(.bg-wh) .img_text--txt {
    padding-left: 0;
  }
  .lps_parts--img_text .lps_parts--child.inner-lg.img_right .img_text--txt,
  .lps_parts--img_text .lps_parts--child.inner-lg.img_right .img_text--txt {
    padding-right: 0;
  }
  .lps_parts--img_text .lps_parts--child:not(.bg-wh) .img_text--child .img-detail {
    box-shadow: none;
  }

  .lps_parts--img_text .lps_parts--child.bg-wh .img_text--txt {
    padding: 24px;
  }
}

/* lps_parts--slide
********************************************** */
.lps_parts--slide .img img {
  display: inline-block;
}
.lps_parts--slide .img + .text {
  margin-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}
.lps_parts--slide .slick-list {
  margin-bottom: 32px;
  margin-left: -24px;
}
.lps_parts--slide .slick-slide {
  margin-left: 24px;
}
.lps_parts--slide .slick-arrow {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100%;
  text-decoration: none;
}
.lps_parts--slide .slick-prev {
  left: -60px;
}
.lps_parts--slide .slick-next {
  right: -60px;
}
.lps_parts--slide .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.lps_parts--slide .slick-arrow::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
}
.lps_parts--slide .slick-prev::after {
  border-bottom: 3px solid #80d4d4;
  border-left: 3px solid #80d4d4;
}
.lps_parts--slide .slick-next::after {
  border-top: 3px solid #80d4d4;
  border-right: 3px solid #80d4d4;
}
.lps_parts--slide .slick-dots li {
  display: block;
  width: 12px;
  height: 12px;
  background-color: #ddd;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.4s ease-out;
  margin: 6px;
}
.lps_parts--slide .slick-dots li.slick-active {
  background-color: #80d4d4;
}
.lps_parts--slide .slick-dots li:hover {
  opacity: 0.7;
}
.lps_parts--slide .slick-dots button {
  padding: 0;
  cursor: pointer;
  color: transparent;
  background: transparent;
  outline: none;
  border: none;
}
@media screen and (max-width: 1080px) {
  .lps_parts--slide .img + .text {
    margin-top: 16px;
  }
  .lps_parts--slide .slick-dots {
    position: relative;
    bottom: 4px;
  }
  .lps_parts--slide .slick-arrow {
    width: 56px;
  }
  .lps_parts--slide .slick-prev {
    left: 0;
  }
  .lps_parts--slide .slick-next {
    right: 0;
  }
  .lps_parts--slide .slick-arrow::after {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 767px) {
  .lps_parts--slide .slick-arrow {
    width: 40px;
  }
  .lps_parts--slide .slick-arrow::after {
    width: 16px;
    height: 16px;
  }
}

/* lps_parts--button
********************************************** */
.lps_parts--button .btn + .btn,
.lps_parts--cta .btn + .btn {
  margin-top: 0;
}
.lps_parts--button .flex .btn + .btn {
  margin-left: 0;
}
.lps_parts--button .btn:first-child,
.lps_parts--banner .bnr:first-child {
  margin-left: auto;
}
.lps_parts--button .btn:last-child,
.lps_parts--banner .bnr:last-child {
  margin-right: auto;
}
.lps_parts--button .btn a,
.lps_parts--cta .btn a {
  min-width: 100%;
}
.btn.txt-ctr a {
  margin-left: auto;
  margin-right: auto;
}

@media print, screen and (min-width: 768px) {
  .lps_parts--column.column-2 + .lps_parts--button,
  .lps_parts--column.column-3 + .lps_parts--button,
  .lps_parts--column.column-4 + .lps_parts--button {
    margin-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .lps_parts--button .btn:not(:last-child) {
    margin-bottom: 8px;
  }
}

/* lps_parts--banner
********************************************** */
.lps_parts--banner .bnr a {
  display: block;
}
.lps_parts--banner .bnr a:hover {
  opacity: 0.7;
}

/* lps_parts--flow
********************************************** */
.lps_parts--flow .flow_item {
  position: relative;
  padding-left: 96px;
  counter-increment: number;
}
.lps_parts--flow .flow_item:not(:last-child) {
  padding-bottom: 72px;
}
.lps_parts--flow .ttl-03 {
  padding-top: 10px;
  margin-bottom: 0.5em !important;
  font-size: 2rem;
}
.lps_parts--flow .flow_item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 70px;
  left: 36px;
  display: block;
  width: 0;
  height: calc(100% - 77px);
  border-right: 3px solid #80d4d4;
}
.lps_parts--flow .ttl-03::after {
  content: counter(number);
  position: absolute;
  top: 0;
  left: -90px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  background-color: #80d4d4;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  font-size: 1.25rem; /* 20px */
}
.lps_parts--flow .txt-wh .ttl-03 {
  color: #fff;
}
.lps_parts--flow .flow_item.txt-wh:not(:last-child)::before {
  border-color: #fff;
}
.lps_parts--flow .txt-wh .ttl-03::after {
  color: #1d7fb7;
  background-color: #fff;
}
.lps_parts--flow .text img,
.lps_parts--faq .text img {
  max-width: 248px;
  margin-top: 0;
  margin-right: 40px;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .lps_parts--flow .flow_item {
    padding-left: 54px;
  }
  .lps_parts--flow .flow_item:not(:last-child) {
    padding-bottom: 40px;
  }
  .lps_parts--flow .ttl-03 {
    padding-top: 2px;
    font-size: 1.429rem; /* 20px */
  }
  .lps_parts--flow .flow_item:not(:last-child)::before {
    top: 54px;
    left: 17px;
    height: calc(100% - 73px);
  }
  .lps_parts--flow .ttl-03::after {
    top: -3px;
    left: -55px;
    width: 42px;
    height: 42px;
    font-size: 1.286rem; /* 18px */
  }
  .lps_parts--flow .ttl-03::before {
    left: -52px;
  }
  .lps_parts--flow .text img {
    float: none !important;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 16px !important;
  }
}

/* lps_parts--faq
********************************************** */
.lps_parts--faq .faq_item:last-child {
  border-bottom: 2px solid #efefef;
}
.lps_parts--faq .lps_parts--child.inner {
  max-width: 1020px;
}
.lps_parts--faq .ttl-03,
.lps_parts--faq .mce-content-body {
  padding: 32px 48px 32px 68px;
}
.lps_parts--faq .ttl-03 {
  cursor: pointer;
  border-top: 2px solid #efefef;
  margin-bottom: 0 !important;
  font-size: 1.25rem;
}
.lps_parts--faq .faq--deco {
  position: absolute;
  top: 33px;
  left: 32px;
  display: block;
  font-family: "Outfit", sans-serif;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: 0;
}
.lps_parts--faq .ttl-03 .accordion_deco {
  position: absolute;
  top: 0;
  right: 13px;
  bottom: 0;
  margin: auto 0;
  width: 48px;
  height: 48px;
  background-color: #bfbfbf;
  border-radius: 50%;
  transition: all 0.4s ease-out;
}
.lps_parts--faq .ttl-03 .accordion_deco::before,
.lps_parts--faq .ttl-03 .accordion_deco::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 12px;
  height: 2px;
  background-color: #fff;
}
.lps_parts--faq .ttl-03:hover .accordion_deco,
.lps_parts--faq .ttl-03.close .accordion_deco {
  background-color: #dfdfdf;
}
.lps_parts--faq .ttl-03 .accordion_deco::after {
  transform: rotate(90deg);
  transition: all 0.4s ease-out;
}
.lps_parts--faq .ttl-03.close .accordion_deco::after {
  transform: rotate(0deg);
}
.lps_parts--faq .mce-content-body {
  position: relative;
  padding-top: 0;
  display: none;
}
.lps_parts--faq .mce-content-body .editor-ttl2 {
  margin-top: 0;
}

@media screen and (max-width: 767px) {
  .lps_parts--faq .ttl-03 {
    padding: 20px 40px 20px 42px;
  }
  .lps_parts--faq .mce-content-body {
    padding: 0 40px 20px 42px;
  }
  .lps_parts--faq .ttl-03 {
    font-size: 1rem;
    line-height: 1.65;
  }
  .lps_parts--faq .ttl-03::after,
  .lps_parts--faq .mce-content-body::after {
    left: 0;
    font-size: 1.2rem; /* 18px */
  }
  .lps_parts--faq .faq--deco {
    top: 18px;
    left: 0;
    font-size: 1.8rem;
  }
  .lps_parts--faq .ttl-03::before {
    width: 40px;
  }
  .lps_parts--faq .ttl-03 .accordion_deco {
    right: 0;
    width: 30px;
    height: 30px;
  }
  .lps_parts--faq .mce-content-body::after {
    top: 11px;
    left: 9px;
    font-size: 1.5rem;
  }
  .lps_parts--faq .text img {
    float: none !important;
    margin-top: 2px;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 8px !important;
    border-radius: 8px;
  }
}

/* cta
********************************************** */
.loops_cta .inner {
  background-color: #29323d;
  border-radius: 40px;
  min-height: 224px;
  padding: 40px 60px 56px;
  color: #fff;
}
.loops_cta--ttl {
  margin-right: 24px;
}
.loops_cta--ttl .font-en {
  display: block;
  margin-bottom: 16px;
  font-size: 4.444444rem; /*80px*/
  line-height: 1;
  color: #88cccc;
}
.loops_cta--ttl .jp {
  padding-left: 0.3em;
  font-size: 1.333333rem; /*24px*/
}
.cta_btns a {
  display: flex;
  align-items: center;
  border-radius: 60px;
  font-weight: 600;
  letter-spacing: 0.05em;
}
.cta_btns--contact {
  margin-right: 24px;
}
.cta_btns--contact a {
  background-color: #ff7a59;
  padding: 22px 24px;
  color: #fff;
}
.cta_btns--contact a:hover {
  background-color: #ff957a;
}
.cta_btns--contact a span {
  display: inline-block;
}
.cta_btns--contact a span:nth-child(1) {
  position: relative;
  margin-right: 16px;
  padding: 4px 16px 4px 42px;
  background-color: #fff;
  border-radius: 24px;
  color: #ff7a59;
}
.cta_btns--contact a span:nth-child(1)::before {
  content: "";
  position: absolute;
  top: 17px;
  left: 15px;
  width: 21px;
  height: 10px;
  border-left: 4px solid #ff7a59;
  border-bottom: 4px solid #ff7a59;
  transform: rotate(-45deg);
}
.cta_btns--pdf a {
  padding: 8px 20px;
  background-color: #e6e6e6;
}
.cta_btns--pdf a:hover {
  background-color: #e8f3f3;
}
.cta_btns--pdf a span {
  display: inline-block;
  border-radius: 32px;
  padding: 4px 12px 6px;
  margin-left: 8px;
  background-color: #88cccc;
  color: #fff;
  font-size: 0.8888888rem;
  letter-spacing: 0.05em;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .loops_cta .inner {
    width: 95%;
    border-radius: 16px;
    padding: 32px 24px 40px;
  }
  .loops_cta--ttl {
    margin-right: 0;
    margin-bottom: 24px;
  }
  .loops_cta--ttl .font-en {
    font-size: 3.5rem;
  }
  .loops_cta--ttl .jp {
    padding-left: 0;
    font-size: 1.15rem;
  }
  .cta_btns--contact {
    margin-bottom: 24px;
  }
  .cta_btns--contact a {
    padding: 14px;
  }
  .cta_btns--contact a span:nth-child(1) {
    padding: 4px 10px 4px 31px;
  }
  .cta_btns--contact a span:nth-child(1)::before {
    top: 11px;
    left: 9px;
    width: 14px;
    height: 10px;
  }
}

/* post-edit-link
********************************************** */
.post-edit-link {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 10;
  padding: 16px;
  border-radius: 50%;
  border: 1px solid #999;
}
.post-edit-link:hover {
  background-color: #ddd;
}
.post-edit-link::after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuODrOOCpOODpOODvF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDAxIDQwMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAxIDQwMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM5OTk5OTk7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzcwLjEsMjUxLjljLTUuNSwwLTEwLDQuNS0xMCwxMHY4OC43YzAsMTYuNS0xMy40LDI5LjktMzAsMzBINDkuOWMtMTYuNSwwLTI5LjktMTMuNC0zMC0zMFY5MC4zCgljMC0xNi41LDEzLjQtMjkuOSwzMC0zMGg4OC43YzUuNSwwLDEwLTQuNSwxMC0xMGMwLTUuNS00LjUtMTAtMTAtMTBINDkuOUMyMi40LDQwLjQsMCw2Mi43LDAsOTAuM3YyNjAuMwoJYzAsMjcuNiwyMi40LDQ5LjksNDkuOSw0OS45aDI4MC4yYzI3LjYsMCw0OS45LTIyLjQsNDkuOS00OS45di04OC43QzM4MC4xLDI1Ni40LDM3NS42LDI1MS45LDM3MC4xLDI1MS45eiIvPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzc2LjEsMTQuN2MtMTcuNi0xNy42LTQ2LTE3LjYtNjMuNiwwTDEzNC40LDE5Mi45Yy0xLjIsMS4yLTIuMSwyLjctMi42LDQuNGwtMjMuNCw4NC42Yy0xLDMuNSwwLDcuMiwyLjYsOS43CgljMi41LDIuNSw2LjMsMy41LDkuNywyLjZsODQuNi0yMy40YzEuNy0wLjUsMy4yLTEuMyw0LjQtMi42TDM4Ny45LDkwYzE3LjUtMTcuNiwxNy41LTQ2LDAtNjMuNkwzNzYuMSwxNC43eiBNMTU2LjIsMTk5LjNMMzAyLDUzLjUKCWw0Nyw0N0wyMDMuMiwyNDYuNEwxNTYuMiwxOTkuM3ogTTE0Ni44LDIxOC4ybDM3LjYsMzcuNmwtNTIsMTQuNEwxNDYuOCwyMTguMnogTTM3My43LDc1LjhsLTEwLjYsMTAuNmwtNDctNDdsMTAuNi0xMC42CgljOS43LTkuNywyNS42LTkuNywzNS4zLDBsMTEuNywxMS43QzM4My41LDUwLjMsMzgzLjUsNjYuMSwzNzMuNyw3NS44eiIvPgo8L3N2Zz4K");
}
@media screen and (max-width: 767px) {
  .post-edit-link {
    display: none;
  }
}

/* lps_parts--history
********************************************** */
.lps_parts--history .lps_parts--child {
  position: relative;
  padding-top: 42px;
  padding-bottom: 42px;
}
.lps_parts--history .lps_parts--child::before {
  content: "";
  position: absolute;
  top: 6px;
  left: 8px;
  width: 3px;
  height: 100%;
  background-color: #80d4d4;
}
.lps_parts--history .history_item {
  position: relative;
  padding-left: 60px;
}
.lps_parts--history .history_item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 19px;
  background-color: #fff;
  border: 3px solid #80d4d4;
  border-radius: 50%;
}
.lps_parts--history .history_item:not(:last-child) {
  margin-bottom: 40px;
}
.lps_parts--history .year {
  width: 132px;
  font-size: 2rem;
  line-height: 1;
  letter-spacing: 0.05em;
  font-weight: 600;
}
.lps_parts--history .txt {
  padding-top: 3px;
}

@media screen and (max-width: 767px) {
  .lps_parts--history .lps_parts--child {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .lps_parts--history .history_item {
    flex-wrap: wrap;
    padding-left: 40px;
  }
  .lps_parts--history .history_item:not(:last-child) {
    padding-bottom: 32px;
  }
  .lps_parts--history .history_item::before {
    top: 5px;
  }
  .lps_parts--history .year {
    width: 100%;
  }
  .lps_parts--history .txt {
    padding-top: 6px;
  }
}

/* relation_pages
********************************************** */
.relation_pages--link {
  width: calc(100% / 3);
  height: 398px;
}
.relation_pages--link a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.relation_pages--link:nth-child(1) a {
  background-color: #555555;
}
.relation_pages--link:nth-child(2) a {
  background-color: #444444;
}
.relation_pages--link:nth-child(3) a {
  background-color: #333333;
}
.relation_pages--link a .jp {
  font-size: 2rem;
  font-family: "Shuei KakuGo Gin B", sans-serif;
  color: #fff;
}
.relation_pages--link a .en {
  color: #80d4d4;
}

@media screen and (max-width: 767px) {
  .relation_pages > .flex {
    flex-wrap: wrap;
  }
  .relation_pages--link {
    width: 100%;
    height: 178px;
  }
  .relation_pages--link a .jp {
    font-size: 1.4rem;
  }
}

/* lps_parts--plan
********************************************** */
.lps_parts--plan .plan_bloc {
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 5;
  gap: 24px;
  border-top: 8px solid;
  border-bottom: 8px solid;
  margin-right: 24px;
  box-shadow: 0 0 40px 0 rgba(69, 131, 175, 0.2);
}
.lps_parts--plan .plan_cnt2 .plan_bloc:nth-child(2n),
.lps_parts--plan .plan_cnt3 .plan_bloc:nth-child(3n),
.lps_parts--plan .plan_bloc:last-child {
  margin-right: 0;
}
.line-plan_bloc::before {
  content: '';
  position: absolute;
  inset-inline: -32px;
  display: block;
  border-bottom: 1px solid #dfdfdf;
}

/* --- プラン名など --- */
.plan_bloc {
  padding: 32px;
}
.plan_bloc--head {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
}
.plan_bloc--head.line-plan_bloc::before {
  bottom: 0;
}
.plan_bloc--head .ttl {
  flex: 1;
}
.plan_bloc--head .name {
  margin-bottom: 8px;
  font-size: 2rem;
  font-family: "Shuei KakuGo Gin B", sans-serif;
}
.plan_bloc--head .img {
  width: 140px;
  height: 140px;
}
.plan_bloc--head .deco {
  position: relative;
  font-size: 8.75rem;
  font-weight: 600;
  line-height: 1;
  opacity: 0.5;
  z-index: -1;
}
.plan_bloc--head.img_on .deco {
  position: absolute;
  left: 0;
  width: 100%;
  display: block;
  text-align: center;
  pointer-events: none;
  z-index: -1;
}
.plan_bloc .mce-content-body {
  margin-bottom: 0 !important;
}

/* --- メリット & デメリット --- */
.plan_bloc--merit li {
  position: relative;
  padding-left: 42px;
}
.plan_bloc--merit li:not(:last-child) {
  margin-bottom: 16px;
}
.plan_bloc--merit .merit,
.plan_bloc--merit .demerit {
  position: absolute;
  top: -3px;
  left: 0;
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #bbbbbb;
}
.plan_bloc--merit .merit::before,
.plan_bloc--merit .demerit::before,
.plan_bloc--merit .demerit::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.plan_bloc--merit .merit::before {
  width: 8px;
  height: 12px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: translateY(-2px) rotate(45deg);
}
.plan_bloc--merit .demerit::before,
.plan_bloc--merit .demerit::after {
  width: 16px;
  height: 2px;
  background-color: #fff;
}
.plan_bloc--merit .demerit::before {
  transform: rotate(-45deg);
}
.plan_bloc--merit .demerit::after {
  transform: rotate(45deg);
}

/* --- プラン詳細 --- */
.plan_bloc--detail {
  display: flex;
  flex-wrap: wrap;
}
.plan_bloc--detail li:only-child {
  width: 100% !important;
}
.plan_bloc--detail.detail_cnt3 li {
  width: calc(100% / 3 - (8px * 2 / 3));
  margin-right: 8px;
  margin-bottom: 8px;
  border-bottom: 2px solid #ddd;
}
.plan_bloc--detail.detail_cnt3 li:nth-child(3n) {
  margin-right: 0;
}
.plan_bloc--detail.detail_cnt2 li {
  width: calc(100% / 2 - (8px * 1 / 2));
  margin-right: 8px;
  margin-bottom: 8px;
  border-bottom: 2px solid #ddd;
}
.plan_bloc--detail.detail_cnt2 li:nth-child(2n) {
  margin-right: 0;
}
.plan_bloc--detail .ttl {
  position: relative;
  padding: 4px;
  text-align: center;
  background-color: #ddd;
}
.plan_bloc--detail .content {
  padding: 8px;
  text-align: center;
}

/* --- 料金 --- */
.plan_bloc--price {
  position: relative;
  padding-top: 32px;
  font-size: 1.125rem;
  font-family: "Shuei KakuGo Gin B", sans-serif;
  text-align: center;
}
.plan_bloc--price.line-plan_bloc::before {
  top: 0;
}
.plan_bloc--price .num {
  display: inline-block;
  padding: 0 6px;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .lps_parts--plan .plan_bloc,
  .lps_parts--plan .plan_cnt2 .plan_bloc {
    width: 100%;
    margin-right: 0;
  }
  .line-plan_bloc::before {
    inset-inline: -20px;
  }
  .plan_bloc {
    padding: 20px;
  }
  .plan_bloc:not(:last-child) {
    margin-bottom: 24px;
  }
  .plan_bloc--head {
    padding-bottom: 20px;
  }
  .plan_bloc--head .name {
    font-size: 1.5rem;
  }
  .plan_bloc--head .catch {
    line-height: 1.35;
    font-size: 0.93333rem;
  }
  .plan_bloc--head .deco {
    font-size: 7rem;
  }
  .plan_bloc--head .img {
    width: 100px;
    height: 100px;
  }
  .plan_bloc--merit li {
    padding-left: 36px;
  }
  .plan_bloc--merit li:not(:last-child) {
    margin-bottom: 10px;
  }
  .plan_bloc--merit .merit,
  .plan_bloc--merit .demerit {
    top: 0;
    width: 26px;
    height: 26px;
  }
  .plan_bloc--price {
    padding-top: 20px;
    font-size: 1rem;
  }
  .plan_bloc--price .num {
    font-size: 2rem;
  }

  .plan_bloc--detail.detail_cnt3 li,
  .plan_bloc--detail.detail_cnt2 li {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0;
  }
}
