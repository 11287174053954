@use "sass:math";
@use '../abstracts' as *;

/* ####################################################################################

  Sass導入メモ
  既存の記述をそのまま使用
  flex-colはmain.css似合った記述を移行

#################################################################################### */

/* flex
**************************************** */

/* --- ブロック要素 --- */
.flex {
  display: flex;
}

/* --- インライン要素 --- */
.flex-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

/* --- 逆向き --- */
.flex-reverse {
  flex-direction: row-reverse;
}

/* --- 縦並び --- */
.flex-column {
  flex-direction: column;
}

/* --- 水平方向揃え --- */
.flex-j-start {
  justify-content: flex-start;
}
.flex-j-end {
  justify-content: flex-end;
}
.flex-j-ctr {
  justify-content: center;
}
.flex-j-between {
  justify-content: space-between;
}
.flex-j-around {
  justify-content: space-around;
}

/* --- 垂直方向揃え --- */
.flex-a-start {
  align-items: flex-start;
}
.flex-a-end {
  align-items: flex-end;
}
.flex-a-ctr {
  align-items: center;
}
.flex-a-baseline {
  align-items: baseline;
}
.flex-a-stretch {
  align-items: stretch;
}

/* --- 子要素の折り返し設定 --- */
.flex-c-nowrap {
  flex-wrap: nowrap;
}
.flex-c-wrap {
  flex-wrap: wrap;
}

/* --- 子要素の複数行設定 --- */
.flex-c-reverse {
  flex-wrap: wrap-reverse;
}
.flex-c-start {
  align-content: flex-start;
}
.flex-c-start {
  align-content: flex-start;
}
.flex-c-end {
  align-content: flex-end;
}
.flex-c-ctr {
  align-content: center;
}
.flex-c-baseline {
  align-content: baseline;
}
.flex-c-stretch {
  align-content: stretch;
}

@include mq(sp) {
  /* --- 縦並び - sp --- */
  .flex-sp-block {
    flex-direction: column;
  }
}

/*  main.css から移行
**************************************** */
/* --- ざっくりflexレイアウト --- */
.flex-col1 > * {
  width: 100%;
}
.flex-col2,
.flex-col3,
.flex-col4 {
  flex-wrap: wrap;
}
.flex-col2 > * {
  width: 48.5%;
  margin-right: 3%;
  margin-bottom: 3%;
}
.flex-col2 > *:nth-child(even) {
  margin-right: 0;
}
.flex-col3 > * {
  width: 31.33%;
  margin-right: 3%;
  margin-bottom: 3%;
}
.flex-col3 > *:nth-child(3n) {
  margin-right: 0;
}
.flex-col4 > * {
  width: 23.5%;
  margin-right: 2%;
  margin-bottom: 2%;
}
.flex-col4 > *:nth-child(4n) {
  margin-right: 0;
}
@include mq(sp) {
  /* --- ざっくりflexレイアウト - sp --- */
  .flex-col2.flex-sp-block > *,
  .flex-col3.flex-sp-block > *,
  .flex-col4.flex-sp-block > * {
    width: 100%;
    margin-bottom: 16px;
    margin-right: 0;
  }
  .flex-col2.flex-sp-block > *:last-child,
  .flex-col3.flex-sp-block > *:last-child,
  .flex-col4.flex-sp-block > *:last-child {
    margin-bottom: 0;
  }
  .flex-sp-col2 > *,
  .flex-sp-col2.flex-col3 > *:nth-child(3n) {
    width: 48.5%;
    margin-right: 3%;
    margin-bottom: 3%;
  }
  .flex-sp-col2 > *:nth-child(even) {
    margin-right: 0;
  }
  .flex-sp-col3 > *,
  .flex-sp-col3.flex-col4 > *:nth-child(4n) {
    width: 31.33%;
    margin-right: 3%;
    margin-bottom: 3%;
  }
  .flex-sp-col3 > *:nth-child(3n) {
    margin-right: 0;
  }
}