@use "../abstracts" as *;

/* ####################################################################################

  Sass導入メモ
  main.scssにあった section_pdg を移動

#################################################################################### */

/* --- section_pdg --- */
.section_pdg {
  padding-top: 96px;
  padding-bottom: 96px;
}
.section_pdg-top {
  padding-top: 96px;
}
.section_pdg-btm {
  padding-bottom: 96px;
}
.section_pdg-sm {
  padding-top: 80px;
  padding-bottom: 80px;
}
.page-contact .section_pdg,
.single-e-book .section_pdg,
.single-advice .section_pdg {
  padding-top: 96px;
}
@include mq(sp) {
  .section_pdg-top {
    padding-top: 48px;
  }
  .section_pdg-btm {
    padding-bottom: 48px;
  }
  .page-contact .section_pdg,
  .single-e-book .section_pdg,
  .single-advice .section_pdg {
    padding-top: 40px;
  }
  .page-contact main.section_pdg,
  .single-e-book main.section_pdg,
  .single-advice main.section_pdg {
    padding-bottom: 0;
  }
  .page-contact .recaptcha_policy {
    margin-bottom: 0;
  }
  .page-contact .lps_sec.contact.section_pdg {
    padding-bottom: 0;
  }

  /* --- section_pdg --- */
  .section_pdg {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .section_pdg-sm {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}