@use "../abstracts" as *;

/* ##############################################################################

  FOOTER

############################################################################## */

@media screen and (max-width: 767px) {
  body:has(.sp_fix_cta) .footer {
    padding-bottom: 56px;
  }
  .sp_fix_cta {
    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    opacity: 0;
    pointer-events: none;
    transition: all 0.6s ease;
    transform: translateY(20px);
  }
  .sp_fix_cta.active {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }
  .sp_fix_cta li {
    width: 50%;
  }
  .sp_fix_cta li a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--clr-wht);
    font-size: 0.9125rem;
    line-height: 1.3;
    text-align: center;
    padding: 0 4px 2px;
    height: 56px;
  }
  .sp_fix_cta li a img {
    height: 1.2rem !important;
    width: auto !important;
    margin-left: 8px;
    filter: brightness(0) invert(1);
  }
  .sp_fix_cta li i {
    margin-left: 8px;
  }
  .sp_fix_cta li:nth-child(1) a {
    background-color: #e02828;
  }
  .sp_fix_cta li:nth-child(2) a {
    background-color: var(--clr-sub-04);
  }
}

/* footer
********************************************** */
.footer {
  position: relative;
  z-index: 2;
}
.foot_cta + .footer {
  margin-top: -12.5vw;
}
.footer_wrap {
  padding-top: 124px;
  border-radius: 12.5vw 12.5vw 0 0;
  background-color: var(--clr-wht);
}
.footer_wrap > .inner {
  width: 92%;
  max-width: 1360px;
}
.footer--cacth {
  font-size: min(vw(28), rem(28));
  letter-spacing: 0.2em;
  font-family: var(--font-jp-b);
}
.footer--page_nav {
  flex: 1;
}
.footer--page_nav > li {
  min-width: 19%;
}
.footer--page_nav > li:not(:last-child) {
  margin-right: 24px;
}
.footer--page_nav .ttl {
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: 0.06em;
  line-height: 1;
}
.footer--page_nav .ttl_sub {
  margin-top: 16px;
}
.footer--page_nav .nav_list li:not(:last-child) {
  margin-bottom: 6px;
}
.footer--page_nav .nav_list li a {
  position: relative;
  display: inline-block;
  padding-left: 16px;
  font-size: clamp(rem(13), vw(14), rem(14));
  font-family: var(--font-jp-b);
  line-height: 1.45;
}
.footer--page_nav .nav_list > li > a {
  margin-left: 1rem;
}
.footer--sub_nav .nav_list a:hover,
.footer--page_nav .nav_list li a:hover {
  opacity: 0.5;
}
.footer--page_nav .nav_list li a::before {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--clr-sub-01);
}
.footer--page_nav .nav_list li a svg {
  margin-left: 4px;
  vertical-align: middle;
}
.footer--page_nav .nav_list small {
  opacity: 0.5;
  font-size: 0.8rem;
}
.footer--page_nav .nav_list--child {
  margin-top: 8px;
  padding-left: 1rem;
}
.footer--sub_nav {
  margin-left: 40px;
}
.footer--sub_nav .sns_list li {
  margin-bottom: 108px;
  transition: all 0.5s ease;
}
.footer--sub_nav .sns_list li:hover {
  transform: scale(0.95);
}
.footer--sub_nav .sns_list li:first-child {
  margin-right: 16px;
}
.footer--sub_nav .sns_list a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid var(--clr-blk);
}
.footer--sub_nav .nav_list {
  .btn {
    @include mq(sp, min, ps) {
      margin: 0 0 rem(8) !important;
    }
    @include mq(sp) {
      margin: 0 sprem(8) sprem(8)!important;
    }
    a {
      @include center-flex;
      padding: rem(8) rem(16);
    }
  }
  .btn-cta .free {
    font-size: rem(11);
    padding: rem(4) rem(6);
  }
}

.footer--sub_nav .nav_list li:not(:last-child) {
  margin-bottom: 2px;
}
.footer--sub_nav .nav_list a {
  font-size: 0.8rem;
  font-family: var(--font-jp-b);
}
.footer--sub_nav .sns_list li:nth-child(2) a {
  color: var(--clr-wht);
}
.footer--sub_nav .nav_list .icon-svg--pdf svg {
  width: auto !important;
  height: 1rem !important;
  vertical-align: middle;
}

.footer_client {
  border-top: 1px solid #dfdfdf;
  padding: 0 40px;
  border-bottom: 1px solid #dfdfdf;
}
.footer_client--ttl {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 0.875rem;
  font-family: var(--font-jp-b);
  cursor: pointer;
}
.footer_client--ttl .font-en {
  display: inline-block;
  margin-right: 24px;
  font-size: 1.375rem;
}
.footer_client--ttl.accordion_btn .icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 48px;
  height: 48px;
}
.footer_client--ttl.accordion_btn .icon::before,
.footer_client--ttl.accordion_btn .icon::after {
  width: 30%;
  height: 3px;
}
.footer_client--list {
  display: flex;
  padding-bottom: 40px;
}
.footer_client--list li {
  width: calc(100% / 4 - (32px * 3 / 4));
  margin-right: 32px;
  letter-spacing: 0;
  margin-bottom: 4px;
  font-size: 0.75em;
}
.footer_client--list li:nth-child(4n) {
  margin-right: 0;
}
.footer_client--list li a {
  position: relative;
  display: block;
  padding-left: 16px;
  white-space: nowrap; /* 折り返し無しにする */
  overflow: hidden; /* はみ出た部分を非表示 */
  text-overflow: ellipsis; /* 語尾を3点リーダーに */
}
.footer_client--list li a::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--clr-sub-01);
}

.footer_license {
  padding: 32px 8%;
  border: 1px solid #dfdfdf;
}
.footer_license li {
  display: flex;
  align-items: center;
}
.footer_license li:nth-child(1) {
  flex: 1;
  background-color: var(--clr-wht);
  display: flex;
  align-items: center;
  padding: 16px;
  font-size: 0.825rem;
  line-height: 1.5;
  letter-spacing: 0.02em;
  word-break: break-all;
}
.footer_license li:nth-child(1) small {
  display: inline-block;
  margin-left: 8px;
}

.footer_service {
  padding-top: 80px;
  padding-bottom: 80px;
}
.footer_service li {
  margin: 0 40px;
}
.copyright {
  padding-top: 24px;
  padding-bottom: 24px;
}
.footer--mini_menu {
  text-align: center;
}

.footer--mini_menu a {
  color: rgb(0 0 0 / 80%);
}
@media screen and (max-width: 1200px) {
  .footer_license--list {
    flex-wrap: wrap;
  }
  .footer_license li:nth-child(1) {
    flex-direction: column;
    width: 100%;
    flex: auto;
    margin: 0 0 24px;
  }
}
@media screen and (min-width: 768px) {
  .footer_license li:not(:last-child):not(:nth-child(3)) {
    margin-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .footer_wrap {
    padding-top: 48px;
    border-radius: 24px 24px 0 0;
  }
  .footer_wrap > .inner {
    width: 86%;
  }
  .footer--logo {
    width: 94px;
    margin: 0 auto 24px;
  }
  .footer--cacth {
    font-size: 1rem;
    letter-spacing: 0.08em;
  }
  .footer--cacth + p {
    margin-bottom: 32px;
  }
  .footer--page_nav {
    flex-wrap: wrap;
  }
  .footer--page_nav,
  .footer--page_nav li {
    width: 100%;
  }
  .footer--page_nav > li:not(:last-child) {
    margin-right: 0;
  }
  .footer--page_nav .ttl {
    position: relative;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #ddd;
    font-size: 1.25rem;
  }
  .footer--page_nav .ttl::before,
  .footer--page_nav .ttl::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 10px;
    width: 16px;
    height: 2px;
    background-color: var(--clr-mono-03);
  }
  .footer--page_nav .ttl::after {
    transform: rotate(90deg);
  }
  .footer--page_nav .nav_list {
    display: none;
    padding: 0 8px;
    margin-bottom: 32px;
  }
  .footer--page_nav .nav_list li {
    width: 100%;
  }
  .footer--page_nav .nav_list li:not(:last-child) {
    margin-bottom: 6px;
  }
  .footer--page_nav .nav_list li a {
    padding-left: 16px;
  }

  .footer--sub_nav {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }
  .footer--sub_nav .sns_list {
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
    order: 2;
  }
  .footer--sub_nav .sns_list a {
    width: 48px;
    height: 48px;
  }
  .footer--sub_nav .nav_list {
    margin-block: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    order: 1;
  }
  .footer--sub_nav .nav_list li {
    margin: 0 8px;
  }
  .footer_service {
    padding: 24px 0;
  }
  .footer_service li {
    margin: 0 7px;
  }

  .footer--sub_nav .sns_list li {
    margin-bottom: 0;
  }

  .footer_client {
    padding: 0 16px;
  }
  .footer_client--ttl {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .footer_client--ttl.accordion_btn .icon {
    width: 32px;
    height: 32px;
  }
  .footer_client--ttl.accordion_btn .icon::before,
  .footer_client--ttl.accordion_btn .icon::after {
    width: 33%;
    height: 2px;
  }
  .footer_client--list li {
    width: calc(100% / 2 - (16px * 1 / 2));
    margin-right: 16px;
    line-height: 1.4;
  }
  .footer_client--list li:nth-child(2n) {
    margin-right: 0;
  }
  .footer_client--list li a::before {
    top: 6px;
  }

  .footer_license {
    padding: 16px;
  }
  .footer_license--list {
    flex-wrap: wrap;
  }
  .footer_license li:not(:last-child):not(:nth-child(2)) {
    margin-bottom: 8px;
    margin-right: 0;
  }
  .footer_license--list li:nth-child(1) {
    flex: auto;
    width: 100%;
    padding: 0 16px 16px;
    flex-direction: column;
    order: 1;
  }
  .footer_license--list li:nth-child(2) {
    order: 3;
    width: 50%;
  }
  .footer_license--list li:nth-child(3) {
    order: 2;
  }
  .footer_license--list li:nth-child(4) {
    order: 4;
    width: 50%;
  }
  .footer_license--list li img {
    margin: auto;
  }
}

