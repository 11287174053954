@use "sass:map";

// SASS用の基本設定変数
$fz-pc: 16;
$fz-sp: 14;
$wid-pc: 1920;
$wid-sp: 414;

// カラー管理
$colors: (
  clr-primary-default: #437dd5,
  clr-primary-dark: #1d59b5,
  clr-primary-light: #5f97eb,

  clr-secondary-default: #f83838,
  clr-secondary-dark: #c62d2d, /*カンプはこっちだが既存は2の方を使っている。統合しても良いかも*/
  clr-secondary-dark2: #c4000b, 
  clr-secondary-light: #f25353,

  clr-bg-01: #f9f9f9,
  clr-bg-02: #f4f0ec,

  clr-sub-01: #80d4d4,
  clr-sub-02: #4fbfec,
  clr-sub-03: #3bb68b,
  clr-sub-04: #1e3443,
  clr-sub-05: #74BBB2,

  clr-mono-01: #f2f2f2,
  clr-mono-02: #e2e2e2,
  clr-mono-03: #c4c4c4,
  clr-mono-04: #a4a4a4,
  clr-mono-05: #7e7e7e,
  clr-mono-06: #000000,

  clr-text-primary: #000000,
  clr-text-secondary: #504a4a,
  clr-text-placeholder: #ccc,
  clr-text-light: #f7f7f7,

  clr-wht: #fff,
  clr-blk: #000,
);

// CSS変数
:root {

  // font-families
  --font-en: "Outfit", sans-serif;
  --font-jp: "Shuei KakuGo Gin M", sans-serif;
  --font-jp-b: "Shuei KakuGo Gin B", sans-serif;

  //カラーを出力
  @each $key, $color in $colors {
    --#{$key} : #{$color};
  }

  //shadow
  --shadow-01: 0 0 40px 0 rgba(69, 131, 175, 0.3);

  // animation
  --transit-default: all .4s ease-out;
}

// SASS Maps

$z-index: (
  "header": 9999,
  "top": 9990,
  "aside": 75,
  "middle": 50,
  "main": 1,
  "base": 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160,
  // 2560px
  lg: 120,
  // 1920px
  pc: 85.375,
  // 1366px
  med: 67.5,
  // 1080px
  sp: 47.9375,
  // 767px
  sm: 40,
  // 640px
  xs: 34.375,
  // 500px
  se: 21.25,
  // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$aspect-ratios: (
  widescreen: "3/2",
  square: "1/1",
  portrait: "2/3",
);
