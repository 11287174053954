@use "../abstracts" as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header,
.header-shadow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9990;
  height: 114px;
  transition: all 0.3s ease;
}
.header-shadow {
  z-index: 9989;
  pointer-events: none;
}
.header > .inner {
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 0 110px 0 2.34vw;
  transition: all 0.4s ease;
}
.header--logo {
  position: absolute;
  left: 40px;
  top: calc((168px - 11.7vw * 0.2) / 2);
  z-index: 1;
  transition: all 0.3s ease;
}
.header--logo a,
.footer--logo a {
  display: block;
}
.header--logo a:not([href="javascript:void(0)"]):hover,
.footer--logo a:hover {
  opacity: 0.7;
}
.header--logo a {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../images/common/logo-header-wh.svg);
}
:where(.header:not(.header-sm)) .header--logo img {
  width: min(vw(224),rem(224));
}
.header--logo img {
  opacity: 1;
  transition: all 0.4s ease;
}
.active .header--logo img {
  opacity: 0;
}
.single-advice .header,
.single-e-book .header,
.page-contact:not(.page-thanks) .header,
.page-contact:not(.page-thanks) .header-sm.header,
.page-common_hide .header,
.page-common_hide .header-sm.header {
  position: absolute;
  height: 60px;
  box-shadow: none;
}
.single-advice .header > .inner,
.single-e-book .header > .inner,
.page-contact:not(.page-thanks) .header > .inner,
.page-common_hide .header > .inner {
  justify-content: flex-start;
}
.single-advice .header--logo,
.single-e-book .header--logo,
.page-contact:not(.page-thanks) .header--logo,
.page-common_hide .header--logo {
  position: relative;
  left: auto;
  padding-top: 0;
  width: 110px;
  top: 12px;
}
.single-advice  .header--logo,
.single-e-book  .header--logo,
.page-contact:not(.page-thanks) .header--logo,
.page-common_hide .header--logo a {
  background: none;
}
.header-sm.header {
  height: 80px;
}
.header-sm + .header-shadow {
  height: 80px;
  background: var(--clr-wht);
}
.header-sm + .header-shadow {
  box-shadow: 0 0 40px rgba(59, 131, 175, 0.1);
}
.header-sm.header:not(.active) .header--logo {
  top: 50%;
  transform: translateY(-50%);
}
.header-sm.header .gnav--menu > li,
.header-sm.header .gnav--contact,
.header-sm.header .gnav--e-book {
  padding-block: 24px;
}

.page-contact .header--logo a,
.single-e-book .header--logo a,
.single-advice .header--logo a {
  cursor: default;
}
.page-contact .header > .inner,
.single-e-book .header > .inner,
.single-advice .header > .inner {
  padding-left: 5%;
}

.page-contact .header--logo a:hover,
.single-e-book .header--logo a:hover,
.single-advice .header--logo a:hover {
  opacity: 1;
}

@media screen and (max-width: 1080px) {
  .header {
    height: 104px;
  }
  .header--logo {
    z-index: 302;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
  }
  .header--logo {
    width: 160px;
  }
  .header--logo img {
    width: auto;
    max-height: 40px;
  }
  .header--logo.active img {
    opacity: 0;
  }
}
@media screen and (max-width: 1300px) {
  .header > .inner {
    padding: 0 90px 0 2.34vw;
  }
}
@media screen and (max-width: 767px) {
  .header {
    height: 100px;
  }
  .header-sm.header,
  .header-sm + .header-shadow {
    height: 56px;
  }
  .header > .inner {
    padding: 0 5.78vw;
  }
  .page-contact .header > .inner {
    justify-content: center;
  }
  .header--logo {
    width: 120px;
    padding-top: 0;
  }
}

/* gnav
********************************************** */
.gnav_btn {
  position: absolute;
  top: 62px;
  right: 40px;
  width: 48px;
  height: 48px;
  border: 2px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 9999;
  transition: all 0.4s ease;
  cursor: pointer;
}
.gnav_btn--lines {
  position: relative;
  width: 18px;
  height: 12px;
}
.gnav_btn--lines span {
  transition: all 0.4s ease;
  box-sizing: border-box;
}
.gnav_btn--lines span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--clr-blk);
  border-radius: 6px;
}
.gnav_btn--lines span:nth-of-type(1) {
  top: 0;
}
.gnav_btn--lines span:nth-of-type(2) {
  bottom: 0;
}
.active .gnav_btn {
  border-color: var(--clr-wht);
  background-color: var(--clr-wht);
}
.active .gnav_btn--lines span:nth-of-type(1) {
  transform: translateY(4px) rotate(-30deg);
}
.active .gnav_btn--lines span:nth-of-type(2) {
  transform: translateY(-5px) rotate(30deg);
}

.gnav--menu > li > a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-jp-b);
}
.gnav--menu > li > a svg {
  margin-left: 6px;
}
.gnav--menu > li > a .icon_head svg {
  margin-left: 0;
  height: 1rem !important;
  vertical-align: middle;
}
.gnav--menu > li,
.gnav--contact,
.gnav--e-book {
  padding-top: 58px;
  transition: all 0.4s ease;
}
.gnav--link {
  padding: 0 1rem;
}
.menu-item-has-children {
  position: relative;
}

/* head_sub_nav--wrap */
.head_sub_nav--wrap {
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
  width: max-content;
  max-width: 98vw;
  transition: all 0.4s ease;
  opacity: 0;
  pointer-events: none;
  background-color: var(--clr-wht);
  border-radius: 16px;
  padding: 56px;
  box-shadow: var(--shadow-01);
}
.menu-item-has-children::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 40px;
  border: 8px solid transparent;
  border-bottom: 14px solid var(--clr-wht);
  z-index: 200;
  opacity: 0;
}
.menu-item-has-children:hover::before {
  opacity: 1;
}
.head_sub_nav--wrap a:hover {
  opacity: 0.7;
}
.head_sub_nav--ttl {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 280px;
  flex-shrink: 0;
  padding-right: 2.5rem;
}
.head_sub_nav--ttl span {
  display: block;
}
.head_sub_nav--cont {
  width: 560px;
  padding-left: 2rem;
  border-left: 1px solid var(--clr-mono-02);
}
.head_sub_nav--cont:nth-child(3) {
  width: 460px;
}
.head_sub_nav--cont:not(:last-child) {
  padding-right: 2rem;
}
.head_sub_nav--cont svg {
  fill: currentColor;
}

/* sub_nav_list */
.sub_nav_list:not(.sub_nav_list-bg) {
  gap: 2px;
}
.sub_nav_list:not(.sub_nav_list-bg) li {
  width: calc(50% - 1px);
}
.sub_nav_list li.full {
  width: 100%;
  margin-right: 0;
}
.sub_nav_list a {
  position: relative;
  display: block;
  padding: .375rem 2.5rem .375rem 1rem;;
  font-size: 0.875rem;
  line-height: 1.6;
  letter-spacing: 0.05em;
  border-radius: 4px;
  background-color: var(--clr-wht);
}
.sub_nav_list a:hover {
  background-color: var(--clr-mono-01);
  opacity: 1;
}
.sub_nav_list .arrow {
  position: absolute;
  right: .5rem;
  top: calc(50% - 22px / 2);
  flex-shrink: 0;
  width: 22px;
  border-radius: 50%;
  aspect-ratio: 1;
  background-color: var(--clr-mono-01);
  background-image: url(../images/header/chevron_right.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 72% auto;
}
.sub_nav_list a:hover .arrow {
  background-color: var(--clr-wht);
}
.sub_nav_list .txt {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 0 4px;
  margin-bottom: 4px;
  font-size: 1.125rem; /* 18px */
  line-height: 1.2;
  letter-spacing: 0.05em;
}
.sub_nav_list .txt + .txt-sm {
  margin-top: .25rem;
}
.sub_nav_list .num {
  font-size: 0.725rem;
  opacity: 0.6;
  margin-right: auto;
  margin-left: .5rem;
  letter-spacing: 0.02em;
}

/* sub_nav_list-level2 */
.sub_nav_list-level2 {
  margin-top: 8px;
}
.sub_nav_list-level2 a {
  position: relative;
  display: block;
  width: fit-content;
  margin-inline: auto;
  padding: .5em 2.5em .5em 1em !important;
  line-height: 1;
}
.sub_nav_list-level2 .arrow {
  position: absolute;
  right: .5rem;
  top: calc(50% - 22px / 2);
  flex-shrink: 0;
  width: 22px;
  aspect-ratio: 1;
  background-image: url(../images/header/chevron_right.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 72% auto;
}

.head_sub_nav--cont .box {
  padding: 16px 24px;
  background-color: var(--clr-mono-01);
  border-radius: 4px;
}
.head_sub_nav--cont .box a {
  display: flex;
  align-items: center;
  padding: rem(14) rem(32) rem(14) rem(16);
  height: 100%;
}
.head_sub_nav--cont .box a:hover {
  background-color: var( --clr-text-light);
}
.menu-item-works .sub_nav_list a {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.gnav--bookmark a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 1px solid var(--clr-mono-02);
  transition: 1s ease;
  padding: .5rem;
  border-radius: 60px;
  height: auto !important;
  font-family: var(--font-jp-b);
  font-size: .875rem; /* 14px */
  letter-spacing: .05em;
}
.gnav--bookmark .icon {
  position: relative;
  display: grid;
  place-content: center;
}
.gnav--bookmark .icon::before {
  content: "";
  position: absolute;
  top: -2px;
  right: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #F83838;
}

/* gnav--contact */
.gnav--contact {
  display: flex;
  align-items: center;
}
.gnav--contact a {
  padding: 8px 24px;
  border-radius: 60px;
  height: auto !important;
  font-family: var(--font-jp-b);
}
.gnav--contact a:hover {
  background-color: var(--clr-secondary-dark2);
  color: var(--clr-wht);
}
.gnav--contact .btn {
  display: none;
}
.gnav--contact a.gnav--link .free {
  display: inline-block;
  padding: 6px 8px 4px;
  border-radius: 4px;
  margin-left: 4px;
  font-size: 0.825rem;
  line-height: 1;
}
.gnav--contact a:hover .free {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0);
}

.gnav--e-book {
  display: flex;
  align-items: center;
}
.gnav--e-book a {
  padding: 8px 24px;
  border-radius: 60px;
  height: auto !important;
  font-family: var(--font-jp-b);
}
.gnav--e-book a:hover {
  background-color: var(--clr-mono-01);
}
.gnav--e-book a svg {
  height: 1rem !important;
  width: auto !important;
  transition: 1s ease;
}
.gnav--e-book .btn {
  display: none;
}
.hbg_sub_nav--wrap {
  position: relative;
  top: 0;
  left: 0;
  padding: 0 0 0 1em;
  width: 100%;
  transition: none;
  background-color: transparent;
  opacity: 1;
  pointer-events: inherit;
  display: none;
}

@media screen and (max-width: 767px) {
  .header-sm.header .gnav_btn {
    top: 8px;
  }
  .header-sm.header .header--logo,
  .header-sm.header .gnav--menu > li,
  .header-sm.header .gnav--contact,
  .header-sm.header .gnav--e-book {
    padding-block: 0;
  }
}
@media print, screen and (min-width: 1081px) {
  .header-sm.header:not(.active) .gnav_btn {
    top: 16px;
  }
  .tel_btn {
    display: none;
  }
  .gnav,
  .gnav--menu,
  .gnav--menu > li,
  .gnav--link {
    height: 100%;
  }
  .gnav {
    display: flex;
    align-items: center;
  }
  .gnav.pc-none {
    display: none;
  }
  .gnav--link {
    text-align: center;
  }
  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"])::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: var(--clr-blk);
    opacity: 0;
    transition: all 0.4s ease;
  }
  .header-sm .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"])::before {
    top: -8px;
  }
  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover::before,
  .gnav--menu > li.current-menu-item > .gnav--link::before {
    opacity: 1;
  }
  .menu-item-has-children > a {
    gap: 0.25rem;
  }
  .menu-item-has-children > a::after {
    content: "";
    width: 8px;
    height: 8px;
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease;
  }
  .header-sm.header .menu-item-has-children::after {
    top: 37px;
  }

  .header-sm .head_sub_nav--wrap {
    transform: translateY(-20px);
  }
  .header-sm .head_sub_nav--wrap {
    top: 100px;
  }
  /* hover */
  .gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap,
  .gnav--menu .menu-item-has-children:focus .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  /* タブキーでフォーカスされている時 */
  .gnav--menu .menu-item-has-children:focus-within .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .head_sub_nav a:hover {
    color: var(--clr-sub-01);
  }

  .gnav--contact a {
    background-color: var(--clr-secondary-default);
    color: var(--clr-wht);
    transition: background-color 1s ease;
  }
  .gnav--contact a:hover {
    background-color: var(--clr-secondary-dark2);
  }
  .gnav--contact a .free {
    display: inline-block;
    padding: 5px 8px 4px;
    margin-left: 6px;
    border-radius: 4px;
    font-size: 0.8375rem;
    line-height: 1;
    border: none;
  }
  .gnav--e-book a {
    display: flex;
    align-items: center;
    gap: 4px;
    border: 1px solid #0d1e28;
    background-color: var(--clr-wht);
    transition: 1s ease;
  }
  .gnav_sub_toggle_sp {
    display: none;
  }
  .gnav_sub_toggle_pc {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 18px;
    height: 18px;
    margin: auto;
    transition: all 0.4s ease;
    background: var(--clr-wht);
    border-radius: 50%;
  }
  .gnav_sub_toggle_pc.active {
    background: var(--clr-sub-01);
  }
  .gnav_sub_toggle_pc::before,
  .gnav_sub_toggle_pc::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: calc(100% - 8px);
    height: 2px;
    border-bottom: 2px solid var(--clr-sub-04);
  }
  .gnav_sub_toggle_pc::after {
    transform: rotate(90deg);
  }
  .gnav_sub_toggle_pc.active::after {
    transform: rotate(180deg);
  }
  .gnav--cta {
    gap: 0.5rem;
  }
}
@media screen and (max-width: 1400px) and (min-width: 1081px) {
  .gnav--med-none {
    display: none;
  }
}

@media screen and (max-width: 1650px) and (min-width: 1081px) {
  .gnav--link {
    padding: 0 .5rem;
  }
  .menu-item {
    font-size: 14px;
  }
  .gnav--contact a,
  .gnav--e-book a {
    font-size: 14px;
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 1300px) {
  .gnav_btn {
    right: 24px;
  }
}
@media screen and (max-width: 1180px) and (min-width: 1081px) {
  .header {
    height: 80px;
  }
  .header-sm.header {
    height: 64px;
  }
  .header-sm + .header-shadow {
    height: 64px;
  }
  .header--logo {
    top: auto;
  }
  .gnav_btn {
    top: 16px;
  }
  .header-sm.header:not(.active) .gnav_btn {
    top: 8px;
  }
  .gnav--menu > li {
    padding-top: 0;
  }
  .gnav--cta {
    gap: 6px;
  }
  .gnav--cta {
    position: absolute;
    right: 25px;
    top: calc(100% + 8px);
    transition: all 0.4s ease;
  }
  .gnav--cta .gnav--e-book a,
  .gnav--cta .gnav--contact a {
    white-space: nowrap;
  }
  .header-sm.header .gnav--cta {
    padding-top: 0;
  }
  .gnav--contact,
  .gnav--e-book {
    width: 100%;
  }
  .header-sm.header .gnav--contact,
  .header-sm.header .gnav--e-book {
    padding-block: 0;
  }
  .gnav--contact,
  .gnav--e-book {
    padding-top: 0;
  }
}
@media screen and (max-width: 1080px) {
  .gnav,
  .gnav_overlay {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s ease;
  }
  .gnav {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 124px 7% 40px;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: var(--clr-sub-04);
    z-index: 300;
    opacity: 0;
    -webkit-overflow-scrolling: touch;
  }
  .gnav_overlay {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .gnav.active,
  .gnav_overlay.active {
    opacity: 1;
    pointer-events: auto;
  }
  .gnav--menu,
  .gnav--cta {
    display: flex;
    flex-direction: column;
  }
  .gnav--cta {
    order: 1;
  }
  .gnav--menu {
    order: 2;
  }
  .gnav--menu li {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 0;
  }
  .menu-item > .ttl {
    margin-bottom: 24px;
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 0.06em;
    color: var(--clr-sub-01);
  }
  .gnav--menu li.menu-item-book {
    display: none;
  }
  .menu-item-production {
    order: 3;
  }
  .menu-item-service {
    order: 4;
  }
  .menu-item-works {
    order: 5;
  }
  .menu-item-interview {
    order: 6;
  }
  .menu-item-company {
    order: 1;
  }
  .menu-item-recruit {
    order: 7;
  }
  .menu-item-blog {
    order: 2;
  }
  .gnav--menu > li {
    margin-bottom: 16px;
  }
  .gnav--menu > li > a {
    display: block;
    color: var(--clr-wht);
    padding: 6px 0;
  }
  .gnav--menu > li > a svg {
    fill: var(--clr-wht);
  }
  .head_sub_nav--wrap {
    position: relative;
    top: 0;
    left: 0;
    padding: 16px 0 0 1em;
    width: 100%;
    transition: none;
    background-color: transparent;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
  .menu-item-service .head_sub_nav--wrap,
  .menu-item-works .head_sub_nav--wrap {
    display: block;
    top: auto;
  }
  .menu-item-service .head_sub_nav--wrap,
  .menu-item-works .head_sub_nav--wrap {
    left: 0;
  }
  .menu-item-has-children.active .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .head_sub_nav--list > li {
    padding: 0;
  }
  .head_sub_nav--list > li:not(:last-child) {
    border-right: none;
    margin-bottom: 24px;
  }
  .head_sub_nav--list .txt-gry {
    color: var(--clr-wht);
    opacity: 0.8;
  }
  .head_sub_nav {
    position: relative;
  }
  .head_sub_nav--ttl {
    margin-bottom: 12px;
    font-size: 1rem;
  }
  .menu-item-service .head_sub_nav--list > li,
  .menu-item-service .head_sub_nav--list.service > li {
    width: 100%;
    text-align: left;
  }
  .head_sub_nav--wrap a.flex-inline {
    width: 100%;
  }
  .menu-item-service .head_sub_nav--list > li:nth-child(1),
  .menu-item-service .head_sub_nav--list > li:nth-child(2),
  .menu-item-service .head_sub_nav--list > li:nth-child(3) {
    width: 100%;
  }
  .gnav_btn {
    top: 24px;
    right: 5%;
    width: 40px;
    height: 40px;
    z-index: 9999;
  }
  .gnav_btn {
    transition: all 0.4s ease;
  }
  .gnav_btn.active {
    border-color: var(--clr-text-light);
    background-color: var(--clr-text-light);
  }
  .gnav_btn--lines {
    width: 19px;
    height: 9px;
  }
  .active .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(2px) rotate(-30deg);
  }
  .active .gnav_btn--lines span:nth-of-type(2) {
    transform: translateY(-4px) rotate(30deg);
  }
  .gnav_sub_toggle_pc {
    display: none;
  }
  .gnav_sub_toggle_sp {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 18px;
    height: 18px;
    margin: auto;
    transition: all 0.4s ease;
    background: var(--clr-wht);
    border-radius: 50%;
  }
  .gnav_sub_toggle_sp.active {
    background: var(--clr-sub-01);
  }
  .gnav_sub_toggle_sp::before,
  .gnav_sub_toggle_sp::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: calc(100% - 8px);
    height: 2px;
    border-bottom: 2px solid var(--clr-sub-04);
  }
  .gnav_sub_toggle_sp::after {
    transform: rotate(90deg);
  }
  .gnav_sub_toggle_sp.active::after {
    transform: rotate(180deg);
  }

  .gnav--sns {
    margin-top: 32px;
    order: 3;
  }
  .gnav--sns li {
    margin: 0 6px;
  }

  /* ---CTA --- */
  .gnav--cta {
    margin-bottom: 32px;
  }
  .gnav--cta li:nth-child(1) {
    margin-bottom: 24px;
  }
  .gnav--cta li:nth-child(2) {
    margin-bottom: 16px;
  }
  .gnav--tel {
    text-align: center;
  }
  .gnav--tel a {
    color: var(--clr-wht);
    font-size: 2rem;
    font-family: var(--font-en);
    line-height: 1.25;
  }
  .gnav--tel a i {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.25rem;
  }
  .gnav--tel small {
    vertical-align: baseline;
    margin-left: 8px;
  }
  .gnav--contact {
    padding-top: 0;
    margin-left: 0;
    width: 100%;
  }
  .gnav--contact a {
    width: 100%;
    padding: 14px 16px;
    margin-left: auto;
    margin-right: auto;
    background-color: #e02828;
    border-radius: 60px;
    color: var(--clr-wht);
    text-align: center;
  }

  .gnav--e-book {
    padding-top: 0;
    margin-left: 0;
    width: 100%;
  }
  .gnav--e-book a {
    width: 100%;
    padding: 14px 16px;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--clr-wht);
    border-radius: 60px;
    text-align: center;
  }

  .gnav--deco.circle {
    left: -10px;
    bottom: 20%;
    width: 50px;
    height: 50px;
    background-color: var(--clr-sub-02);
    border-radius: 50%;
  }
  .gnav--deco.triangle1 {
    top: -29%;
    right: -120px;
    transform: rotate(87.94deg);
  }
  .gnav--deco.triangle2 {
    display: none;
    top: 75%;
    right: -80px;
    transform: rotate(12.94deg);
  }

  .sub_nav_list {
    margin-bottom: 16px;
  }
  .gnav .sub_nav_list a > svg:nth-child(1),
  .head_sub_nav--ttl a.font-en svg {
    margin-top: 0;
  }
}

/* hbg
********************************************** */
.hbg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--clr-sub-04);
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease;
}
.active .hbg {
  opacity: 1;
  pointer-events: all;
}
.hbg--deco.circle {
  display: inline-block;
  width: 106px;
  height: 106px;
  background-color: var(--clr-sub-02);
  border-radius: 50%;
}
.hbg--deco.circle1 {
  top: 60%;
  left: -40px;
}
.hbg--deco.circle2 {
  top: 28%;
  right: -30px;
}
.hbg--deco.triangle1 {
  bottom: 95%;
  left: 15%;
  transform: rotate(87.94deg);
}
.hbg--deco.triangle2 {
  top: 16%;
  left: 40%;
  transform: rotate(12.94deg);
  z-index: -1;
}
.hbg--wrapper {
  width: 100%;
  height: 100%;
  padding-bottom: 80px;
}
.hbg--scrolldown {
  position: absolute;
  right: 40px;
  bottom: 8px;
  width: fit-content;
  color: var(--clr-sub-01);
  font-weight: 600;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-font-feature-settings: "pkna";
  font-feature-settings: "pkna";
  height: 80px;
}
.hbg--scrolldown span {
  position: absolute;
  left: -12px;
  top: -100px;
  color: #eee;
  letter-spacing: 0.05em;
}
.hbg--scrolldown::after {
  content: "";
  position: absolute;
  top: 0;
  width: 1px;
  height: 30px;
  background: #eee;
  animation: scrolldown 1.4s ease-in-out infinite;
  opacity: 0;
}
.hbg_inner {
  width: 95%;
  max-width: 1680px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.hbg--nav {
  margin-right: 72px;
}
.hbg--nav::-webkit-scrollbar {
  display: none;
}
.hbg--nav_page {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 56px;
}
.hbg--nav_page .ttl {
  margin-bottom: 24px;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0.06em;
  color: var(--clr-sub-01);
}
.hbg--nav_page .ttl_sub {
  position: relative;
  margin-top: 24px;
  margin-bottom: 10px;
  font-size: 1.125rem; /* 18px */
  font-weight: 600;
}
.hbg--nav_page .nav_list li:not(:last-child) {
  margin-bottom: 4px;
}
.hbg--nav_page .nav_list a {
  --padding: 18px;

  position: relative;
  display: inline-block;
  padding-left: var(--padding);
  color: var(--clr-wht);
  font-size: 0.875rem;
  letter-spacing: 0.02em;
  transition: 0.4s;
}
.hbg--nav_page .nav_list a:hover {
  opacity: 0.6;
}
.hbg--nav_page .ttl_sub_works {
  margin-top: 0;
}
.hbg--nav_page .nav_list > li > a {
  margin-left: 1rem;
}
.hbg--nav_page .nav_list a::before {
  --width: 6px;

  content: "";
  width: var(--width);
  float: inline-start;
  aspect-ratio: 1;
  margin-block: calc((1lh - var(--width)) / 2);
  margin-inline-start: calc(var(--padding) * -1);
  background-color: var(--clr-sub-01);
  border-radius: 50%;
}
.hbg--nav_page .nav_list > li > a .num,
.hbg--nav_page .nav_list--child .num {
  font-size: 0.725rem;
  opacity: 0.6;
  margin-right: auto;
  margin-left: 6px;
  letter-spacing: 0.06em;
}
.hbg--nav_page .nav_list a svg {
  margin-left: 4px;
  fill: var(--clr-wht);
  vertical-align: middle;
}
.hbg--nav_service li:not(:last-child) {
  margin-right: 24px;
}
.hbg--nav_page .nav_list a small {
  opacity: 0.5;
  font-size: 0.8rem;
}
.hbg--nav_page .nav_list--child {
  margin-top: 14px;
  padding-left: 1rem;
}
.hbg--nav_sub {
  max-width: 450px;
}
.hbg--nav_sub li {
  transition: all 0.2s ease;
}
.hbg--nav_sub li:hover {
  opacity: 0.6;
}
.hbg--nav_sub li:nth-child(6) {
  margin-left: 24px;
}
.hbg--nav_sub li:not(:last-child) {
  margin-right: 24px;
}
.hbg--nav_sub a {
  height: 100%;
  display: inline-block;
  color: var(--clr-wht);
  font-size: 0.8rem;
}
.hbg--nav_sub svg {
  height: 100%;
  fill: var(--clr-wht);
}
.hbg--cta {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hbg--cta .ttl {
  margin-bottom: 24px;
  font-size: 5rem;
  line-height: 1;
  letter-spacing: 0.02em;
  color: var(--clr-sub-01);
  font-weight: 400;
}
.hbg--cta .txt-sm ul {
  margin-left: 18px;
}
.hbg--cta .txt-sm ul li {
  margin: 8px 0;
  list-style: disc;
}
.hbg--cta .tel {
  gap: 1em;
  align-items: center;
  justify-content: center;
}
.hbg--cta_btns .btn {
  width: 100%;
}
.hbg--cta_btns .btn + .btn {
  margin-left: 8px !important;
}
.hbg--cta_btns .btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 1rem;
}
.hbg--cta_btns .btn .icon-arrow {
  top: 55%;
  right: 16px;
}
.hbg--cta_btns .btn .icon-arrow svg {
  width: 13px;
  height: 13px;
}
.hbg--cta_btns .btn .icon-pdf {
  fill: #333;
}
.hbg--cta .tel span {
  font-size: 0.7rem;
  line-height: 1;
}
.hbg--cta .tel a {
  font-size: 2rem;
  font-weight: 600;
  color: var(--clr-wht);
  line-height: 1;
}
.hbg--cta .tel svg {
  width: 28px;
  height: 28px;
  translate: 0 2px;
}
.hbg--nav_page .ttl_sub > a {
  display: block;
  font-size: 1.125rem;
  padding-left: 0;
}
.hbg--nav_page .ttl_sub > a::before {
  display: none;
}
.hbg--nav_page .ttl_sub > a::after {
  content: "";
  width: 8px;
  height: 8px;
  border-top: solid 2px var(--clr-wht);
  border-right: solid 2px var(--clr-wht);
  position: absolute;
  right: 6px;
  top: 8px;
  transform: rotate(45deg);
}

@media screen and (max-width: 1500px) {
  .hbg--nav {
    margin-right: 42px;
  }
  .hbg--cta br {
    display: none;
  }
  .hbg--cta .ttl {
    font-size: 3.2rem;
  }
  .hbg--cta .tel {
    flex-direction: column;
    align-items: center;
  }
  .hbg--cta .tel span {
    display: block;
    margin-top: 8px;
  }
  .hbg--cta_btns {
    flex-direction: column;
  }
  .hbg--cta_btns .btn {
    width: 100%;
  }
  .hbg--cta_btns .btn + .btn {
    margin-top: 16px !important;
    margin-left: 0 !important;
  }
}
@media screen and (min-width: 1501px) {
  .hbg--nav_page .ttl_sub.ttl_works {
    margin-top: max(64px, 3.66vw);
  }
  .hbg--cta {
    margin-left: -14%;
  }
}
@media screen and (max-width: 1500px) {
  .hbg--nav_page {
    margin-right: 80px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 0;
  }
  .hbg--nav_page .ttl_sub.ttl_works {
    margin-top: 0;
  }
}
@media screen and (min-width: 1081px) and (max-width: 1500px) {
  .hbg--nav_page li:not(:nth-child(1)) {
    margin-bottom: 32px;
  }
}
@media screen and (min-width: 1081px) {
  .hbg::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    background: rgba(35, 63, 82, 0.9);
  }
  .hbg--cta {
    height: calc(100vh - 240px);
    margin-top: 160px;
  }
  .hbg--nav {
    display: flex;
    justify-content: center;
    align-items: start;
    width: 50vw;
    height: 100vh;
    margin-right: 36px;
    padding-top: 160px;
    padding-inline: 32px;
    overflow-y: scroll;
  }
  .hbg--cta .innerbox {
    max-width: 576px;
    width: 70%;
  }
  .hbg--nav_page--webproduce .nav_list > li:nth-child(3) {
    display: none;
  }
  .hbg--nav_page--webproduce .nav_list > li:nth-child(1) .hbg_sub_nav--wrap,
  .hbg--nav_page--webproduce .nav_list > li:nth-child(2) .hbg_sub_nav--wrap,
  .hbg--nav_page--works .hbg_sub_nav--wrap,
  .hbg--nav_page--outsourcing .nav_list > li:nth-child(1) .hbg_sub_nav--wrap,
  .hbg--nav_page--company .hbg_sub_nav--wrap {
    display: block;
  }
  .hbg--nav_page .ttl_sub {
    cursor: pointer;
  }
  .hbg--nav_page .ttl_sub:has(.gnav_sub_toggle_pc.pc-none) {
    pointer-events: none;
  }
}
@media screen and (max-width: 1080px) {
  .hbg {
    overflow-x: clip;
    overflow-y: scroll;
    padding-inline: 10%;
    padding-top: 100px;
  }
  .hbg_inner {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .hbg--cta {
    order: 1;
    margin-bottom: 24px;
  }
  .hbg--nav {
    margin-right: 0;
  }
  .hbg--cta .innerbox {
    width: 100%;
  }
  .hbg--nav_page {
    gap: 24px;
    width: 100%;
    margin: 0 auto 32px;
  }
  .hbg--deco.circle1 {
    scale: 0.3;
    top: 60%;
  }
  .hbg--deco.circle2 {
    scale: 0.3;
    top: 10%;
  }
  .hbg--deco.triangle1 {
    display: none;
  }
  .hbg--deco.triangle2 {
    width: 240px;
    height: auto;
    aspect-ratio: 804/723;
    left: auto;
    top: 98%;
    right: -40%;
    opacity: 0.3;
  }
  .hbg--scrolldown {
    position: fixed;
    left: 4%;
    bottom: 0;
    font-size: 0.825rem;
    height: 64px;
  }
  .hbg--scrolldown span {
    left: -10px;
    top: -80px;
  }
  .hbg--nav_page .ttl_sub {
    margin-top: 16px;
    position: relative;
  }
  .hbg--nav_page--company {
    order: 1;
  }
  .hbg--nav_page--webproduce {
    order: 2;
  }
  .hbg--nav_page--works {
    order: 3;
  }
  .hbg--nav_page--outsourcing {
    order: 4;
  }
  .hbg_sub_nav-sp-open {
    display: block;
  }
  .hbg--nav_page .ttl {
    margin-left: -16px;
    margin-bottom: 16px;
    font-size: 2rem;
  }
  .hbg--nav_page .nav_list a {
    font-size: 1rem;
    margin-bottom: 4px;
  }
  .hbg--cta_btns {
    margin-bottom: 16px;
  }
  .hbg--nav_page .ttl_sub > a {
    font-size: 1.125rem;
  }
}