@use "../abstracts" as *;

/* ####################################################################################

  Sass導入メモ
  既存のスタイルを残したまま関数追加

#################################################################################### */

/* bg
********************************************** */
// 背景色を出力 
// 出力例）.bg-primary-default
@each $key, $color in $colors {
  $key-suffix: str-slice($key, 5); // 'clr-'を削除（懸念点）
  .bg-#{$key-suffix} {
    background-color: var(--#{$key});
  }
}

/* --- 既存のスタイル --- */
.bg-body {
  background-color: #F4F4F4;
}
.bg-main {
  background-color: var(--clr-sub-04);
}
.bg-sub {
  background-color: var(--clr-bg-01);
}
.bg-wh {
  background-color: var(--clr-wht);
}
.bg-bk {
  background-color: var(--clr-blk);
}