@use '../abstracts' as *;

/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: #{$fz-pc}px;
}
body {
  font-family: "Shuei KakuGo Gin M", YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
  line-height: 1.75;
  font-weight: 500;
  font-feature-settings: "palt";
  letter-spacing: 0.08em;
  *font-size: small;
  *font: x-small;
  color: #000;
  -webkit-font-smoothing: antialiased;
}
ul,
ol {
  list-style: none;
}
small {
  font-size: 0.875rem; /* 14px */
}
a {
  color: #000;
  text-decoration: none;
  transition: all 0.4s ease-out;
  outline: none;
}
a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a:focus {
  text-decoration: none !important;
}
a:focus-visible,
*[tabindex]:focus-visible {
  outline: auto;
}
img {
  vertical-align: middle;
}
address,
em,
i {
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4;
}
svg {
  transition: all 0.4s ease-out;
}

@media screen and (max-width: 767px) {
  html {
    font-size: #{$fz-sp}px;
  }
  body {
    -webkit-text-size-adjust: none;
    word-break: break-word;
    line-height: 1.8;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  small {
    font-size: rem(13); //元の数値に合わせて
  }
}

@media screen and (min-width: 1081px) {
  table {
    width: 100%;
  }
}