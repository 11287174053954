@use '../abstracts' as *;
@use 'sass:math';

/* ####################################################################################

  Sass導入メモ
  main.cssにあった記述を移植

#################################################################################### */

/* img
********************************************** */
.img-ctr {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.img-trim {
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-trim.pos_rel::before {
  content: "";
  display: block;
}
.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}
.img-cover.pos_ab {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: var(--clr-mono-02);
}
.img-contain {
  object-fit: contain;
  font-family: "object-fit: contain;";
}