@use "../abstracts" as *;

/* ##############################################################################

    KEYFRAMES

############################################################################## */

/* テキスト無限スライド */
@keyframes txtroop {
  from { background-position: 0 0; }
  to { background-position: min(vw(-3400),rem(-3400)) 0; }
}

/* 一回転 */
@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* triangle アニメーション */
@keyframes rotation1 {
  0% { transform: rotate(12.94deg); }
  50% { transform: rotate(30deg); }
  100% { transform: rotate(12.94deg); }
}
@keyframes rotation2 {
  0% { transform: rotate(-15deg); }
  50% { transform: rotate(-35deg); }
  100% { transform: rotate(-15deg); }
}
@keyframes rotation3 {
  0% { transform: rotate(-108deg); }
  50% { transform: rotate(-100deg); }
  100% { transform: rotate(-108deg); }
}
@keyframes rotation4 {
  0% { transform: rotate(-20deg); }
  50% { transform: rotate(-100deg); }
  100% { transform: rotate(-20deg); }
}
@keyframes rotation5 {
  0% { transform: rotate(14deg); }
  50% { transform: rotate(100deg); }
  100% { transform: rotate(14deg); }
}
@keyframes rotation6 {
  0% { transform: rotate(-45deg); }
  50% { transform: rotate(-12deg); }
  100% { transform: rotate(-45deg); }
}
@keyframes rotation7 {
  0% { transform: rotate(-22.09deg); }
  50% { transform: rotate(50deg); }
  100% { transform: rotate(-22.09deg); }
}
@keyframes rotation8 {
  0% { transform: rotate(70deg); }
  50% { transform: rotate(100deg); }
  100% { transform: rotate(70deg); }
}
@keyframes rotation9 {
  0% { transform: rotate(-24deg); }
  50% { transform: rotate(-45deg); }
  100% { transform: rotate(-24deg); }
}
@keyframes rotation10 {
  0% { transform: rotate(-40deg); }
  50% { transform: rotate(40deg); }
  100% { transform: rotate(-40deg); }
}

/* circle アニメーション */
@keyframes fuwafuwa {
  0% { transform: translate(0, 0); }
  35% { transform: translate(-50px, -20px); }
  65% { transform: translate(20px, 50px); }
  100% { transform: translate(0, 0); }
}
@keyframes fuwafuwa2 {
  0% { transform: translate(0, 0); }
  35% { transform: translate(20px, 10px); }
  65% { transform: translate(-10px, -25px); }
  100% { transform: translate(0, 0); }
}
@keyframes fuwafuwa3 {
  0% { transform: translate(0, 0); }
  35% { transform: translate(25px, -10px); }
  65% { transform: translate(-10px, 25px); }
  100% { transform: translate(0, 0); }
}

/* スクロールアニメーション */
@keyframes slideup {
  0% { transform: translateY(20px); }
  100% { transform: translateY(0); }
}

/* グラデ背景？ */
@keyframes gradation_bg0_movement {
  0% { transform: translateY(-20%) translateX(30%); }
  50% { transform: translateY(10%) translateX(20%); }
  100% { transform: translateY(-20%) translateX(30%); }
}
@keyframes gradation_bg1_movement {
  0% { transform: translateY(20%) translateX(-20%); }
  50% { transform: translateY(5%) translateX(-30%); }
  100% { transform: translateY(20%) translateX(-20%); }
}
@keyframes gradation_bg2_movement {
  0% { transform: translateX(40%); }
  50% { transform: translateX(10%); }
  100% { transform: translateX(40%); }
}
@keyframes gradation_bg3_movement {
  0% { transform: translateY(10%); }
  50% { transform: translateY(20%); }
  100% { transform: translateY(10%); }
}

/* ハンバーガーメニューの scrolldown */
@keyframes scrolldown {
  0% {
    height: 0;
    top: 0;
    opacity: 0;
  }
  30% {
    height: 30px;
    opacity: 1;
  }
  100% {
    height: 0;
    top: 50px;
    opacity: 0;
  }
}

/* 右下CTAの跳ねる動き */
@keyframes bounce-cta {
  0%, 100% { transform: translateY(0); }
  15% { transform: translateY(-20px); }
  30% { transform: translateY(0); }
}

/* CTAエリアのスライド */
@keyframes loop_scroll-top {
  from { transform: translateY(0); }
  to { transform: translateY(-100%); }
}
@keyframes loop_scroll-btm {
  from { transform: translateY(-100%); }
  to { transform: translateY(0); }
}
@keyframes loop_scroll-top-vertical {
  from { transform: translateX(0); }
  to { transform: translateX(-100%); }
}
@keyframes loop_scroll-btm-vertical {
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
}
@media screen and (max-width: 767px) {
  @keyframes loop_scroll-top {
    from { transform: translateX(0); }
    to { transform: translateX(-100%); }
  }
  @keyframes loop_scroll-btm {
    from { transform: translateX(-100%); }
    to { transform: translateX(0); }
  }
}
