@use "sass:math";
@use '../abstracts' as *;

/* ####################################################################################

  Sass導入メモ
  既存のmgn-btmをそのまま使用 sp時の余白が変わってしまうため
  mgn-btm-spだけ追加

#################################################################################### */

/* --- margin --- */
.mgn-btm4 {
  margin-bottom: 4px;
}
.mgn-btm8 {
  margin-bottom: 8px;
}
.mgn-btm12 {
  margin-bottom: 12px;
}
.mgn-btm16 {
  margin-bottom: 16px;
}
.mgn-btm24 {
  margin-bottom: 24px;
}
.mgn-btm32 {
  margin-bottom: 32px;
}
.mgn-btm40 {
  margin-bottom: 40px;
}
.mgn-btm48 {
  margin-bottom: 48px;
}
.mgn-btm56 {
  margin-bottom: 56px;
}
.mgn-btm64 {
  margin-bottom: 64px;
}
.mgn-btm72 {
  margin-bottom: 72px;
}
.mgn-btm80 {
  margin-bottom: 80px;
}
.mgn-btm96 {
  margin-bottom: 96px;
}
/* NinjaForm用 */
.mgn-top40 {
  margin-top: 40px;
}

@include mq(sp) {
  /* --- margin --- */
  .mgn-btm12 {
    margin-bottom: 8px;
  }
  .mgn-btm16 {
    margin-bottom: 8px;
  }
  .mgn-btm24 {
    margin-bottom: 16px;
  }
  .mgn-btm32 {
    margin-bottom: 16px;
  }
  .mgn-btm40 {
    margin-bottom: 24px;
  }
  .mgn-btm48 {
    margin-bottom: 24px;
  }
  .mgn-btm56 {
    margin-bottom: 32px;
  }
  .mgn-btm64 {
    margin-bottom: 32px;
  }
  .mgn-btm72 {
    margin-bottom: 40px;
  }
  .mgn-btm80 {
    margin-bottom: 40px;
  }
  .mgn-btm96 {
    margin-bottom: 48px;
  }
}

@include mq(sp) {
  @for $i from 0 through 150 {
    $halfValue: math.div(math.div($i, 2), $fz-sp); // $iの半分の値を計算
    .mgn-btm-sp#{$i} { margin-bottom: sprem($i) !important; }
  }
}