/* ####################################################################################

  Sass導入メモ
  既存のものを使用
  pos_fx だけ追加

#################################################################################### */


/* --- position --- */
.pos_rel {
  position: relative;
}
.pos_ab {
  position: absolute;
}
.pos_fx {
  position: fixed;
}

/* --- float --- */
.flt-rgt {
  float: right;
}
.flt-lft {
  float: left;
}