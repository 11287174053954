@use "../abstracts" as *;

/* ####################################################################################

  Sass導入メモ
  main.scssにあった inner を移動

#################################################################################### */

/* --- inner --- */
.inner {
  width: 86%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}
.inner-sm {
  max-width: 960px;
}
.inner-sm2 {
  max-width: 1080px;
}
.inner-md {
  max-width: 1428px;
  width: 90%;
}
.inner-lg {
  max-width: 1600px;
  width: 90%;
}
.inner-xl {
  width: calc(100% - 112px);
  max-width: calc(100% - 112px);
}
@include mq(sp) {
  .inner {
    width: 86%;
    max-width: 86%;
  }
  .inner-sm {
    max-width: 86%;
  }
  .inner-md {
    max-width: 86%;
  }
  .inner-lg {
    max-width: 86%;
  }
}